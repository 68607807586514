import {
  ADD_PRODUCT_VARIANT,
  DELETE_PRODUCT,
  GET_SELECTED_PRODUCT,
  REMOVE_PRODUCT_VARIANT,
  RESET_PRODUCT,
  SAVE_EDIT_PRODUCT,
  SAVE_NEW_PRODUCT,
  SERVICE_ROOT,
  SET_PRODUCTS,
  SET_SELECTED_PRODUCT,
  UPDATE_PRODUCT_VARIANT,
} from '../constants'

const state = () => ({
  selectedProduct: undefined,
})

const getters = {
  [GET_SELECTED_PRODUCT]: (state) => {
    return state.selectedProduct
  },
}

const actions = {
  [SET_SELECTED_PRODUCT]({ commit }, product) {
    commit(`${SET_SELECTED_PRODUCT}`, product)
  },

  [ADD_PRODUCT_VARIANT]({ commit }, variant) {
    commit(`${ADD_PRODUCT_VARIANT}`, variant)
  },

  [REMOVE_PRODUCT_VARIANT]({ commit }, variantCode) {
    commit(`${REMOVE_PRODUCT_VARIANT}`, variantCode)
  },

  [UPDATE_PRODUCT_VARIANT]({ commit }, updateVariant) {
    commit(`${UPDATE_PRODUCT_VARIANT}`, updateVariant)
  },

  async [SAVE_EDIT_PRODUCT]({ commit, state, dispatch, rootState }) {
    if (state.selectedProduct.displayOrder == null)
      delete state.selectedProduct.displayOrder
    if (state.selectedProduct.imagstickeUrl == null)
      state.selectedProduct.imageUrl = ''
    await rootState.SERVICE_ROOT.repository.product.updateProduct(
      state.selectedProduct.id,
      state.selectedProduct
    )
    commit(`${RESET_PRODUCT}`)
    await dispatch(`${SERVICE_ROOT}/${SET_PRODUCTS}`, null, { root: true })
  },

  async [SAVE_NEW_PRODUCT]({ commit, state, dispatch, rootState }) {
    if (state.selectedProduct.displayOrder == null)
      delete state.selectedProduct.displayOrder
    if (state.selectedProduct.imageUrl == null)
      state.selectedProduct.imageUrl = ''
    await rootState.SERVICE_ROOT.repository.product.createProduct(
      state.selectedProduct
    )
    commit(`${RESET_PRODUCT}`)
    await dispatch(`${SERVICE_ROOT}/${SET_PRODUCTS}`, null, { root: true })
  },

  async [DELETE_PRODUCT]({ commit, state, dispatch, rootState }) {
    await rootState.SERVICE_ROOT.repository.product.deleteProduct(
      state.selectedProduct.id
    )
    commit(`${RESET_PRODUCT}`)

    await dispatch(`${SERVICE_ROOT}/${SET_PRODUCTS}`, null, { root: true })
  },
}

const mutations = {
  [SET_SELECTED_PRODUCT](state, product) {
    state.selectedProduct = product
  },

  [RESET_PRODUCT](state) {
    state.selectedProduct = {
      category: null,
      categoryId: 0,
      description: '',
      displayOrder: null,
      id: null,
      imageUrl: '',
      isPickUpOnly: false,
      name: '',
      taxRate: '',
      variants: [],
    }
  },

  [ADD_PRODUCT_VARIANT](state, variant) {
    variant.price = variant.price.replace(',', '.')
    state.selectedProduct.variants.push(variant)
  },

  [REMOVE_PRODUCT_VARIANT](state, variantCode) {
    const updatedVariants = state.selectedProduct.variants.filter(
      (x) => x.variantCode != variantCode
    )
    state.selectedProduct.variants = updatedVariants
  },

  [UPDATE_PRODUCT_VARIANT](state, updateVariant) {
    for (let i = 0; i < state.selectedProduct.variants.length; i++) {
      if (
        state.selectedProduct.variants[i].variantCode ==
        updateVariant.variantCode
      ) {
        state.selectedProduct.variants[i].name = updateVariant.variant.name
        state.selectedProduct.variants[i].variantCode =
          updateVariant.variant.variantCode
        state.selectedProduct.variants[
          i
        ].price = updateVariant.variant.price.replace(',', '.')
        state.selectedProduct.variants[i].isEnabled =
          updateVariant.variant.isEnabled
        state.selectedProduct.variants[i].isSoldOut =
          updateVariant.variant.isSoldOut
        break
      }
    }
  },
}

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations,
}
