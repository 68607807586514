<template>
  <v-text-field
    v-model="selectedDeliverTime"
    label="Tijd"
    dense
    outlined
    class="ml-2"
    @blur="formatTime"
    prepend-inner-icon="mdi-clock"
    :rules="timerules"
    clearable
    persistent-hint
  ></v-text-field>
</template>

<script>
import { mapGetters } from 'vuex'
import { GET_AVAILABLE_TIME_SLOTS, ORDER } from '@/store/constants'
export default {
  computed: {
    ...mapGetters({
      getAvailableTimeSlots: `${ORDER}/${GET_AVAILABLE_TIME_SLOTS}`,
    }),
  },

  props: {
    deliverTime: {
      type: String,
      isRequired: true,
    },
  },

  mounted() {
    this.selectedDeliverTime = this.getAvailableTimeSlots[0] || '16:00'
  },

  methods: {
    formatTime() {
      const regex = new RegExp('^([0-1]?[0-9]|2[0-3])((:)?[0-5][0-9])?$')
      if (
        !regex.test(this.selectedDeliverTime) ||
        this.selectedDeliverTime.length === 3
      )
        return (this.selectedDeliverTime = this.getAvailableTimeSlots[0])
      if (this.selectedDeliverTime.length === 4)
        this.selectedDeliverTime = [
          this.selectedDeliverTime.slice(0, 2),
          ':',
          this.selectedDeliverTime.slice(2),
        ].join('')

      if (this.selectedDeliverTime.length < 3)
        this.selectedDeliverTime = this.selectedDeliverTime + ':00'
      this.$emit('set-time', this.selectedDeliverTime)
    },
  },

  data() {
    return {
      selectedDeliverTime: '16:00',
      timerules: [
        (v) => !!v || 'Vul in',
        (v) =>
          /^([0-1]?[0-9]|2[0-3])((:)?[0-5][0-9])?$/.test(v) ||
          'Tijd klopt niet',
      ],
    }
  },
}
</script>
