<template>
  <div v-if="isInitialized">
    <!-- SEARCH -->
    <v-card-actions class="pt-4 white">
      <v-badge
        overlap
        left
        class="ml-2"
        :content="getTotalQuantity"
        :color="getTotalQuantity ? 'red' : 'transparent'"
      >
        <v-btn icon @click="toggleLeftDrawer" class="mr-2">
          <v-icon>mdi-basket-outline</v-icon>
        </v-btn>
      </v-badge>
      <v-sheet color="transparent" width="100%">
        <v-text-field
          v-model="search"
          hide-details
          label="Product zoeken"
          placeholder="e.g. Maki, Crispy..."
          clearable
          color="secondary"
          outlined
          dense
          prepend-inner-icon="mdi-magnify"
        />
      </v-sheet>
    </v-card-actions>

    <!-- PRODUCTS -->
    <v-card-title class="header">
      <v-tooltip bottom>
        <template v-slot:activator="{ on, attrs }">
          <v-btn
            @click="isGridView = !isGridView"
            icon
            color="primary"
            v-bind="attrs"
            v-on="on"
          >
            <v-icon v-if="!isGridView">mdi-grid</v-icon>
            <v-icon v-if="isGridView">mdi-format-list-bulleted</v-icon>
          </v-btn>
        </template>
        <span v-if="!isGridView">Grid layout</span>
        <span v-if="isGridView">Lijst layout</span>
      </v-tooltip>

      <v-tooltip bottom>
        <template v-slot:activator="{ on, attrs }">
          <v-btn
            @click="isColorCoded = !isColorCoded"
            icon
            color="primary"
            v-bind="attrs"
            v-on="on"
          >
            <v-icon v-if="!isColorCoded">mdi-invert-colors</v-icon>
            <v-icon v-if="isColorCoded">mdi-invert-colors-off</v-icon>
          </v-btn>
        </template>
        <span v-if="!isColorCoded">Categorie kleuren</span>
        <span v-if="isColorCoded">Thema kleur</span>
      </v-tooltip>

      <v-spacer></v-spacer>

      <div :class="isColorCoded ? 'px-2 mt-n2' : 'px-2 secondary--text mt-n2'">
        {{ categoryName ? categoryName : 'Alles' }}
        ({{ sortedVariants.length }})
      </div>

      <v-spacer></v-spacer>
    </v-card-title>
    <v-container fluid>
      <v-row>
        <category-card
          :categories="categories"
          @set-category="setCategory"
          style="width: 160px"
          :is-color-coded="isColorCoded"
        />
        <v-col class="custom-scroll" v-if="sortedVariants.length > 0">
          <variant-collection
            :variants="sortedVariants"
            :is-grid-view="isGridView"
            :is-color-coded="isColorCoded"
            @add-product="addProductVariant"
          />
        </v-col>
        <v-col v-else :class="$vuetify.breakpoint.xs ? '' : 'pr-16 mr-14'">
          <v-card-text class="text-center">
            <v-icon size="100" color="grey"> mdi-basket-off-outline </v-icon>
            <v-card-title class="justify-center gray--text">
              Geen producten gevonden
            </v-card-title>
            <v-card-subtitle class="subtitle-1">
              Aan de linker kant vind je alle mogelijke categorieen!
            </v-card-subtitle>
          </v-card-text>
        </v-col>
      </v-row>
    </v-container>

    <!-- SHOPPING CART + CATEGORIES -->

    <v-navigation-drawer
      v-model="leftDrawer"
      left
      clipped
      app
      class="elevation-5"
      :permanent="$vuetify.breakpoint.mdAndUp && leftDrawer"
      :width="360"
    >
      <shopping-cart @toggle-leftDrawer="toggleLeftDrawer" />
    </v-navigation-drawer>
  </div>
</template>

<script>
const Swal = require('sweetalert2')
import CategoryCard from '../components/menu-cashier/category-card.vue'
import ShoppingCart from '../components/menu-cashier/shopping-cart.vue'
import VariantCollection from '../components/menu-cashier/variant-collection.vue'
import {
  SERVICE_ROOT,
  CART,
  ORDER,
  GET_TOTAL_PRICE,
  GET_ORDER_PRODUCTS,
  GET_TOTAL_QUANTITY,
  CLEAR_CART,
  ADD_OR_INCREASE_CART_ITEM,
  REMOVE_OR_DECREASE_CART_ITEM,
  RESET_ACTIVE_ORDER,
  GET_CATEGORIES,
  GET_PRODUCTS,
  GET_VARIANTS,
  IS_INITIALIZED,
} from '@/store/constants.js'
import { mapActions, mapGetters } from 'vuex'

export default {
  name: 'Home',
  components: { ShoppingCart, CategoryCard, VariantCollection },

  data() {
    return {
      search: '',
      sortingCategory: -1,
      leftDrawer: true,
      activeProduct: null,
      categoryName: '',
      isGridView: false,
      isColorCoded: false,
    }
  },

  computed: {
    ...mapGetters({
      categories: `${SERVICE_ROOT}/${GET_CATEGORIES}`,
      products: `${SERVICE_ROOT}/${GET_PRODUCTS}`,
      variants: `${SERVICE_ROOT}/${GET_VARIANTS}`,
      getTotalPrice: `${CART}/${GET_TOTAL_PRICE}`,
      getOrderedProducts: `${CART}/${GET_ORDER_PRODUCTS}`,
      getTotalQuantity: `${CART}/${GET_TOTAL_QUANTITY}`,
      isInitialized: `${SERVICE_ROOT}/${IS_INITIALIZED}`,
    }),

    sortedVariants() {
      return this.variants.filter((x) => {
        const categoryMatch =
          this.sortingCategory === -1
            ? true
            : x.category.id == this.sortingCategory

        const searchMatch =
          !this.search || this.search == ''
            ? true
            : x.name.toLowerCase().includes(this.search.toLowerCase()) ||
              x.productName.toLowerCase().includes(this.search.toLowerCase()) ||
              x.variantCode.toLowerCase().includes(this.search.toLowerCase())

        return searchMatch && categoryMatch
      })
    },
  },

  mounted() {
    this.resetOrder()
    this.clearCart()
    document.title = 'Akito - POS'
  },

  methods: {
    ...mapActions({
      addCart: `${CART}/${ADD_OR_INCREASE_CART_ITEM}`,
      decreaseCartItemQuantity: `${CART}/${REMOVE_OR_DECREASE_CART_ITEM}`,
      resetOrder: `${ORDER}/${RESET_ACTIVE_ORDER}`,
      clearCart: `${CART}/${CLEAR_CART}`,
    }),

    setCategory(id) {
      this.activeProduct = null
      this.sortingCategory = id
      if (id > 0) this.findCategoryName(id)
      else this.categoryName = 'Alles'
    },

    addProductVariant(value) {
      this.addCart(value)
      this.search = ''
    },

    findCategoryName(id) {
      this.categoryName = this.categories.find((x) => x.id == id).categoryCode
    },

    setProduct(product) {
      this.activeProduct = product
      this.findCategoryName(this.activeProduct.categoryId)
    },

    toggleLeftDrawer() {
      this.leftDrawer = !this.leftDrawer
    },

    toggleRightDrawer() {
      this.rightDrawer = !this.rightDrawer
    },

    newOrderMade() {
      Swal.fire({
        position: 'top-end',
        icon: 'info',
        title: 'Nieuwe bestelling!',
        toast: true,
        timer: 5000,
      })
    },
  },
}
</script>

<style scoped>
.custom-scroll {
  /* height: calc(100vh - 60px - 290px); */
  height: calc(100vh - 60px - 130px);
  overflow-y: auto;
  min-height: 100px;
}

.header {
  font-size: 34px;
  font-weight: bold;
  padding: 10px 0.5em;
  background: white;
}
</style>
