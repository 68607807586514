import Vue from 'vue'
import { HubConnectionBuilder, LogLevel } from '@microsoft/signalr'

const connection = new HubConnectionBuilder()
  .withUrl(process.env.VUE_APP_API_URL + '/order-hub')
  .configureLogging(LogLevel.Information)
  .build()

connection.start()

const orderHub = new Vue()
Vue.prototype.$orderHub = orderHub


connection.on('NewOrderCreated', () => {
  orderHub.$emit('new-order-created')
})

connection.on('OrderUpdated', () => {
  orderHub.$emit('order-updated')
})

connection.on('OrderedPVUpdated', () => {
  orderHub.$emit('order-updated')
})

connection.on('ForceRefresh', () => {
  orderHub.$emit('force-refresh')
})
