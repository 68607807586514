import Vue from 'vue';
import Vuetify from 'vuetify/lib/framework';

Vue.use(Vuetify);
Vue.use(require('vue-moment'));

export default new Vuetify({
  theme: {
    themes: {
      light: {
        primary: '#7dbe7c',
        secondary: '#14984f',
        darkorange: '#f58218',
        darkblue: '0f6af3',
        status1: '#ff0000',
        status2: '#fbac0e',
        status3: '#568cf3',
        status4: '#35b852',
      },
      dark: {
        primary: '#7dbe7c',
        secondary: '#14984f',
        status1: '#ff0000',
        status2: '#fbac0e',
        status3: '#568cf3',
        status4: '#35b852',
      },
    },
  },
});
