import { PRINT_CUSTOMER, PRINT_KITCHEN } from '../constants'

const actions = {
  async [PRINT_KITCHEN]({ rootState }, param) {
    for (let i = 0; i < param.times; i++) {
      await rootState.SERVICE_ROOT.repository.printer.printKitchen(
        param.orderId
      )
    }
  },

  async [PRINT_CUSTOMER]({ rootState }, param) {
    for (let i = 0; i < param.times; i++) {
      await rootState.SERVICE_ROOT.repository.printer.printCustomer(
        param.orderId
      )
    }
  },
}

export default {
  namespaced: true,
  actions,
}
