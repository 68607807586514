<template>
  <v-menu
    ref="menu"
    v-model="menu"
    :close-on-content-click="false"
    :return-value.sync="date"
    transition="scale-transition"
    offset-y
    min-width="290px"
  >
    <template v-slot:activator="{ on, attrs }">
      <v-text-field
        v-model="date"
        :label="placeholder"
        prepend-inner-icon="mdi-calendar"
        readonly
        dense
        outlined
        color="primary"
        v-bind="attrs"
        hide-details
        v-on="on"
      ></v-text-field>
    </template>
    <v-date-picker v-model="date" no-title @click:date="$refs.menu.save(date)">
    </v-date-picker>
  </v-menu>
</template>

<script>
export default {
  props: {
    placeholder: {
      type: String,
      required: false,
      default: "Kies een datum",
    },

    initialDate: {
      type: String,
      required: true,
    },
  },

  data() {
    return {
      date: this.initialDate,
      menu: null,
    };
  },

  watch: {
    date() {
      this.$emit("date-changed", this.date);
    },
  },
};
</script>
