export const SERVICE_ROOT = 'SERVICE_ROOT'
export const PRODUCT = `${SERVICE_ROOT}/PRODUCT`
export const CART = `${SERVICE_ROOT}/CART`
export const ORDER = `${SERVICE_ROOT}/ORDER`
export const CATEGORY = `${SERVICE_ROOT}/CATEGORY`
export const HELPER = `${SERVICE_ROOT}/HELPER`

export const TIME = 'HH:mm'
export const YEAR_FORMAT = 'YYYY-MM-DD'

/*************************** */
/***    SERVICE ROOT     *** */
/*************************** */
//#region SERVICE_ROOT GETTERS
export const IS_INITIALIZED = 'IS_INITIALIZED'
export const GET_CATEGORIES = 'GET_CATEGORIES'
export const GET_REVENUE = 'GET_REVENUE'
export const GET_KITCHEN_TYPES = 'GET_KITCHEN_TYPES'
export const GET_LOCATION = 'GET_LOCATION'
export const GET_ORDER_STATUSES = 'GET_ORDER_STATUSES'
export const GET_PAYMENT_METHODS = 'GET_PAYMENT_METHODS'
export const GET_PRODUCTS = 'GET_PRODUCTS'
export const GET_ADDITIONS = 'GET_ADDITIONS'
export const GET_VARIANTS = 'GET_VARIANTS'
export const GET_VARIANT_CODE = 'GET_VARIANT_CODE'
export const GET_TIMESLOTS = 'GET_TIMESLOTS'
//#endregion
//#region SERVICE_ROOT ACTIONS
export const INITIALIZE = 'INITIALIZE'
export const SET_PRODUCTS = 'SET_PRODUCTS'
export const SET_CATEGORIES = 'SET_CATEGORIES'
export const SET_ORDER_ADDITIONS = 'SET_ORDER_ADDITIONS'
export const FETCH_REVENUE = 'FETCH_REVENUE'
export const FETCH_TIMESLOTS = 'FETCH_TIMESLOTS'
//#endregion

/*************************** */
/***        CART         *** */
/*************************** */
//#region CART - GETTERS
export const GET_ORDER_PRODUCTS = 'GET_ORDER_PRODUCTS'
export const GET_ORDER_ADDITIONS = 'GET_ORDER_ADDITIONS'
export const GET_TOTAL_QUANTITY = 'GET_TOTAL_QUANTITY'
export const GET_TOTAL_PRICE = 'GET_TOTAL_PRICE'
//#endregion
//#region CART - ACTIONS
export const SET_CART = 'SET_CART'
export const ADD_OR_INCREASE_CART_ITEM = 'ADD_OR_INCREASE_CART_ITEM'
export const REMOVE_OR_DECREASE_CART_ITEM = 'REMOVE_OR_DECREASE_CART_ITEM'
export const CLEAR_CART = 'CLEAR_CART'
//#endregion

/*************************** */
/***        ORDER        *** */
/*************************** */
//#region ORDER - GETTERS
export const GET_POS_ORDERS = 'GET_POS_ORDERS'
export const GET_POS_ORDER_SORTED_BY_STATUS = 'GET_POS_ORDER_SORTED_BY_STATUS'
export const GET_POS_ORDER_SORTED_BY_TIME = 'GET_POS_ORDER_SORTED_BY_TIME'
export const GET_ACTIVE_ORDER = 'GET_ACTIVE_ORDER'
export const GET_EXTERNAL_ORDERS = 'GET_EXTERNAL_ORDERS'

export const GET_AVAILABLE_TIME_SLOTS = 'GET_AVAILABLE_TIME_SLOTS'
export const GET_PRINT_OPTIONS = 'GET_PRINT_OPTIONS'
//#endregion
//#region ORDER - ACTIONS
export const CREATE_ACTIVE_ORDER = 'CREATE_ACTIVE_ORDER'
export const EDIT_ACTIVE_ORDER = 'EDIT_ACTIVE_ORDER'
export const RESET_ACTIVE_ORDER = 'RESET_ACTIVE_ORDER'
export const SET_ACTIVE_ORDER_BY_ID = 'SET_ACTIVE_ORDER_BY_ID'
export const SET_ACTIVE_ORDER = 'SET_ACTIVE_ORDER'
export const SET_NEW_ACTIVE_ORDER = 'SET_NEW_ACTIVE_ORDER'

export const FETCH_EXTERNAL_ORDERS = 'FETCH_EXTERNAL_ORDERS'
export const CREATE_EXTERNAL_ORDER = 'CREATE_EXTERNAL_ORDER'
export const DELETE_EXTERNAL_ORDER = 'DELETE_EXTERNAL_ORDER'

export const FETCH_POS_ORDERS_BY_DATE = 'FETCH_POS_ORDERS_BY_DATE'
export const FETCH_POS_ORDERS_FOR_TODAY = 'FETCH_POS_ORDERS_FOR_TODAY'
export const DELETE_ACTIVE_ORDER_FROM_POS = 'DELETE_ORDER_FROM_POS'
export const SET_PRINT_OPTIONS = 'SET_PRINT_OPTIONS'
export const MARK_VARIANT_COMPLETE = 'MARK_VARIANT_COMPLETE'
export const SET_ORDER_PRODUCTS_AND_ADDITIONS =
  'SET_ORDER_PRODUCTS_AND_ADDITIONS'
export const PRINT = 'PRINT'
//#endregion

/*************************** */
/***       CATEGORY      *** */
/*************************** */
//#region SELECTED CATEGORY
export const GET_SELECTED_CATEGORY = 'GET_SELECTED_CATEGORY'

export const SET_SELECTED_CATEGORY = 'SET_SELECTED_CATEGORY'
export const SAVE_EDIT_CATEGORY = 'SAVE_EDIT_CATEGORY'
export const SAVE_NEW_CATEGORY = 'SAVE_NEW_CATEGORY'
export const DELETE_CATEGORY = 'DELETE_CATEGORY'
export const RESET_CATEGORY = 'RESET_CATEGORY'
//#endregion

/*************************** */
/***       PRODUCT       *** */
/*************************** */
//#region SELECTED PRODUCT
export const GET_SELECTED_PRODUCT = 'GET_SELECTED_PRODUCT'

export const SET_SELECTED_PRODUCT = 'SET_SELECTED_PRODUCT'
export const ADD_PRODUCT_VARIANT = 'ADD_PRODUCT_VARIANT'
export const REMOVE_PRODUCT_VARIANT = 'REMOVE_PRODUCT_VARIANT'
export const UPDATE_PRODUCT_VARIANT = 'UPDATE_PRODUCT_VARIANT'
export const SAVE_EDIT_PRODUCT = 'SAVE_EDIT_PRODUCT'
export const SAVE_NEW_PRODUCT = 'SAVE_NEW_PRODUCT'
export const DELETE_PRODUCT = 'DELETE_PRODUCT'
export const RESET_PRODUCT = 'RESET_PRODUCT'
//#endregion

/*************************** */
/***        HELPER       *** */
/*************************** */
export const PRINT_KITCHEN = 'PRINT_KITCHEN'
export const PRINT_CUSTOMER = 'PRINT_CUSTOMER'
