<template>
  <v-container fluid class="fill-height">
    <v-card-title class="display-3 font-weight-thin"
      >Akito Instructies & Tutorials</v-card-title
    >
    <v-row>
      <v-col
        cols="12"
        sm="6"
        lg="4"
        v-for="(tutorial, i) in tutorials"
        :key="i"
      >
        <v-card>
          <a target="_blank" v-if="tutorial.imgUrl" :href="tutorial.imgUrl">
            <v-img :src="tutorial.imgUrl"> </v-img>
          </a>
          <v-card-title>
            {{ tutorial.title }}
          </v-card-title>
          <v-card-text>
            <div v-for="(step, j) in tutorial.steps" :key="j">
              <strong class="mr-2 secondary--text">{{ j + 1 }}.</strong>
              <span>
                {{ step }}
              </span>
            </div>
          </v-card-text>
          <v-card-actions>
            <v-spacer></v-spacer>
            <v-btn small text color="secondary" @click="selectCard(i)"
              >Bekijk</v-btn
            >
          </v-card-actions>
        </v-card>
      </v-col>
    </v-row>
    <v-dialog
      v-model="fullscreenDialog"
      v-if="selectedTutorial"
      :fullscreen="$vuetify.breakpoint.mobile"
    >
      <v-card>
        <v-card-title class="primary white--text">
          {{ selectedTutorial.title }}
          <v-spacer></v-spacer>
          <v-icon color="white" @click="closeDialog()">mdi-close</v-icon>
        </v-card-title>
        <v-container fluid class="fill-height">
          <v-row>
            <v-col cols="12" lg="8">
              <v-img
                :src="selectedTutorial.imgUrl"
                max-height="80vh"
                max-width="80vw"
                contain
              ></v-img>
            </v-col>
            <v-col>
              <v-card-title>
                {{ selectedTutorial.title }}
              </v-card-title>
              <v-card-text>
                <div v-for="(step, j) in selectedTutorial.steps" :key="j">
                  <strong class="mr-2 secondary--text">{{ j + 1 }}.</strong>
                  <span>
                    {{ step }}
                  </span>
                </div>
              </v-card-text>
              <v-card-actions>
                <v-btn text color="secondary" :to="selectedTutorial.link"
                  >Probeer het zelf!</v-btn
                >
                <v-spacer></v-spacer>
                <v-btn @click="closeDialog()" text>Annuleren</v-btn>
              </v-card-actions>
            </v-col>
          </v-row>
        </v-container>
      </v-card>
    </v-dialog>
  </v-container>
</template>

<script>
export default {
  methods: {
    selectCard(i) {
      this.fullscreenDialog = !this.fullscreenDialog;
      this.selectedTutorial = this.tutorials[i];
    },

    closeDialog() {
      this.fullscreenDialog = !this.fullscreenDialog;
      this.selectedTutorial = null;
    },
  },

  data() {
    return {
      fullscreenDialog: false,
      selectedTutorial: null,
      tutorials: [
        {
          title: "Bestelling maken / bestellen",
          imgUrl: "https://i.imgur.com/VbPCHRO.gif",
          steps: [
            "Kies je product. Dit kan je vinden via categorieen of de zoek balk",
            "Kies een variant van je product. In dit geval heb je 3 keuzes.",
            "Controleer de bestelling en pas het zodanig aan.",
            "Zijn er extra toevoegingen? Denk aan korting, tips en extra ingredienten",
            "Kies een bestelling tijdstip + klant naam.",
            "De knop volgende wordt dan groen.",
          ],
          link: "/cashier",
        },
        // {
        //   title: "Wat zijn dag bestellingen?",
        //   imgUrl: "background.png",
        //   steps: ["Bestel", "vul in details", "betaal"],
        //   link: "/current-orders",
        // },
        // {
        //   title: "Hoe werkt de omzet pagina?",
        //   imgUrl: "background.png",
        //   steps: ["Bestel", "vul in details", "betaal"],
        //   link: "/revenue",
        // },
      ],
    };
  },
};
</script>
