var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-card',{staticClass:"mx-auto",attrs:{"width":"1500px"}},[_c('v-data-table',{staticClass:"elevation-1",attrs:{"headers":_vm.headers,"items":_vm.orders,"sort-by":"deliverTime","items-per-page":-1},scopedSlots:_vm._u([{key:"top",fn:function(){return [_c('v-toolbar',{attrs:{"flat":""}},[_c('v-toolbar-title',{staticClass:"pr-5"},[_vm._v("Bestellingen van")]),_c('date-picker-menu',{attrs:{"placeholder":"Start Datum","initialDate":_vm.startDate},on:{"date-changed":_vm.updateStart}}),_c('div',{staticClass:"mx-2"}),_c('date-picker-menu',{attrs:{"placeholder":"Eind Datum","initialDate":_vm.endDate},on:{"date-changed":_vm.updateEnd}})],1)]},proxy:true},{key:"item.deliverTime",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(_vm.$moment(item.deliverTime).format('DD-MM-YYYY HH:mm'))+" ")]}},{key:"item.trackingNumber",fn:function(ref){
var item = ref.item;
return [_c('b',[_vm._v(" #"+_vm._s(item.trackingNumber)+" ")])]}},{key:"item.total",fn:function(ref){
var item = ref.item;
return [_c('div',[_vm._v("€"+_vm._s(_vm.sumPrice(item).toFixed(2)))])]}},{key:"item.isPaid",fn:function(ref){
var item = ref.item;
return [_c('v-checkbox',{staticClass:"mt-n2",attrs:{"readonly":"","hide-details":""},model:{value:(item.isPaid),callback:function ($$v) {_vm.$set(item, "isPaid", $$v)},expression:"item.isPaid"}})]}},{key:"item.actions",fn:function(ref){
var item = ref.item;
return [_c('v-icon',{staticClass:"mr-2",on:{"click":function($event){return _vm.editItem(item)}}},[_vm._v(" mdi-pencil ")])]}}])}),_c('v-dialog',{model:{value:(_vm.dialog),callback:function ($$v) {_vm.dialog=$$v},expression:"dialog"}},[(_vm.editedItem)?_c('edit-order',{attrs:{"order":_vm.editedItem},on:{"close-dialog":_vm.close}}):_vm._e()],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }