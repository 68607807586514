var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('v-toolbar',{attrs:{"flat":""}},[_c('v-btn',{attrs:{"color":"green","outlined":""},on:{"click":function($event){_vm.show = !_vm.show}}},[_c('v-icon',[_vm._v("mdi-plus")]),_vm._v(" Nieuw ")],1)],1),(_vm.externalOrders.length > 0)?_c('v-data-table',{attrs:{"headers":_vm.headers,"items":_vm.externalOrders,"items-per-page":-1,"footer-props":{
      itemsPerPageOptions: [4, 10, -1],
    }},scopedSlots:_vm._u([{key:"item.deliverTime",fn:function(ref){
    var item = ref.item;
return [_vm._v(" "+_vm._s(_vm.$moment(item.deliverTime).format('YYYY MMM DD'))+" ")]}},{key:"item.totalPrice",fn:function(ref){
    var item = ref.item;
return [_vm._v(" €"+_vm._s(item.totalPrice.toFixed(2))+" ")]}},{key:"item.action",fn:function(ref){
    var item = ref.item;
return [_c('v-btn',{attrs:{"icon":""},on:{"click":function($event){return _vm.deleteExternalOrder(item)}}},[_c('v-icon',[_vm._v(" mdi-delete ")])],1)]}}],null,false,1004075918)}):_vm._e(),_c('v-dialog',{attrs:{"max-width":"400"},model:{value:(_vm.show),callback:function ($$v) {_vm.show=$$v},expression:"show"}},[_c('revenue-adder',{on:{"close-dialog":_vm.onRevenueAdderClosed}})],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }