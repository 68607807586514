<template>
  <v-container fluid>
    <v-row dense>
      <v-col cols="12" md="4">
        <order-menu :orders="orders" @select-order="selectOrderById" />
      </v-col>
      <v-col v-if="!$vuetify.breakpoint.smAndDown" cols="12" md="8">
        <order-details
          :order="activeOrder"
          v-if="activeOrder.id > 0"
        ></order-details>
      </v-col>
    </v-row>
    <v-dialog
      v-model="showOrderDialog"
      v-if="$vuetify.breakpoint.smAndDown"
      fullscreen
    >
      <v-card>
        <v-card-title class="primary white--text py-2">
          <span>#{{ activeOrder.trackingNumber }}</span>
          <v-spacer></v-spacer>
          <v-btn icon color="white" @click="showOrderDialog = false">
            <v-icon>mdi-close</v-icon>
          </v-btn>
        </v-card-title>
        <order-details
          :order="activeOrder"
          v-if="activeOrder.id > 0"
        ></order-details>
        <v-footer padless absolute bottom>
          <v-btn block text @click="showOrderDialog = false" large>
            Terug
          </v-btn>
        </v-footer>
      </v-card>
    </v-dialog>
  </v-container>
</template>

<script>
import OrderDetails from '../components/orders/order-details.vue'
import OrderMenu from '../components/orders/order-menu.vue'
import {
  CART,
  FETCH_POS_ORDERS_FOR_TODAY,
  GET_ACTIVE_ORDER,
  GET_POS_ORDERS,
  ORDER,
  SET_ACTIVE_ORDER_BY_ID,
  SET_CART,
} from '@/store/constants.js'
import { mapGetters, mapActions } from 'vuex'

export default {
  components: {
    OrderMenu,
    OrderDetails,
  },

  data() {
    return {
      showOrderDialog: false,
    }
  },

  async mounted() {
    document.title = 'Akito - Current Orders'
    await this.fetchCurrentOrders()
    if (!this.$vuetify.breakpoint.smAndDown && this.$route.params.id)
      await this.selectOrderById(this.$route.params.id)
  },

  computed: {
    ...mapGetters({
      activeOrder: `${ORDER}/${GET_ACTIVE_ORDER}`,
      orders: `${ORDER}/${GET_POS_ORDERS}`,
    }),
  },

  methods: {
    ...mapActions({
      setOrderById: `${ORDER}/${SET_ACTIVE_ORDER_BY_ID}`,
      setCart: `${CART}/${SET_CART}`,
      fetchCurrentOrders: `${ORDER}/${FETCH_POS_ORDERS_FOR_TODAY}`,
    }),

    async selectOrderById(id) {
      await this.setOrderById(id)
      if (this.activeOrder.id > 0) {
        this.setCart(this.activeOrder)
        this.showOrderDialog = true
      }
    },
  },
}
</script>

<style>
.main {
  height: calc(100vh - 148px);
  overflow-y: auto;
}
</style>
