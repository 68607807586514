<template>
  <v-row justify="center">
    <v-col class="text-center">
      <div class="font-weight-bold title mt-1">
        {{ currentTime }}
      </div>
      <div class="mt-n2 overline">
        {{ this.$moment().format("DD MMM YYYY") }}
      </div>
    </v-col>
  </v-row>
</template>

<script>
// import { onMounted, ref } from "@vue/composition-api";

export default {
  async mounted() {
    setInterval(() => {
      this.updateClock();
    }, 1000);
  },

  data() {
    return {
      currentTime: "",
      username: "akito",
    };
  },

  methods: {
    updateClock() {
      this.currentTime = this.$moment().format("HH:mm:ss");
    },
  },
};
</script>
