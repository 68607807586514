<template>
  <v-card-text>
    <v-list>
      <v-list-item v-for="(product, index) in productsOrdered" :key="index">
        <v-list-item-action class="orange--text font-weight-bold">
          {{ index + 1 }}.
        </v-list-item-action>

        <v-list-item-title>
          <span class="px-3">
            {{ `${product.name}` }}
          </span>
          <small>
            {{ `${product.totalAmountSold}x besteld` }}
          </small>
        </v-list-item-title>
        <v-list-item-title
          >&euro; {{ `${product.totalRevenue.toFixed(2)}` }}</v-list-item-title
        >
      </v-list-item>
    </v-list>
  </v-card-text>
</template>

<script>
export default {
  props: {
    products: Array,
  },

  computed: {
    productsOrdered() {
      return this.products
    },
  },
}
</script>
