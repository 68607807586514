<template>
  <div>
    <div v-for="(order, j) in orders" :key="j">
      <v-list-item
        :class="
          `status-border${order.orderStatusId} ${
            activeOrder.id === order.id ? 'active' : ''
          }`
        "
        @click="setOrder(order.trackingNumber)"
      >
        <v-list-item-action class="font-weight-bold">
          {{ `#${order.trackingNumber}` }}
        </v-list-item-action>
        <v-list-item-title>
          <div class="gray--text title">
            <span>{{ $moment(order.deliverTime).format('HH:mm') }}</span>
          </div>
          {{ `${order.name}` }}
        </v-list-item-title>
        <v-tooltip bottom>
          <template v-slot:activator="{ on, attrs }">
            <v-icon v-if="order.isPaid" color="green" v-bind="attrs" v-on="on"
              >mdi-check
            </v-icon>
            <v-icon v-else color="error" v-bind="attrs" v-on="on"
              >mdi-close</v-icon
            >
          </template>
          <span>Is betaald</span>
        </v-tooltip>
        <v-list-item-action>
          <v-tooltip bottom>
            <template v-slot:activator="{ on, attrs }">
              <div v-bind="attrs" v-on="on">
                <v-icon color="darkblue" v-if="order.isPickUp"
                  >mdi-store</v-icon
                >
                <v-icon color="darkorange" v-else>mdi-moped</v-icon>
              </div>
            </template>
            <span>{{ order.isPickUp ? 'Afhalen' : 'Bezorgen' }}</span>
          </v-tooltip>
        </v-list-item-action>
      </v-list-item>
      <v-divider />
    </div>
  </div>
</template>

<script>
import * as moment from 'moment'
import { mapGetters } from 'vuex'
import { GET_ACTIVE_ORDER, ORDER, TIME } from '../../store/constants.js'
export default {
  props: {
    groupOrders: {
      type: Array,
      required: true,
    },
    search: {
      type: String,
      default: '',
    },
  },

  watch: {
    groupOrders(value) {
      this.groupOrders = value
    },
  },

  computed: {
    ...mapGetters({
      activeOrder: `${ORDER}/${GET_ACTIVE_ORDER}`,
    }),

    orders() {
      return this.groupOrders.filter((item) => {
        if (!this.search) return this.groupOrders
        if (this.search.toLowerCase() === 'afhalen') return item.isPickUp
        if (this.search.toLowerCase() === 'bezorgen') return !item.isPickUp
        return (
          item.name.toLowerCase().includes(this.search.toLowerCase()) ||
          this.search == item.trackingNumber ||
          moment(item.deliverTime)
            .format(TIME)
            .includes(this.search)
        )
      })
    },
  },

  data() {
    return {
      activeNumber: null,
    }
  },

  methods: {
    setOrder(orderNumber) {
      this.$emit('select-order', orderNumber)
      history.replaceState(
        { urlPath: `/current-orders/${orderNumber}` },
        '/current-orders',
        `/current-orders/${orderNumber}`
      )
    },
  },
}
</script>

<style scoped>
.status-border1 {
  border-left: #ff0000 14px solid;
}

.status-border2 {
  border-left: #fbac0e 14px solid;
}

.status-border3 {
  border-left: #568cf3 14px solid;
}

.status-border4 {
  border-left: #35b852 14px solid;
}

.active {
  background-color: rgba(207, 204, 204, 0.349);
}
</style>
