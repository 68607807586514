<template>
  <div>
    <div class="custom-scroll">
      <!-- TABLE OF ORDERED ITEMS -->
      <v-simple-table>
        <template v-slot:default v-if="totalItems > 0">
          <thead>
            <tr>
              <th class="text-left">
                <v-btn
                  icon
                  color="primary"
                  small
                  class="mr-1"
                  @click="clearCart"
                >
                  <v-icon>mdi-refresh</v-icon>
                </v-btn>
                Aantal
              </th>
              <th class="text-left">Product</th>
              <th class="text-left">Prijs</th>
            </tr>
          </thead>
          <tbody>
            <tr v-for="(orderedProduct, i) in orderedProducts" :key="i">
              <td>
                <div style="display: flex" justify="center">
                  <v-btn
                    small
                    color="grey"
                    icon
                    @click="decreaseCart(orderedProduct, true)"
                  >
                    <v-icon>mdi-minus-circle</v-icon>
                  </v-btn>
                  <div class="mx-1 subtitle-1">
                    {{ orderedProduct.quantity }}
                  </div>
                  <v-btn
                    icon
                    small
                    color="grey"
                    @click="increaseCart(orderedProduct, true)"
                  >
                    <v-icon>mdi-plus-circle</v-icon>
                  </v-btn>
                </div>
              </td>
              <td>{{ orderedProduct.name }}</td>
              <td>
                &euro;{{
                  (orderedProduct.price * orderedProduct.quantity).toFixed(2)
                }}
              </td>
            </tr>
            <tr
              class="orange lighten-4"
              v-for="(orderedAddition, i) in orderedAdditions"
              :key="totalItems + i"
            >
              <td>
                <div style="display: flex" justify="center">
                  <v-btn
                    small
                    color="grey"
                    icon
                    @click="decreaseCart(orderedAddition, false)"
                  >
                    <v-icon>mdi-minus-circle</v-icon>
                  </v-btn>
                  <div class="mx-1 subtitle-1">
                    {{ orderedAddition.quantity }}
                  </div>
                  <v-btn
                    icon
                    small
                    color="grey"
                    @click="increaseCart(orderedAddition, false)"
                  >
                    <v-icon>mdi-plus-circle</v-icon>
                  </v-btn>
                </div>
              </td>
              <td>{{ orderedAddition.name }}</td>
              <td>
                &euro;{{
                  (orderedAddition.price * orderedAddition.quantity).toFixed(2)
                }}
              </td>
            </tr>
          </tbody>
        </template>
        <v-card-text class="text-center pt-10" v-if="totalItems < 1">
          <v-icon size="100" color="grey"> mdi-basket-off-outline </v-icon>
          <v-card-title class="justify-center gray--text">
            Geen producten Toegevoegd
          </v-card-title>
          <v-card-subtitle class="subtitle-1">
            Selecteer een product aan de rechterkant.
          </v-card-subtitle>
        </v-card-text>
      </v-simple-table>
    </div>
    <v-divider></v-divider>
    <v-form v-model="orderForm" class="py-2 primary lighten-4">
      <v-list-item class="px-5">
        <v-list-item-content>
          <v-list-item-title class="title">
            Totaal: {{ totalItems }}
          </v-list-item-title>
        </v-list-item-content>
        <v-list-item-action-text class="display-1 secondary--text">
          <span class="px-1"> &euro;{{ totalPrice.toFixed(2) }} </span>
        </v-list-item-action-text>
      </v-list-item>
      <v-card-text>
        <div class="d-flex">
          <v-text-field
            v-model="order.name"
            label="Klant naam"
            dense
            :rules="[(v) => !!v || 'Vul in']"
            persistent-hint
            outlined
            clearable
          ></v-text-field>
          <input-order-time
            :deliverTime="order.deliverTime"
            @set-time="setDeliverTime"
          />
        </div>
        <v-textarea
          v-model="order.description"
          rows="3"
          auto-grow
          outlined
          dense
          hide-details
          label="Beschrijving"
        >
        </v-textarea>
      </v-card-text>
      <v-row class="px-4" style="height: 70px" dense>
        <v-col cols="auto">
          <v-btn color="primary" x-large @click="toggleDialogAddition"
            >+ Extra</v-btn
          >
        </v-col>
        <v-col>
          <v-btn
            x-large
            block
            color="secondary"
            :disabled="totalItems < 1 || !orderForm"
            @click="toggleDialogPay"
          >
            Volgende
          </v-btn>
        </v-col>
      </v-row>
    </v-form>
    <v-footer absolute v-if="$vuetify.breakpoint.smAndDown" padless>
      <v-btn
        text
        color="secondary"
        block
        @click="$emit('toggle-leftDrawer')"
        x-large
      >
        Terug naar kassa</v-btn
      >
    </v-footer>

    <!-- PAYMENT DIALOG  -->
    <v-dialog
      v-model="dialogPay"
      width="500"
      :fullscreen="$vuetify.breakpoint.xs"
    >
      <pay-dialog @close-dialog="toggleDialogPay" @create-order="placeOrder" />
    </v-dialog>

    <v-dialog v-model="dialogAddition" width="400">
      <addition-dialog
        @close-dialog="toggleDialogAddition"
        @add-addition="addCart"
      />
    </v-dialog>
  </div>
</template>

<script>
import PayDialog from '../menu-cashier/pay-dialog.vue'
import AdditionDialog from '../menu-cashier/addition-dialog.vue'
import { mapActions, mapGetters } from 'vuex'
import {
  ADD_OR_INCREASE_CART_ITEM,
  CART,
  CLEAR_CART,
  CREATE_ACTIVE_ORDER,
  GET_AVAILABLE_TIME_SLOTS,
  GET_ORDER_ADDITIONS,
  GET_ORDER_PRODUCTS,
  GET_TOTAL_PRICE,
  GET_TOTAL_QUANTITY,
  ORDER,
  REMOVE_OR_DECREASE_CART_ITEM,
  SET_NEW_ACTIVE_ORDER,
} from '@/store/constants'
import _ from 'lodash'
import InputOrderTime from '../InputOrderTime.vue'

export default {
  components: {
    PayDialog,
    AdditionDialog,
    InputOrderTime,
  },

  data() {
    return {
      orderForm: false,
      dialogPay: false,
      dialogAddition: false,
      order: {
        name: '',
        deliverTime: '16:00',
        description: '',
        isPaid: false,
        isPickUp: true,
        paymentMethodId: 1,
        orderedProducts: this.orderedProducts,
        orderedAdditions: this.orderedAdditions,
        location: null,
      },
    }
  },

  mounted(){
    this.order.deliverTime = this.getAvailableTimeSlots[0] || '16:00'
    this.clearCart()
  },

  computed: {
    ...mapGetters({
      totalPrice: `${CART}/${GET_TOTAL_PRICE}`,
      totalItems: `${CART}/${GET_TOTAL_QUANTITY}`,
      orderedProducts: `${CART}/${GET_ORDER_PRODUCTS}`,
      orderedAdditions: `${CART}/${GET_ORDER_ADDITIONS}`,
      getAvailableTimeSlots: `${ORDER}/${GET_AVAILABLE_TIME_SLOTS}`,
    }),
  },

  methods: {
    ...mapActions({
      setNewOrder: `${ORDER}/${SET_NEW_ACTIVE_ORDER}`,
      createOrder: `${ORDER}/${CREATE_ACTIVE_ORDER}`,
      clearCart: `${CART}/${CLEAR_CART}`,
      addCart: `${CART}/${ADD_OR_INCREASE_CART_ITEM}`,
      decreaseCartItemQuantity: `${CART}/${REMOVE_OR_DECREASE_CART_ITEM}`,
    }),

    increaseCart(item, isProduct) {
      this.addCart({
        item: item,
        isProduct: isProduct,
      })
    },

    decreaseCart(item, isProduct) {
      this.decreaseCartItemQuantity({
        item: item,
        isProduct: isProduct,
      })
    },

    setDeliverTime(value) {
      this.order.deliverTime = value
    },

    async placeOrder(info) {
      this.order.isPaid = info.isPaid
      this.order.isPickUp = info.isPickUp
      this.order.paymentMethodId = info.paymentMethodId
      this.order.location = info.location
      this.order.orderedProducts = this.orderedProducts
      this.order.orderedAdditions = this.orderedAdditions
      this.order.name = this.order.name || 'Anoniem'
      this.order.deliverTime =
        this.order.deliverTime || this.getAvailableTimeSlots[0]
      let orderCopy = _.cloneDeep(this.order)
      this.setNewOrder(orderCopy)
      this.clearCart()
      await this.createOrder(orderCopy)
      this.order = {
        name: '',
        deliverTime: this.getAvailableTimeSlots[0],
        description: '',
        isPaid: false,
        isPickUp: true,
        paymentMethodId: 1,
        orderedProducts: this.orderedProducts,
        orderedAdditions: this.orderedAdditions,
        location: null,
      }
    },

    toggleDialogPay() {
      this.dialogPay = !this.dialogPay
    },

    toggleDialogAddition() {
      this.dialogAddition = !this.dialogAddition
    },
  },
}
</script>

<style scoped>
.custom-scroll {
  /* height: calc(100vh - 60px - 150px); */
  height: calc(100vh - 60px - 200px - 108px);
  overflow-y: auto;
  /* overflow-y: hidden; */
}

/* .custom-scroll .products {
  height: calc(100vh - 60px - 200px - 150px);
  min-height: 200px;
  overflow-y: auto;
}*/

/* .custom-scroll .additions {
  height: 200px;
  overflow-y: hidden;
}  */
</style>
