<template>
  <v-container fluid class="pa-6">
    <v-row>
      <v-col v-for="(block, i) in blocks" :key="i" cols="12" md="4">
        <v-card :color="block.color" dark class="text-left">
          <v-card-title>
            <span
              :class="$vuetify.breakpoint.mobile ? 'text-h4' : 'text-h3 pl-3'"
            >
              <span v-if="i === 0">{{ totalOrders }} </span>
              <span v-if="i === 1"> &euro; {{ totalRevenue }} </span>
              <span v-if="i === 2" class="text-h4">
                {{ productOfDay() }}
              </span>
              <div class="subtitle-1">
                {{ block.title }}
              </div>
            </span>
            <v-spacer></v-spacer>
            <v-icon size="56" right v-if="i < 2"> {{ block.icon }} </v-icon>
          </v-card-title>
        </v-card>
      </v-col>
    </v-row>
    <v-row>
      <v-col cols="12" md="6" lg="4">
        <v-card class="payment">
          <v-card-title>
            <v-sheet color="transparent" width="50%" class="pa-1">
              <date-picker-menu
                placeholder="Start datum"
                :initial-date="startDate"
                @date-changed="updateStartDate"
              />
            </v-sheet>
            <v-sheet color="transparent" width="50%" class="pa-1">
              <date-picker-menu
                placeholder="Eind datum"
                :initial-date="endDate"
                @date-changed="updateEndDate"
              />
            </v-sheet>
          </v-card-title>
          <div class="text-center pt-4">
            <v-progress-circular
              :rotate="-90"
              :size="120"
              :width="10"
              :value="
                (
                  (paidOrders.length + externalOrders.length / totalOrders) *
                  100
                ).toFixed()
              "
              color="cyan"
            >
              <div>
                {{
                  (
                    ((paidOrders.length + externalOrders.length) /
                      totalOrders) *
                    100
                  ).toFixed()
                }}
                % betaald
              </div>
            </v-progress-circular>
          </div>
          <v-list class="pb-0">
            <v-list-item class="font-weight-bold">
              <v-list-item-content> Naam </v-list-item-content>
              <v-spacer></v-spacer>
              Bedrag
              <v-list-item-action>
                <small> Aantal </small>
              </v-list-item-action>
            </v-list-item>
            <v-divider></v-divider>
            <v-list-item v-for="(p, i) in getPaymentMethods" :key="i">
              <v-list-item-content> {{ p.name }} </v-list-item-content>
              <v-spacer></v-spacer>
              &euro; {{ getTotalSumPaymentMethod(p.name) }}
              <v-list-item-action class="ml-7">
                {{ paymentMethodOrder(p.id).length }}
              </v-list-item-action>
            </v-list-item>
            <div v-if="externalOrders.length > 0">
              <!-- TODO: GET SUBTOTAL-->
              <!-- <hr />
              <v-list-item class="font-weight-bold">
                SubTotaal
                <v-spacer></v-spacer>
                &euro; {{ totalRevenue }}
                <v-list-item-action class="ml-7">
                  {{ totalOrders }}
                </v-list-item-action>
              </v-list-item>
              <hr /> -->
              <div v-for="(source, i) in sources" :key="i">
                <v-list-item v-if="externalRevenue(source)">
                  <v-list-item-content>
                    {{ source }}
                  </v-list-item-content>
                  <v-spacer></v-spacer>
                  &euro; {{ externalRevenue(source) }}
                  <v-list-item-action class="ml-7">
                    {{ totalExternalSources(source) }}
                  </v-list-item-action>
                </v-list-item>
              </div>
            </div>
            <v-divider></v-divider>
            <v-list-item class="font-weight-bold">
              <v-list-item-content> Totaal </v-list-item-content>
              <v-spacer></v-spacer>
              &euro; {{ totalRevenue }}
              <v-list-item-action class="ml-7">
                {{ totalOrders }}
              </v-list-item-action>
            </v-list-item>
          </v-list>
        </v-card>
      </v-col>
      <v-col cols="12" md="6" lg="8">
        <v-tabs v-model="tab" background-color="transparent" hide-slider>
          <v-tab active-class="secondary--text white">
            Externe bestellingen
          </v-tab>
          <v-tab active-class="secondary--text white" v-if="categories">
            Omzet per Categorie
          </v-tab>
          <v-tab active-class="orange--text white" v-if="products.length > 0">
            Verkochte producten
          </v-tab>
        </v-tabs>

        <v-tabs-items v-model="tab">
          <v-tab-item>
            <v-card>
              <div class="external-orders">
                <external-orders :date="startDate" />
              </div>
            </v-card>
          </v-tab-item>
          <v-tab-item>
            <v-card>
              <category-graph
                v-if="categories"
                :categorieRevenue="categories"
              />
            </v-card>
          </v-tab-item>
          <v-tab-item>
            <v-card>
              <product-graph v-if="products.length > 0" :products="products" />
            </v-card>
          </v-tab-item>
        </v-tabs-items>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
import {
  FETCH_EXTERNAL_ORDERS,
  FETCH_POS_ORDERS_BY_DATE,
  FETCH_POS_ORDERS_FOR_TODAY,
  FETCH_REVENUE,
  GET_EXTERNAL_ORDERS,
  GET_PAYMENT_METHODS,
  GET_POS_ORDERS,
  GET_REVENUE,
  ORDER,
  SERVICE_ROOT,
} from '@/store/constants.js'
import ExternalOrders from '@/components/revenue/ExternalOrders.vue'
import DatePickerMenu from '@/components/revenue/DatePickerMenu.vue'
import { mapActions, mapGetters } from 'vuex'
import ProductGraph from '@/components/revenue/ProductGraph.vue'
import CategoryGraph from '@/components/revenue/CategoryGraph.vue'
export default {
  components: {
    DatePickerMenu,
    ExternalOrders,
    ProductGraph,
    CategoryGraph,
  },

  computed: {
    ...mapGetters({
      orders: `${ORDER}/${GET_POS_ORDERS}`,
      getPaymentMethods: `${SERVICE_ROOT}/${GET_PAYMENT_METHODS}`,
      externalOrders: `${ORDER}/${GET_EXTERNAL_ORDERS}`,
      getRevenue: `${SERVICE_ROOT}/${GET_REVENUE}`,
    }),

    paidOrders() {
      return this.orders.filter((x) => x.isPaid)
    },

    totalOrders() {
      return this.orders.length + this.externalOrders.length
    },

    products() {
      if (this.getRevenue == null || this.getRevenue?.productsSold == 0)
        return []
      else {
        const sorted = this.sortArray()
        return sorted
      }
    },

    categories() {
      if (this.getRevenue == null || this.getRevenue?.totalRevenue == 0)
        return null
      else {
        return this.getRevenue.categoriesRevenue
      }
    },

    totalRevenue() {
      if (this.getRevenue == null || this.getRevenue?.totalRevenue == 0)
        return 0
      return this.getRevenue?.totalRevenue?.toFixed(2)
    },
  },

  async mounted() {
    document.title = 'Akito - Revenue'

    const date = {
      start: this.startDate,
      end: this.endDate,
    }
    await this.fetchCurrentOrders()
    await this.fetchExternalOrders(date)
    await this.fetchRevenue(date)
  },

  methods: {
    ...mapActions({
      fetchCurrentOrders: `${ORDER}/${FETCH_POS_ORDERS_FOR_TODAY}`,
      fetchOrders: `${ORDER}/${FETCH_POS_ORDERS_BY_DATE}`,
      fetchExternalOrders: `${ORDER}/${FETCH_EXTERNAL_ORDERS}`,
      fetchRevenue: `${SERVICE_ROOT}/${FETCH_REVENUE}`,
    }),

    sortArray() {
      const sorted = this.getRevenue.productsSold.sort((a, b) => {
        return b.totalRevenue - a.totalRevenue
      })
      return sorted
    },

    productOfDay() {
      if (this.getRevenue == null || this.getRevenue?.productsSold == 0)
        return `-`

      const maximum = this.products[0].name
      return `${maximum}`
    },

    updateStartDate(value) {
      this.startDate = value
      const date = {
        start: this.$moment(value)
          .startOf('day')
          .toISOString(),
        end: this.endDate,
      }
      this.fetchOrders(date)
      this.fetchExternalOrders(date)
      this.fetchRevenue(date)
    },

    updateEndDate(value) {
      this.endDate = value
      const date = {
        start: this.startDate,
        end: this.$moment(value)
          .endOf('day')
          .toISOString(),
      }
      this.fetchOrders(date)
      this.fetchExternalOrders(date)
      this.fetchRevenue(date)
    },

    externalRevenue(source) {
      const list = this.externalOrders.filter(
        (x) => x.externalSource === source
      )
      if (list.length === 0) {
        return 0
      }
      return list.reduce((sum, order) => sum + order.totalPrice, 0.0).toFixed(2)
    },

    totalExternalSources(source) {
      const list = this.externalOrders.filter(
        (x) => x.externalSource === source
      )
      return list.length
    },

    paymentMethodOrder(i) {
      let orders = this.orders
      return orders.filter((x) => x.paymentMethodId === i)
    },

    getTotalSumPaymentMethod(i) {
      if (this.getRevenue == null || this.getRevenue?.totalRevenue == 0)
        return 0

      for (const [key, value] of Object.entries(
        this.getRevenue.paymentMethodsRevenue
      )) {
        if (key == i) return value.toFixed(2)
      }
      return 0
    },
  },

  data() {
    return {
      startDate: this.$moment().format('YYYY-MM-DD'),
      endDate: this.$moment().format('YYYY-MM-DD'),
      sources: ['Thuisbezorgd', 'Retour', 'Tips', 'Tikkie', 'Extern', 'Extra'],
      tab: 0,
      blocks: [
        {
          id: 1,
          title: 'Bestellingen',
          value: '-',
          icon: 'mdi-text-box-check',
          color: 'cyan',
          link: '/current-orders',
        },

        {
          id: 2,
          title: 'Dag Opbrengst',
          value: '-',
          icon: 'mdi-currency-eur',
          color: 'green',
        },
        {
          id: 3,
          title: 'Product van de dag',
          value: '-',
          icon: 'mdi-noodles',
          color: 'orange',
        },
      ],
    }
  },
}
</script>
