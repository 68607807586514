<template>
  <v-form v-model="valid">
    <v-card>
      <v-card-title>
        <v-btn class="mr-2" icon color="primary" @click="$emit('close-dialog')">
          <v-icon> mdi-close </v-icon>
        </v-btn>
        Bestelling Afronden
      </v-card-title>
      <v-divider></v-divider>
      <v-card-text class="custom-scroll pb-0">
        <div class="d-flex justify-center">
          <v-btn-toggle
            v-model="info.isPickUp"
            rounded
            mandatory
            class="elevation-1"
            color="secondary"
            background-color="white"
          >
            <v-btn outlined value="true" @click="resetAddress">
              <v-icon class="mr-2" color="darkblue"> mdi-store </v-icon>
              Afhalen
            </v-btn>
            <v-btn outlined value="false">
              Bezorgen
              <v-icon color="darkorange" class="ml-2"> mdi-moped </v-icon>
            </v-btn>
          </v-btn-toggle>
        </div>
        <v-card-text class="text-center">
          <div class="red--text">
            Bezorgen alleen in eersel. Gratis vanaf 30 euro!
          </div>
          <div class="font-weight-bold">
            LET OP! Voeg bezorg kosten zelf toe indien nodig.
          </div>
        </v-card-text>
        <div v-if="info.isPickUp == 'false'">
          <v-card-text class="pb-0">
            <div class="d-flex">
              <v-text-field
                outlined
                dense
                label="Postcode"
                v-model="info.location.zipCode"
                :rules="postCodeRule"
                clearable
              ></v-text-field>
              <v-text-field
                outlined
                :rules="numberRule"
                dense
                label="Huis Nummer"
                v-model="info.location.houseNumber"
                clearable
              ></v-text-field>
              <v-text-field
                outlined
                dense
                label="Toevoeging"
                v-model="info.location.houseAddition"
              ></v-text-field>
              <v-btn icon color="primary" @click="getAddress">
                <v-icon>mdi-map-search</v-icon>
              </v-btn>
            </div>
            <div class="d-flex">
              <v-text-field
                outlined
                dense
                label="Straat"
                v-model="info.location.street"
                :rules="emptyRule"
                clearable
              ></v-text-field>
              <v-text-field
                outlined
                :rules="numberRule"
                dense
                label="Huis Nummer"
                v-model="info.location.houseNumber"
                clearable
              ></v-text-field>
              <v-text-field
                outlined
                dense
                label="Toevoeging"
                v-model="info.location.houseAddition"
              ></v-text-field>
              <v-btn
                icon
                color="primary"
                target="_blank"
                :href="
                  `https://www.google.com/maps/search/${info.location.street},+${info.location.houseNumber}+${info.location.city}`
                "
              >
                <v-icon>mdi-map-marker</v-icon>
              </v-btn>
            </div>
            <v-text-field
              outlined
              dense
              label="Stad"
              v-model="info.location.city"
              :rules="emptyRule"
              clearable
            ></v-text-field>
          </v-card-text>
        </div>
      </v-card-text>
      <v-divider></v-divider>
      <v-card-text>
        <v-btn-toggle v-model="info.paymentMethodId" color="secondary">
          <v-btn
            v-for="(payment, i) in getPaymentMethods"
            :key="i"
            :value="payment.id"
          >
            {{ payment.name }}
          </v-btn>
        </v-btn-toggle>
        <print-dialog />
      </v-card-text>
      <v-divider></v-divider>
      <v-card-actions>
        <v-btn text x-large @click="$emit('close-dialog')">
          <v-icon class="pr-2">mdi-arrow-left</v-icon>
          Terug
        </v-btn>
        <v-spacer></v-spacer>
        <v-btn
          color="primary"
          x-large
          @click="createOrder(false)"
          depressed
          class="px-5"
          :disabled="!valid || totalItems < 1"
        >
          Opslaan
        </v-btn>
        <v-btn
          color="secondary"
          x-large
          @click="createOrder(true)"
          depressed
          :disabled="!valid || totalItems < 1"
          class="px-5"
        >
          <v-icon class="pr-2">mdi-hand-coin</v-icon>
          Betaald
        </v-btn>
      </v-card-actions>
    </v-card>
  </v-form>
</template>

<script>
import { mapGetters } from 'vuex'
import {
  SERVICE_ROOT,
  CART,
  ORDER,
  GET_TOTAL_QUANTITY,
  GET_AVAILABLE_TIME_SLOTS,
  GET_PAYMENT_METHODS,
} from '@/store/constants'
import PrintDialog from './print-dialog'
import axios from 'axios'
const Swal = require('sweetalert2')

export default {
  components: {
    PrintDialog,
  },

  computed: {
    ...mapGetters({
      getPaymentMethods: `${SERVICE_ROOT}/${GET_PAYMENT_METHODS}`,
      totalItems: `${CART}/${GET_TOTAL_QUANTITY}`,
      getAvailableTimeSlots: `${ORDER}/${GET_AVAILABLE_TIME_SLOTS}`,
    }),
  },

  data() {
    return {
      emptyRule: [(v) => !!v || 'Mag niet leeg.'],
      postCodeRule: [
        (v) => !!v || 'PostCode a.u.b.',
        (v) =>
          /^[1-9][0-9]{3} ?(?!sa|sd|ss|SA|SD|SS)[A-Za-z]{2}$/.test(v) ||
          'Postcode klopt niet.',
      ],
      numberRule: [
        (v) => !!v || 'Huisnummer',
        (v) => /^[0-9]*$/.test(v) || 'Alleen nummers',
      ],
      valid: true,
      info: {
        name: '',
        deliverTime: '16:00',
        description: '',
        isPaid: false,
        isPickUp: true,
        paymentMethodId: 1,
        location: {
          city: 'Eersel',
          zipCode: '5521EH',
          houseNumber: 41,
          houseAddition: '',
          street: 'Lindestraat',
        },
      },
    }
  },

  methods: {
    resetAddress() {
      if (!this.info.location.city) this.info.location.city = 'Eersel'
      if (!this.info.location.street) this.info.location.street = 'Lindestraat'
      if (!this.info.location.houseNumber) this.info.location.houseNumber = 41
      if (!this.info.location.zipCode) this.info.location.zipCode = '5521EH'
    },

    getAddress() {
      const api = `https://postcode.tech/api/v1/postcode/full?postcode=${this.info.location.zipCode}&number=${this.info.location.houseNumber}`
      const token = `664ab897-0f11-4888-8d63-77e7f50c8f22`
      axios
        .get(api, { headers: { Authorization: `Bearer ${token}` } })
        .then((res) => {
          const result = res.data
          this.info.location.city = result.city
          this.info.location.street = result.street
          this.info.location.zipCode = result.postcode
          this.info.location.houseNumber = result.number
          Swal.fire({
            icon: 'success',
            position: 'top-end',
            title: 'Adres gevonden!',
            toast: true,
            timer: 5000,
          })
        })
        .catch((error) => {
          Swal.fire({
            icon: 'error',
            toast: true,
            position: 'top-end',
            title: 'Adres onjuist',
            text: error,
            timer: 5000,
          })
        })
    },

    createOrder(isPaid) {
      this.$emit('create-order', {
        isPaid: isPaid,
        isPickUp: this.info.isPickUp,
        paymentMethodId: this.info.paymentMethodId,
        location: this.info.location,
      })
      this.$emit('close-dialog')
      this.info = {
        isPaid: false,
        isPickUp: true,
        paymentMethodId: 1,
        location: {
          city: 'Eersel',
          zipCode: '5521EH',
          houseNumber: 41,
          houseAddition: '',
          street: 'Lindestraat',
        },
      }
    },
  },
}
</script>

<style scoped>
.custom-scroll {
  overflow-y: auto;
  max-height: calc(100vh - 60px - 410px);
}
@media only screen and (max-width: 600px) {
  .custom-scroll {
    height: calc(100vh - 60px - 250px);
    min-height: 100px;
  }
}
</style>
