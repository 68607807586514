<template>
  <v-card width="1500px" class="mx-auto">
    <v-data-table
      :headers="headers"
      :items="orders"
      sort-by="deliverTime"
      class="elevation-1"
      :items-per-page="-1"
    >
      <template v-slot:top>
        <v-toolbar flat>
          <v-toolbar-title class="pr-5">Bestellingen van</v-toolbar-title>
          <date-picker-menu
            placeholder="Start Datum"
            :initialDate="startDate"
            @date-changed="updateStart"
          />
          <div class="mx-2"></div>
          <date-picker-menu
            placeholder="Eind Datum"
            :initialDate="endDate"
            @date-changed="updateEnd"
          />
        </v-toolbar>
      </template>
      <template v-slot:item.deliverTime="{ item }">
        {{ $moment(item.deliverTime).format('DD-MM-YYYY HH:mm') }}
      </template>
      <template v-slot:item.trackingNumber="{ item }">
        <b> #{{ item.trackingNumber }} </b>
      </template>
      <template v-slot:item.total="{ item }">
        <div>&euro;{{ sumPrice(item).toFixed(2) }}</div>
      </template>
      <template v-slot:item.isPaid="{ item }">
        <v-checkbox v-model="item.isPaid" readonly hide-details class="mt-n2">
        </v-checkbox>
      </template>
      <template v-slot:item.actions="{ item }">
        <v-icon class="mr-2" @click="editItem(item)"> mdi-pencil </v-icon>
      </template>
    </v-data-table>
    <v-dialog v-model="dialog">
      <edit-order @close-dialog="close" :order="editedItem" v-if="editedItem" />
    </v-dialog>
  </v-card>
</template>

<script>
import DatePickerMenu from '@/components/revenue/DatePickerMenu.vue'
import {
  CART,
  FETCH_POS_ORDERS_BY_DATE,
  GET_POS_ORDERS,
  ORDER,
  SET_ACTIVE_ORDER,
  SET_CART,
} from '@/store/constants'
import { mapActions, mapGetters } from 'vuex'
import EditOrder from '@/components/orders/edit-order.vue'

export default {
  components: { DatePickerMenu, EditOrder },

  data() {
    return {
      dialog: false,
      headers: [
        { text: 'Datum', value: 'deliverTime' },
        { text: 'Bestel#', value: 'trackingNumber' },
        { text: 'Naam', value: 'name' },
        { text: 'Betaal Method', value: 'paymentMethod.name' },
        { text: 'Betaald', value: 'isPaid' },
        { text: 'Totaal', value: 'total' },
        { text: 'Acties', value: 'actions' },
      ],
      startDate: this.$moment().format('YYYY-MM-DD'),
      endDate: this.$moment().format('YYYY-MM-DD'),
      editedItem: null,
    }
  },

  computed: {
    ...mapGetters({
      orders: `${ORDER}/${GET_POS_ORDERS}`,
    }),
  },

  watch: {
    dialog(val) {
      val || this.close()
    },
  },

  mounted() {
    this.updateDate()
  },

  methods: {
    ...mapActions({
      fetchOrders: `${ORDER}/${FETCH_POS_ORDERS_BY_DATE}`,
      setOrder: `${ORDER}/${SET_ACTIVE_ORDER}`,
      setCart: `${CART}/${SET_CART}`,
    }),

    sumPrice(order) {
      return (
        order.orderedPVs.reduce(
          (tempSum, product) => tempSum + product.price * product.quantity,
          0.0
        ) +
        order.orderAdditions.reduce((sum, a) => sum + a.quantity * a.price, 0.0)
      )
    },
    updateStart(value) {
      this.startDate = value
      this.updateDate()
    },

    updateEnd(value) {
      this.endDate = value
      this.updateDate()
    },

    updateDate() {
      const date = {
        start: this.$moment(this.startDate)
          .startOf('day')
          .toISOString(),
        end: this.$moment(this.endDate)
          .endOf('day')
          .toISOString(),
      }
      this.fetchOrders(date)
    },

    editItem(item) {
      this.setCart(item)
      this.setOrder(item)
      this.editedItem = item
      this.dialog = true
    },

    deleteItem(item) {
      alert('delete ', item.name)
    },

    close() {
      this.dialog = false
      this.editedItem = null
    },

    save() {
      if (this.editedIndex > -1) {
        Object.assign(this.orders[this.editedIndex], this.editedItem)
      }
      this.close()
    },
  },
}
</script>
