<template>
  <v-card v-if="product">
    <v-card-title>
      <span class="headline mr-2">{{ title }}</span>
      <v-progress-circular
        v-if="processing"
        indeterminate
        color="primary"
      ></v-progress-circular>
      <v-spacer />
      <v-btn icon color="greenbtn" @click="close" :disabled="processing">
        <v-icon>mdi-close</v-icon>
      </v-btn>
    </v-card-title>

    <v-divider />
    <div class="custom-scroll" v-if="getSelectedProduct">
      <v-list-item class="px-6" v-if="product.imageUrl">
        <v-list-item-avatar size="100" rounded>
          <v-img :src="product.imageUrl" />
        </v-list-item-avatar>
      </v-list-item>

      <v-form class="pa-6 pt-3">
        <!-- Name field -->
        <v-text-field
          v-model="product.name"
          outlined
          dense
          label="Naam"
          hint="Dit wordt weergegeven aan de klant."
          :rules="[rules.required]"
          maxlength="64"
          counter
          :disabled="processing"
        />
        <!-- Description field -->
        <v-textarea
          v-model="product.description"
          label="Beschrijving"
          outlined
          auto-grow
          maxlength="255"
          counter
          rows="2"
          dense
          hint="Voeg informatie toe voor de klant."
          :disabled="processing"
        />
        <!-- Category field -->
        <v-select
          v-model="product.categoryId"
          :items="getCategories"
          outlined
          label="Categorie"
          item-text="name"
          item-value="id"
          dense
          :rules="[rules.category]"
          :disabled="processing"
        />
        <!-- IsPickUpOnly field -->
        <v-card flat width="220" class="mx-3">
          <v-switch
            v-model="product.isPickUpOnly"
            inset
            label="Alleen ahaalbaar"
            class="mt-0 pt-0"
            color="blue"
            hide-details
            :disabled="processing"
          />
        </v-card>

        <!-- Product Variants section -->
        <p
          class="mt-2 red--text"
          v-if="getSelectedProduct.variants.length == 0 && !processing"
        >
          Er moet minstens één variant bestaan.
        </p>
        <variant-section class="mt-5" />

        <v-btn
          v-if="!isCreating"
          color="error"
          class="mt-4"
          @click="dialogDelete = true"
          :disabled="processing"
        >
          <v-icon class="mr-1"> mdi-trash-can </v-icon>
          Verwijder Product
        </v-btn>
      </v-form>
      <v-dialog v-model="dialogDelete" max-width="500px">
        <v-card>
          <v-card-title class="headline">Bevestig verwijderen?</v-card-title>
          <v-card-actions>
            <v-spacer></v-spacer>
            <v-btn
              color="blue darken-1"
              text
              @click="dialogDelete = false"
              :disabled="processing"
              >Annuleren</v-btn
            >
            <v-btn
              color="blue darken-1"
              text
              @click="attemptDeleteProduct"
              :disabled="processing"
              >OK</v-btn
            >
            <v-spacer></v-spacer>
          </v-card-actions>
        </v-card>
      </v-dialog>
    </div>
    <v-footer color="white">
      <v-btn
        block
        color="greenbtn"
        large
        :disabled="!isValid"
        :dark="isValid"
        @click="saveProduct"
      >
        Opslaan
        <v-icon class="ml-2"> mdi-check-circle </v-icon>
      </v-btn>
      <v-btn
        v-if="$vuetify.breakpoint.xs"
        block
        color="secondary"
        outlined
        large
        class="mt-3"
        @click="close"
        :disabled="processing"
      >
        Annuleren
      </v-btn>
    </v-footer>
  </v-card>
</template>

<script>
import { mapGetters, mapActions } from 'vuex'
import {
  SERVICE_ROOT,
  PRODUCT,
  GET_CATEGORIES,
  GET_KITCHEN_TYPES,
  GET_SELECTED_PRODUCT,
  SAVE_EDIT_PRODUCT,
  SAVE_NEW_PRODUCT,
  SET_SELECTED_PRODUCT,
  DELETE_PRODUCT,
} from '@/store/constants.js'
import VariantSection from '@/components/products/VariantSection'

export default {
  components: {
    VariantSection,
  },

  props: {
    isCreating: {
      type: Boolean,
      required: true,
    },
    title: {
      type: String,
      require: true,
    },
  },

  data() {
    return {
      rules: {
        required: (value) => !!value || 'Verplicht.',
        category: (value) => value >= 1 || 'Verplicht.',
      },
      initialized: false,
      dialogDelete: false,
      product: null,
      processing: false,
    }
  },

  computed: {
    ...mapGetters({
      getCategories: `${SERVICE_ROOT}/${GET_CATEGORIES}`,
      getKitchenTypes: `${SERVICE_ROOT}/${GET_KITCHEN_TYPES}`,
      getSelectedProduct: `${PRODUCT}/${GET_SELECTED_PRODUCT}`,
    }),

    isValid() {
      return (
        this.getSelectedProduct.categoryId >= 1 &&
        this.getSelectedProduct.variants?.length >= 1 &&
        !this.processing
      )
    },
  },

  watch: {
    getSelectedProduct() {
      this.setProduct()
    },
  },

  mounted() {
    this.setProduct()
  },

  methods: {
    ...mapActions({
      saveEditProduct: `${PRODUCT}/${SAVE_EDIT_PRODUCT}`,
      saveNewProduct: `${PRODUCT}/${SAVE_NEW_PRODUCT}`,
      setSelectedProduct: `${PRODUCT}/${SET_SELECTED_PRODUCT}`,
      deleteProduct: `${PRODUCT}/${DELETE_PRODUCT}`,
    }),

    async attemptDeleteProduct() {
      this.processing = true
      await this.deleteProduct()
      this.close()
      this.processing = false
    },

    close() {
      this.$emit('close-dialog')
    },

    setProduct() {
      this.product = {
        categoryId: this.getSelectedProduct.categoryId,
        description: this.getSelectedProduct.description,
        displayOrder: this.getSelectedProduct.displayOrder,
        id: this.getSelectedProduct.id,
        imageUrl: this.getSelectedProduct.imageUrl,
        isPickUpOnly: this.getSelectedProduct.isPickUpOnly,
        name: this.getSelectedProduct.name,
        taxRate: this.getSelectedProduct.taxRate,
        variants: this.getSelectedProduct.variants,
      }
    },

    async saveProduct() {
      this.processing = true
      this.product.variants = this.getSelectedProduct.variants
      this.setSelectedProduct(this.product)
      if (this.isCreating) await this.saveNewProduct()
      else await this.saveEditProduct()
      this.close()
      this.processing = false
    },
  },
}
</script>

<style scoped>
.custom-scroll {
  max-height: calc(100vh - 200px);
  overflow-y: auto;
}
</style>
