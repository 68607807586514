<template>
  <v-container :class="$vuetify.breakpoint.xs ? '' : 'pt-8 px-8'" fluid>
    <v-row :dense="$vuetify.breakpoint.xs">
      <v-col cols="12" lg="12">
        <v-row :dense="$vuetify.breakpoint.xs">
          <v-col v-for="(nav, i) in navItems" :key="i" cols="4">
            <v-card
              class="text-center pa-2"
              :to="nav.link"
              :draggable="false"
              :dropzone="false"
              :disabled="nav.admin"
              flat
            >
              <v-icon v-if="nav.admin" small> mdi-lock </v-icon>
              <v-icon
                size="40"
                class="pb-2"
                :color="nav.admin ? '' : 'secondary'"
              >
                {{ nav.icon }}
              </v-icon>
              <div :class="$vuetify.breakpoint.smAndDown ? '' : 'title'">
                {{ nav.name }}
              </div>
              <i class="caption grey--text" v-if="$vuetify.breakpoint.mdAndUp">
                {{ nav.text }}
              </i>
            </v-card>
          </v-col>
        </v-row>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
import { ref } from '@vue/composition-api'
export default {
  setup() {
    const gradients = ref([
      ['#222'],
      ['#42b3f4'],
      ['red', 'orange', 'yellow'],
      ['purple', 'violet'],
      ['#00c6ff', '#F0F', '#FF0'],
      ['#f72047', '#ffd200', '#1feaea'],
    ])
    const graph = ref({
      radius: 5,
      lineCap: 'round',
      gradient: gradients.value[5],
      value: [0, 2, 5, 9, 5, 10, 3, 5, 0],
      gradientDirection: 'top',
      gradients,
      labels: [
        '16:00',
        '16:30',
        '17:00',
        '17:30',
        '18:00',
        '18:30',
        '19:00',
        '19:30',
        '20:00',
      ],
    })
    const navItems = ref([
      {
        icon: 'mdi-hand-coin',
        name: 'Kassa',
        text: 'Voor nieuwe bestellingen',
        link: '/cashier',
        admin: false,
      },
      {
        icon: 'mdi-text-box-check',
        name: 'Dag bestellingen',
        text: 'Alle bestelling van vandaag',
        link: '/current-orders',
        admin: false,
      },
      {
        icon: 'mdi-clipboard-list-outline',
        name: 'Keuken bonnen',
        text: 'Bonnen overzicht voor keuken',
        link: '/kitchen-orders',
        admin: false,
      },
      {
        icon: 'mdi-finance',
        name: 'Omzet overzicht',
        text: 'Totale omzet en statistieken',
        link: '/revenue',
        admin: false,
      },
      {
        icon: 'mdi-noodles',
        name: 'Producten',
        text: 'Backoffice voor producten',
        link: '/products',
        admin: false,
      },
      {
        icon: 'mdi-archive',
        name: 'Alle bestellingen',
        text: 'Overzicht van alle bestellingen',
        link: '/allorders',
        admin: false,
      },
      // {
      //   icon: "mdi-cog",
      //   name: "Instellingen",
      //   text: "Beheer openings tijden, website en online",
      //   link: "/settings",
      //   admin: true,
      // },
      // {
      //   icon: "mdi-account-multiple",
      //   name: "Administratie",
      //   text: "Beheer beschikbaarheid van medewerkers",
      //   link: "/administration",
      //   admin: true,
      // },
      {
        icon: 'mdi-help',
        name: 'Help',
        text: 'Volg instructies en tutorials',
        link: '/help',
        admin: false,
      },
    ])

    return { navItems, graph, gradients }
  },
}
</script>
