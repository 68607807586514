<template>
  <div>
    <v-row dense v-if="isGridView">
      <v-col
        v-for="(item, i) in variants"
        :key="i"
        cols="12"
        sm="6"
        lg="3"
        class="pa-2"
      >
        <v-card
          height="150"
          v-if="item.isEnabled"
          ripple
          :disabled="item.isSoldOut"
          class="product-card"
          @click="addProductToCart(item)"
        >
          <v-img height="56" src="https://i.imgur.com/rrj7W6u.jpg">
            <v-chip
              style="position: absolute; right:0; "
              :color="isColorCoded ? item.category.colorCode : ''"
              class="subtitle-1 ma-3 pa-4"
              >&euro;{{ item.price.toFixed(2) }}</v-chip
            >
          </v-img>
          <div class="text-left mx-4 pt-2">
            <div class="header">{{ item.variantCode }}</div>
            <div class="text-wrap pb-2 grey--text text--darken-2">
              {{ item.name }}
            </div>
          </div>
        </v-card>
      </v-col>
    </v-row>
    <v-row v-else>
      <v-col
        v-for="(item, i) in variants"
        :key="i + variants.length"
        cols="12"
        sm="6"
        lg="3"
        class="pa-2"
      >
        <v-card
          v-if="item.isEnabled"
          ripple
          height="80"
          class="product-card pa-2"
          :disabled="item.isSoldOut"
          @click="addProductToCart(item)"
        >
          <div>
            <div
              v-if="isColorCoded"
              :style="`height: 2px; background: ` + item.category.colorCode"
            ></div>
            <div class="title d-flex justify-content-between">
              {{ item.variantCode }}
              <v-spacer></v-spacer>
              <v-chip
                class="grey--text px-0"
                color="transparent"
                style="position: aboslute; left:0 "
                >&euro;{{ item.price.toFixed(2) }}</v-chip
              >
            </div>
            <div style="font-size: 14px">
              {{ item.name }}
            </div>
          </div>
        </v-card>
      </v-col>
    </v-row>
  </div>
</template>

<script>
export default {
  props: {
    variants: {
      type: Array,
      required: true,
    },

    isGridView: Boolean,
    isColorCoded: Boolean,
  },

  methods: {
    addProductToCart(variant) {
      const product = {
        name: variant.name,
        price: variant.price,
        variantId: variant.id,
        category: variant.category.name,
        variantCode: variant.variantCode,
        taxRate: variant.taxRate,
      }

      this.$emit('add-product', { item: product, isProduct: true })
    },
  },
}
</script>

<style scoped>
.header {
  font-size: 30px;
  font-family: 'Gill Sans', 'Gill Sans MT', Calibri, 'Trebuchet MS', sans-serif;
}

@media only screen and (max-width: 600px) {
  .header {
    font-size: 24px;
  }
}
</style>
