import { getInstance } from './index'
import store from '@/store/index'
import { INITIALIZE, IS_INITIALIZED, SERVICE_ROOT } from '@/store/constants.js'

export const authGuard = (to, from, next) => {
  const authService = getInstance()

  const fn = async () => {
    // If the user is authenticated, continue with the route
    if (authService.isAuthenticated) {
      const authInitialized = store.getters[`${SERVICE_ROOT}/${IS_INITIALIZED}`]
      if (!authInitialized) {
        const token = await authService.auth0Client.getTokenSilently()
        await store.dispatch(`${SERVICE_ROOT}/${INITIALIZE}`, token)
      }

      return next()
    }

    // Otherwise, log in
    authService.loginWithRedirect({ appState: { targetUrl: to.fullPath } })
  }

  // If loading has already finished, check our auth state using `fn()`
  if (!authService.loading) {
    return fn()
  }

  // Watch for the loading property to change before we check isAuthenticated
  authService.$watch('loading', (loading) => {
    if (loading === false) {
      return fn()
    }
  })
}
