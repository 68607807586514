<template>
  <v-card>
    <v-card-title class="green white--text">
      <span>Externe bestellingen</span>
      <v-spacer></v-spacer>
      <v-icon dark @click="$emit('close-dialog', false)">mdi-close</v-icon>
    </v-card-title>
    <v-card-text class="pa-4">
      <v-select v-model="name" :items="sources" label="Bron naam" solo>
      </v-select>
      <div>
        <v-text-field
          placeholder="0.00"
          v-model="amount"
          label="Bedrag"
          solo
          prepend-inner-icon="mdi-currency-eur"
        >
        </v-text-field>
      </div>
    </v-card-text>
    <v-divider></v-divider>
    <v-card-actions>
      <v-btn text @click="$emit('close-dialog', false)">Annuleren</v-btn>
      <v-spacer></v-spacer>
      <v-btn text color="green" @click="add">
        <v-icon>mdi-plus</v-icon>
        Toevoegen
      </v-btn>
    </v-card-actions>
  </v-card>
</template>

<script>
import { CREATE_EXTERNAL_ORDER, ORDER } from '@/store/constants'
import { mapActions } from 'vuex'
export default {
  data() {
    return {
      isEdit: false,
      amount: 1,
      sources: ['Thuisbezorgd', 'Retour', 'Tips', 'Tikkie', 'Extern', 'Extra'],
      name: 'Thuisbezorgd',
    }
  },

  methods: {
    ...mapActions({
      createExternalOrder: `${ORDER}/${CREATE_EXTERNAL_ORDER}`,
    }),

    add() {
      // call post api
      this.createExternalOrder({
        externalSource: this.name,
        totalPrice: this.amount,
      })
      this.$emit('close-dialog', true)
    },
  },
}
</script>
