<template>
  <div>
    <v-toolbar flat>
      <v-btn color="green" outlined @click="show = !show">
        <v-icon>mdi-plus</v-icon>
        Nieuw
      </v-btn>
    </v-toolbar>
    <v-data-table
      v-if="externalOrders.length > 0"
      :headers="headers"
      :items="externalOrders"
      :items-per-page="-1"
      :footer-props="{
        itemsPerPageOptions: [4, 10, -1],
      }"
    >
      <template v-slot:item.deliverTime="{ item }">
        {{ $moment(item.deliverTime).format('YYYY MMM DD') }}
      </template>
      <template v-slot:item.totalPrice="{ item }">
        &euro;{{ item.totalPrice.toFixed(2) }}
      </template>
      <template v-slot:item.action="{ item }">
        <v-btn icon @click="deleteExternalOrder(item)">
          <v-icon> mdi-delete </v-icon>
        </v-btn>
      </template>
    </v-data-table>

    <v-dialog v-model="show" max-width="400">
      <revenue-adder @close-dialog="onRevenueAdderClosed"></revenue-adder>
    </v-dialog>
  </div>
</template>
<script>
import RevenueAdder from '@/components/revenue/RevenueAdder.vue'
import {
  DELETE_EXTERNAL_ORDER,
  FETCH_REVENUE,
  GET_EXTERNAL_ORDERS,
  ORDER,
  SERVICE_ROOT,
} from '@/store/constants'
import { mapActions, mapGetters } from 'vuex'
export default {
  components: {
    RevenueAdder,
  },
  props: {
    date: {
      type: String,
      required: true,
    },
  },
  computed: {
    ...mapGetters({
      externalOrders: `${ORDER}/${GET_EXTERNAL_ORDERS}`,
    }),
  },

  methods: {
    ...mapActions({
      deleteExternalOrder: `${ORDER}/${DELETE_EXTERNAL_ORDER}`,
      fetchRevenue: `${SERVICE_ROOT}/${FETCH_REVENUE}`,
    }),

    async onRevenueAdderClosed(check) {
      this.show = !this.show
      if (check) {
        await this.fetchRevenue(this.date)
      }
    },
  },

  data() {
    return {
      pagination: {
        ascending: true,
        sortBy: 'name',
        rowsPerPage: 2,
        page: 1,
      },
      show: false,
      headers: [
        { text: 'Datum', value: 'deliverTime' },
        { text: 'Bron', value: 'externalSource' },
        { text: 'Bedrag', value: 'totalPrice' },
        { text: 'Acties', value: 'action' },
      ],
    }
  },
}
</script>
