<template>
  <div>
    <v-card-title> Wat is nieuw? </v-card-title>
    <v-timeline dense clipped class="news">
      <v-slide-x-transition group>
        <v-timeline-item
          v-for="event in timeline"
          :key="event.id"
          class="mb-4"
          :color="event.color"
          small
        >
          <v-card>
            <v-container>
              <v-row justify="space-between" no-gutters>
                <v-col cols="8">
                  <div>
                    {{ event.text }}
                  </div>
                  <a target="_blank" :href="event.imgUrl">
                    <v-img
                      v-if="event.imgUrl"
                      :src="event.imgUrl"
                      max-height="180"
                      max-width="200"
                      contain
                      class="my-1"
                      alt="Image "
                    >
                    </v-img>
                  </a>
                  <v-btn
                    small
                    color="primary"
                    rounded
                    elevation="1"
                    class="mt-1"
                    :to="event.link"
                    v-if="event.link"
                  >
                    {{ event.link_text }}
                  </v-btn>
                </v-col>
                <v-col class="text-right" cols="4">
                  <div>
                    {{ event.author }}
                  </div>
                  <div class="caption">
                    {{ event.time }}
                  </div>
                  <div class="caption font-weight-bold">
                    {{ event.version }}
                  </div>
                </v-col>
              </v-row>
            </v-container>
          </v-card>
        </v-timeline-item>
      </v-slide-x-transition>
    </v-timeline>
  </div>
</template>
<script>
export default {
  data: () => ({
    events: [
      {
        id: 0,
        author: 'Kelly',
        text: 'Externe bestellingen voor thuisbezorgd / tikkie werkt nu!',
        time: '19 07 2021',
        link: '/revenue',
        link_text: 'Naar Dag omzet',
        color: 'pink',
        imgUrl: '',
      },
      {
        id: 1,
        author: 'Kelly',
        text: 'Bekijk nieuwe keuken bon scherm!',
        time: '19 07 2021',
        link: '/kitchen-orders',
        link_text: 'Bekijk',
        imgUrl: '',
        color: 'green',
      },
      {
        id: 2,
        author: 'Kelly',
        text: 'Heb je hulp nodig? Er is nu een hulp pagina met nieuwe instructies & tutorials',
        time: '19 07 2021',
        link: '/help',
        link_text: 'Help',
        color: 'green',
        imgUrl: '',
      },
      {
        id: 3,
        author: 'Kelly',
        text: 'Dag bestellingen werken weer normaal! ',
        version: 'v0.1.1',
        time: '22 07 2021',
        link: '/current-orders',
        link_text: 'Dag bestelling',
        color: 'pink',
        imgUrl: 'https://i.imgur.com/wIG5Vi4.gif',
      },
      {
        id: 4,
        author: 'Kelly',
        text: 'Dag omzet van het verleden is nu ook zichtbaar!',
        version: 'v0.1.2',
        time: '24 07 2021',
        link: '/revenue',
        link_text: 'Dag Omzet',
        color: 'pink',
      },
      {
        id: 5,
        author: 'Kelly',
        text: 'Kassa zoekbalk en bestelling tijd verbeterd!',
        version: 'v0.1.3',
        time: '20 08 2021',
        link: '/cashier',
        link_text: 'Naar kassa',
        color: 'pink',
      },
      {
        id: 6,
        author: 'Kelly',
        text: 'Geel voor frituur en groen voor salade. Nu te zien bij de digitale keuken bonnen!',
        version: 'v0.1.4',
        time: '08 12 2021',
        link: '/kitchen-orders',
        link_text: 'Keuken bonnen',
        imgUrl: 'https://i.imgur.com/rm8Nq2r.gif',
        color: 'green',
      },
      {
        id: 7,
        author: 'Kelly',
        text: 'Gedefineerde toevoegingen kan je nu gebruiken voor bijvoorbeeld: Mix 12 Vegan',
        version: 'v0.1.5',
        time: '09 12 2021',
        link: '/cashier',
        link_text: 'Bekijk Kassa',
        imgUrl: 'https://i.imgur.com/iTGlzKo.gif',
        color: 'green',
      },
      {
        id: 8,
        author: 'Kelly',
        text: 'Adres toevoegen in de bestelling is nu mogelijk in de kassa scherm.',
        version: 'v0.1.6',
        time: '09 12 2021',
        link: '/cashier',
        link_text: 'Bekijk Kassa',
        imgUrl: 'https://i.imgur.com/KimI8Pi.gif',
        color: 'green',
      },
      {
        id: 9,
        author: 'Kelly',
        text: 'Nieuw Kassa systeem 2.0',
        version: 'v0.2.0',
        time: '10 12 2021',
        link: '/cashier',
        link_text: 'Bekijk Kassa',
        color: 'green',
      },
      {
        id: 10,
        author: 'Jeffrey',
        text: 'KDS helper',
        version: 'v0.2.1',
        time: '02 01 2022',
        link: '/kitchen-orders',
        link_text: 'Bekijk KDS',
        imgUrl: 'https://i.imgur.com/n3rTGpF.gif',
        color: 'green',
      },
      {
        id: 11,
        author: 'Kelly',
        text: 'Product achtergrond naar wit.',
        version: 'v0.2.3',
        time: '07 01 2022',
        link: '/cashier',
        link_text: 'Kassa',
        color: 'pink',
      },
      {
        id: 12,
        author: 'Kelly',
        text: 'Kassa met klant naam, tijd en beschrijving.',
        version: 'v0.2.4',
        time: '17 01 2022',
        link: '/cashier',
        link_text: 'Kassa',
        color: 'green',
      },
      {
        id: 13,
        author: 'Kelly',
        text: 'User friendlier POS',
        version: 'v0.2.4.1',
        time: '11 06 2022',
        link: '/cashier',
        link_text: 'Kassa',
        color: 'pink',
      },
      {
        id: 14,
        author: 'Kelly',
        text: 'Dag Omzet met top 10 product namen',
        version: 'v0.2.5',
        time: '11 06 2022',
        link: '/revenue',
        link_text: 'Bekijk Omzet',
        color: 'green',
      },
      {
        id: 15,
        author: 'Kelly',
        text: 'PostCode check werkt nu',
        version: 'v0.3.0',
        time: '12 06 2022',
        link: '/cashier',
        link_text: 'Kassa',
        color: 'green',
      },
      {
        id: 16,
        author: 'Kelly',
        text: 'Fix extern omzet + producten omzet toonbaar',
        version: 'v0.3.2',
        time: '18 06 2022',
        color: 'pink',
      },
      {
        id: 17,
        author: 'Kelly',
        text: 'Code refactor vuex',
        version: 'v0.3.3',
        time: '18 06 2022',
        color: 'grey',
      },
      {
        id: 18,
        author: 'Jeffrey',
        text: 'Grafiek met de omzet per tijdstrook',
        version: 'v0.3.4',
        time: '19 06 2022',
        color: 'pink',
      },
      {
        id: 19,
        author: 'Kelly',
        text: 'Omzet per categorie beschikbaar',
        version: 'v0.3.5',
        time: '23 06 2022',
        link: '/revenue',
        link_text: 'Naar omzet!',
        color: 'pink',
      },
      {
        id: 20,
        author: 'Kelly',
        text: 'Omzet tijdstrook grafiek met kleurcode en schatting.',
        version: 'v0.3.6',
        time: '23 06 2022',
        link: '/',
        link_text: 'Zie Dashboard',
        imgUrl: 'https://miro.medium.com/max/1400/1*EVoT-miKeeuIgPHX0To8DQ.gif',
        color: 'pink',
      },
      {
        id: 21,
        author: 'Kelly',
        text: 'Snelle fix voor KDS en postcode. Grafiek is nu 3.30 euro per min.',
        version: 'v0.3.7',
        time: '24 06 2022',
        color: 'pink',
      },
      {
        id: 22,
        author: 'Jeffrey',
        text: 'Datum periode voor omzet. Categorie omzet gesorteerd.',
        version: 'v0.3.8',
        time: '15 07 2022',
        color: 'pink',
      },
      {
        id: 23,
        author: 'Kelly',
        text: 'Producten kunnen worden toegevoegd.',
        version: 'v0.3.9',
        time: '04 12 2022',
        color: 'pink',
      },
      {
        id: 24,
        author: 'Kelly',
        text: 'Plastic toeslag automatisch en onder extra',
        version: 'v0.3.10',
        time: '30 06 2023',
        color: 'pink',
      },
      {
        id: 25,
        author: 'Kelly',
        text: 'Plastic toeslag automatisch 10 cent per product',
        version: 'v0.3.11',
        time: '01 07 2023',
        color: 'pink',
      },
    ],
    input: null,
    nonce: 0,
  }),

  computed: {
    timeline() {
      return this.events.slice().reverse()
    },
  },

  methods: {},
}
</script>

<style scoped>
@media only screen and (min-width: 1250px) {
  .news {
    height: calc(100vh - 100px);
    padding-right: 20px;
    margin-left: -10px;
    overflow-y: auto;
  }
}
</style>
