import {
  ADD_OR_INCREASE_CART_ITEM,
  CLEAR_CART,
  GET_ORDER_ADDITIONS,
  GET_ORDER_PRODUCTS,
  GET_TOTAL_PRICE,
  GET_TOTAL_QUANTITY,
  REMOVE_OR_DECREASE_CART_ITEM,
  SET_CART,
} from '../constants'

const PLASTIC_FEE_NAME = 'Plastic toeslag'
const PLASTIC_FEE_PER_PRODUCT = 0.1

// STATES
const state = () => ({
  orderProducts: [],
  orderAdditions: [],
})

//#region CART - GETTERS
const getters = {
  [GET_ORDER_PRODUCTS]: (state) => {
    return state.orderProducts
  },

  [GET_ORDER_ADDITIONS]: (state) => {
    return state.orderAdditions
  },

  [GET_TOTAL_QUANTITY]: (state) => {
    let plasticQuantity =
      state.orderAdditions.find((x) => x.name == PLASTIC_FEE_NAME)?.quantity ??
      0

    return (
      state.orderProducts.reduce((sum, product) => sum + product.quantity, 0) +
      state.orderAdditions.reduce(
        (sum, addition) => sum + addition.quantity,
        0
      ) -
      plasticQuantity
    )
  },

  [GET_TOTAL_PRICE]: (state) => {
    if (state.orderProducts.length === 0 && state.orderAdditions.length === 0)
      return 0.0

    return (
      state.orderProducts.reduce(
        (sum, product) => sum + product.quantity * product.price,
        0.0
      ) +
      state.orderAdditions.reduce(
        (sum, addition) => sum + addition.quantity * addition.price,
        0.0
      )
    )
  },
}
//#endregion

//#region CART - ACTIONS
const actions = {
  async [SET_CART]({ commit }, order) {
    commit(`${SET_CART}`, order)
  },

  [ADD_OR_INCREASE_CART_ITEM]({ commit }, cartItem) {
    commit(`${ADD_OR_INCREASE_CART_ITEM}`, cartItem)
  },

  [REMOVE_OR_DECREASE_CART_ITEM]({ commit }, cartItem) {
    commit(`${REMOVE_OR_DECREASE_CART_ITEM}`, cartItem)
  },

  [CLEAR_CART]({ commit }) {
    commit(`${CLEAR_CART}`)
  },
}
//#endregion

//#region CART - MUTATIONS
const mutations = {
  [SET_CART](state, order) {
    state.orderProducts = order.orderedPVs
    state.orderAdditions = order.orderAdditions
  },

  [ADD_OR_INCREASE_CART_ITEM](state, cartItem) {
    const cartItems = cartItem.isProduct
      ? state.orderProducts
      : state.orderAdditions
    const foundItem = cartItems.find(
      (x) => x.name == cartItem.item.name && x.price == cartItem.item.price
    )
    if (foundItem) foundItem.quantity++
    else {
      if (cartItem.isProduct) {
        state.orderProducts.push({
          name: cartItem.item.name, // product name + variant name
          price: parseFloat(cartItem.item.price), // variant price
          variantId: cartItem.item.variantId,
          taxRate: cartItem.item.taxRate,
          category: cartItem.item.category,
          variantCode: cartItem.item.variantCode,
          isCompleted: false,
          quantity: 1,
        })
      } else {
        state.orderAdditions.push({
          name: cartItem.item.name, // Addition name
          price: cartItem.item.price, // Additon price
          quantity: 1,
        })
      }
    }

    if (cartItem.isProduct) {
      let foundPlasticFee = state.orderAdditions.find(
        (x) => x.name == PLASTIC_FEE_NAME
      )

      if (foundPlasticFee) {
        foundPlasticFee.quantity++
      } else {
        state.orderAdditions.push({
          name: PLASTIC_FEE_NAME, // Addition name
          price: PLASTIC_FEE_PER_PRODUCT, // Additon price
          quantity: 1,
        })
      }
    }
  },

  [REMOVE_OR_DECREASE_CART_ITEM](state, cartItem) {
    const cartItems = cartItem.isProduct
      ? state.orderProducts
      : state.orderAdditions
    if (cartItem.item.quantity === 1) {
      if (cartItem.isProduct)
        state.orderProducts = cartItems.filter(
          (x) => x.name !== cartItem.item.name
        )
      else
        state.orderAdditions = cartItems.filter(
          (x) => x.name !== cartItem.item.name
        )
    } else cartItem.item.quantity--

    if (cartItem.isProduct) {
      let foundPlasticFee = state.orderAdditions.find(
        (x) => x.name == PLASTIC_FEE_NAME
      )

      if (foundPlasticFee.quantity === 1) {
        state.orderAdditions = cartItems.filter(
          (x) => x.name !== cartItem.item.name
        )
      } else foundPlasticFee.quantity--
    }
  },

  [CLEAR_CART](state) {
    state.orderProducts = []
    state.orderAdditions = []
  },
}
//#endregion

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations,
}
