<template>
  <div>
    <v-list-item class="px-0" v-for="(option, i) in options" :key="i">
      <div class="d-flex mr-2">
        <v-btn small color="grey" icon @click="print(option.id, false)">
          <v-icon>mdi-minus-circle</v-icon>
        </v-btn>
        <div class="mx-1 subtitle-1">{{ option.times }}</div>
        <v-btn icon small color="grey" @click="print(option.id, true)">
          <v-icon>mdi-plus-circle</v-icon>
        </v-btn>
      </div>
      <v-list-item-title>{{ option.name }} </v-list-item-title>
    </v-list-item>
  </div>
</template>

<script>
import { GET_PRINT_OPTIONS, ORDER, SET_PRINT_OPTIONS } from '@/store/constants'
import { mapActions, mapGetters } from 'vuex'
export default {
  data() {
    return {
      options: [
        {
          name: 'Klant Bon',
          id: 1,
          times: 1,
        },
        {
          name: 'Keuken Bon',
          id: 2,
          times: 2,
        },
      ],
    }
  },

  computed: {
    ...mapGetters({
      getPrintOptions: `${ORDER}/${GET_PRINT_OPTIONS}`,
    }),
  },

  methods: {
    ...mapActions({
      setPrintOptions: `${ORDER}/${SET_PRINT_OPTIONS}`,
    }),

    print(id, increase) {
      const foundOption = this.options.find((x) => x.id === id)
      if (increase && foundOption.times < 6) foundOption.times++
      else if (!increase && foundOption.times > 0) foundOption.times--
      this.setPrint()
    },

    setPrint() {
      this.setPrintOptions({
        customer: this.options[0].times,
        kitchen: this.options[1].times,
      })
    },
  },
}
</script>
