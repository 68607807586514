<template>
  <div style="overflow: hidden; height: calc(100vh - 64px)">
    <v-img src="blackboard.jpg">
      <v-container
        class="mt-3"
        fluid
        style="overflow-y: auto; height: calc(100vh - 64px)"
      >
        <!-- KDS helper toggle -->
        <v-btn
          class="mx-2 kds-helper-btn"
          fab
          dark
          color="teal"
          @click="kdsHelper = !kdsHelper"
        >
          <v-icon dark>
            mdi-format-list-bulleted-square
          </v-icon>
        </v-btn>

        <!-- KDS helper drawer -->
        <v-navigation-drawer v-model="kdsHelper" absolute right width="400">
          <template v-slot:prepend>
            <h2 class="ma-2">KDS Helper</h2>
          </template>

          <v-card-text>
            <v-text-field
              v-model="kdsHelperStartTime"
              label="Start tijd"
              dense
              outlined
              prepend-inner-icon="mdi-clock"
              :rules="timerules"
              clearable
              persistent-hint
            ></v-text-field>

            <v-text-field
              v-model="kdsHelperEndTime"
              label="Eind tijd"
              dense
              outlined
              prepend-inner-icon="mdi-clock"
              :rules="timerules"
              clearable
              persistent-hint
            ></v-text-field>

            <v-text-field
              v-model="kdsHelperSearch"
              label="Product naam"
              clearable
              outlined
              dense
            >
            </v-text-field>
          </v-card-text>

          <v-divider></v-divider>

          <v-simple-table class="kds-table">
            <template v-slot:default>
              <thead>
                <tr>
                  <th class="text-left">
                    Product
                  </th>
                  <th class="text-left">
                    Quantity
                  </th>
                </tr>
              </thead>
              <tbody>
                <tr v-for="(item, i) in productQuantities" :key="i">
                  <td>{{ item.product }}</td>
                  <td>{{ item.quantity }}</td>
                </tr>
              </tbody>
            </template>
          </v-simple-table>
        </v-navigation-drawer>

        <!-- End KDS helper -->
        <v-row
          v-if="readyOrders.length > 0"
          justify="start"
          style="overflow-x: auto"
        >
          <v-card-title class="white--text">Klaar: </v-card-title>
          <v-col v-for="(item, i) in readyOrders" :key="i" cols="auto">
            <v-btn color="blue" dark rounded @click="setStatusBack(item)">
              # {{ item.trackingNumber }}
              <v-icon class="pr-1 pl-2" v-if="!item.isPickUp" size="20"
                >mdi-moped</v-icon
              >
              <div v-else class="px-2">-</div>
              {{ getOrderTime(item) }}
            </v-btn>
          </v-col>
        </v-row>
        <v-row v-if="orders.length > 0">
          <v-col v-for="(item, i) in orders" :key="i" cols="12" md="6" lg="4">
            <v-card>
              <v-card-title :class="isTime(item)">
                <v-icon dark class="mr-3" v-if="isUrgent(item)"
                  >mdi-alert-circle</v-icon
                >
                <div>
                  {{ getOrderTime(item) }}
                </div>
                <v-spacer v-if="!item.isPickUp"></v-spacer>
                <v-spacer></v-spacer>
                <v-btn
                  dark
                  rounded
                  :to="`/current-orders/${item.trackingNumber}`"
                >
                  <div style="font-size: 20px">#{{ item.trackingNumber }}</div>
                </v-btn>
              </v-card-title>
              <div
                v-if="!item.isPickUp"
                class="text-center py-1 orange white--text"
              >
                <v-icon class="mx-2" color="white">mdi-moped</v-icon>
                Bezorgen
              </div>
              <div></div>
              <v-card-text v-if="item.description">
                <b style="font-size: 18px" class="text-wrap">
                  {{ item.description }}
                </b>
              </v-card-text>
              <v-divider v-if="item.description"></v-divider>

              <v-simple-table
                fixed-header
                max-height="300px"
                v-if="item.orderedPVs.length > 0"
              >
                <template v-slot:default>
                  <tbody>
                    <tr
                      v-for="product in sortProducts(item.orderedPVs)"
                      :key="product.name"
                      :class="styleCategory(product)"
                    >
                      <td width="50px" class="title font-weight-regular">
                        <b> {{ product.quantity }}x </b>
                      </td>
                      <td class="title font-weight-regular">
                        <div>
                          {{ product.name }}
                        </div>
                      </td>
                      <td width="20px">
                        <v-checkbox
                          @click="markComplete(product)"
                          v-model="product.isCompleted"
                        ></v-checkbox>
                      </td>
                    </tr>
                  </tbody>
                </template>
              </v-simple-table>
              <v-divider v-if="item.orderAdditions.length > 0"></v-divider>
              <v-simple-table max-height="200px">
                <template v-slot:default>
                  <tbody>
                    <tr
                      v-for="addition in sortOrderAddition(item.orderAdditions)"
                      :key="addition.name"
                    >
                      <td width="50px" class="title font-weight-regular">
                        <b> {{ addition.quantity }}x </b>
                      </td>
                      <td class="title font-weight-regular">
                        {{ addition.name }}
                      </td>
                      <td width="22px">
                        <v-checkbox color="grey"></v-checkbox>
                      </td>
                    </tr>
                  </tbody>
                </template>
              </v-simple-table>
              <v-divider></v-divider>
              <div class="title py-1 d-flex justify-content-between">
                <div class="px-2">
                  Totaal ({{
                    item.orderedPVs.length + item.orderAdditions.length
                  }})
                </div>
                <v-spacer></v-spacer>
                <div class="px-2 font-weight-regular">
                  Klant: {{ item.name }}
                </div>
              </div>
              <v-btn @click="changeStatus(item)" class="mb-4" block dark large>
                Klaar</v-btn
              >
            </v-card>
          </v-col>
        </v-row>
        <v-row v-else>
          <v-col v-if="unsortedOrders.length < 1">
            <v-card-text class="text-center py-8 white--text">
              <v-icon size="100" color="white">
                mdi-clock-time-ten-outline
              </v-icon>
              <v-card-title class="justify-center">
                Geen bestellingen
              </v-card-title>
              <v-card-subtitle>
                Momenteel zijn er nog geen bestellingen binnen.
              </v-card-subtitle>
              <v-btn color="error" class="mt-8" large to="/cashier">
                <v-icon class="mr-2"> mdi-plus </v-icon>
                Nieuwe bestelling
              </v-btn>
            </v-card-text>
          </v-col>
          <v-col v-else>
            <v-card-text class="text-center py-8 white--text">
              <v-icon size="100" color="white">
                mdi-check-circle-outline
              </v-icon>
              <v-card-title class="justify-center">
                Alle bestellingen staan klaar
              </v-card-title>
              <v-card-subtitle>
                Momenteel zijn alle bestelling bereid!
              </v-card-subtitle>
              <v-btn color="error" class="mt-8 px-8" large to="/cashier">
                <v-icon class="mr-2"> mdi-plus </v-icon>
                Nieuwe bestelling
              </v-btn>
              <div></div>
              <v-btn color="secondary" class="mt-4" large to="/current-orders">
                <v-icon class="mr-2"> mdi-text-box-check </v-icon>
                Naar Dag bestelling
              </v-btn>
            </v-card-text>
          </v-col>
        </v-row>
      </v-container>
    </v-img>
  </div>
</template>

<script>
import { mapActions, mapGetters } from 'vuex'
import {
  SERVICE_ROOT,
  ORDER,
  TIME,
  EDIT_ACTIVE_ORDER,
  GET_POS_ORDER_SORTED_BY_STATUS,
  FETCH_POS_ORDERS_FOR_TODAY,
  MARK_VARIANT_COMPLETE,
  SET_ACTIVE_ORDER,
  GET_ORDER_STATUSES,
  GET_CATEGORIES,
} from '@/store/constants.js'
const Swal = require('sweetalert2')
import * as moment from 'moment'
import { cloneDeep } from 'lodash'

export default {
  data() {
    return {
      timerules: [
        (v) => !!v || 'Vul in',
        (v) =>
          /^([0-1]?[0-9]|2[0-3])((:)?[0-5][0-9])?$/.test(v) ||
          'Tijd klopt niet',
      ],
      receiptModel: null,
      search: '',
      categoryNames: [],
      currentTime: moment(),
      kdsHelper: false,
      kdsHelperSearch: '',
      kdsHelperStartTime: '15:00',
      kdsHelperEndTime: '20:00',
    }
  },

  computed: {
    ...mapGetters({
      unsortedOrders: `${ORDER}/${GET_POS_ORDER_SORTED_BY_STATUS}`,
      orderStatuses: `${SERVICE_ROOT}/${GET_ORDER_STATUSES}`,
      categories: `${SERVICE_ROOT}/${GET_CATEGORIES}`,
    }),

    orders() {
      let unsortedOrders = this.unsortedOrders.filter(
        (order) => order.orderStatusId < 3
      )

      if (this.kdsHelperStartTime) {
        unsortedOrders = unsortedOrders.filter((x) => {
          const deliverTime = moment(x.deliverTime)
          const startTime = moment(this.kdsHelperStartTime, TIME)
          return deliverTime >= startTime
        })
      }

      if (this.kdsHelperEndTime) {
        unsortedOrders = unsortedOrders.filter((x) => {
          const deliverTime = moment(x.deliverTime)
          const endTime = moment(this.kdsHelperEndTime, TIME)
          return deliverTime <= endTime
        })
      }

      return unsortedOrders
    },

    readyOrders() {
      let unsortedOrders = this.unsortedOrders.filter(
        (order) => order.orderStatusId == 3
      )

      if (this.kdsHelperStartTime) {
        unsortedOrders = unsortedOrders.filter((x) => {
          const deliverTime = moment(x.deliverTime)
          const startTime = moment(this.kdsHelperStartTime, TIME)
          return deliverTime >= startTime
        })
      }

      if (this.kdsHelperEndTime) {
        unsortedOrders = unsortedOrders.filter((x) => {
          const deliverTime = moment(x.deliverTime)
          const endTime = moment(this.kdsHelperEndTime, TIME)
          return deliverTime <= endTime
        })
      }

      return unsortedOrders
    },

    productQuantities() {
      let orders = this.orders
      if (this.kdsHelperStartTime) {
        orders = orders.filter((x) => {
          const deliverTime = moment(x.deliverTime)
          const startTime = moment(this.kdsHelperStartTime, TIME)
          return deliverTime >= startTime
        })
      }

      if (this.kdsHelperEndTime) {
        orders = orders.filter((x) => {
          const deliverTime = moment(x.deliverTime)
          const endTime = moment(this.kdsHelperEndTime, TIME)
          return deliverTime <= endTime
        })
      }
      let productQuantity = {}

      orders.forEach((x) => {
        x.orderedPVs.forEach((y) => {
          if (y.name in productQuantity) {
            productQuantity[y.name] += y.quantity
          } else {
            productQuantity[y.name] = y.quantity
          }
        })
      })

      const result = Object.entries(
        productQuantity
      ).map(([product, quantity]) => ({ product, quantity }))

      if (!this.kdsHelperSearch) {
        return result
      }

      return result
        .filter((x) =>
          x.product.toLowerCase().includes(this.kdsHelperSearch.toLowerCase())
        )
        .sort((x) => x.product)
    },
  },

  mounted() {
    this.categoryNames = this.categories.map((x) => x.names)
    this.fetchCurrentOrders()
    document.title = 'Akito - KDS'
  },

  methods: {
    ...mapActions({
      fetchCurrentOrders: `${ORDER}/${FETCH_POS_ORDERS_FOR_TODAY}`,
      markVariantComplete: `${ORDER}/${MARK_VARIANT_COMPLETE}`,
      setOrder: `${ORDER}/${SET_ACTIVE_ORDER}`,
      editOrder: `${ORDER}/${EDIT_ACTIVE_ORDER}`,
    }),

    isTime(order) {
      let styling = 'py-2 white--text '

      if (this.isUrgent(order)) return styling + 'status-background-1'
      if (this.is15before(order)) return styling + 'status-background-2'

      return 'py-2'
    },
    //d = 16:15
    //t = 16:05 + 10
    /**10 minutes before due date */
    isUrgent(order) {
      return this.currentTime >= moment(order.deliverTime)
    },
    /** 15 minutes before due date */
    is15before(order) {
      return (
        this.currentTime >= moment(order.deliverTime).subtract(16, 'minutes')
      )
    },

    getOrderTime(order) {
      return moment(order.deliverTime).format(TIME)
    },

    getOrderStatus(id) {
      return this.orderStatuses.find((x) => x.id == id).name
    },

    changeStatus(order) {
      this.setOrder(order)
      order.orderStatusId = 3
      this.editOrder(true)
    },

    styleCategory(product) {
      switch (product.category.toLowerCase()) {
        case 'warme maaltijden':
          return 'yellow'
        case 'snack':
          return 'yellow'
        case 'salade':
          return 'green'
      }

      const yellow_product_patterns = [
        'crispy',
        'special mix',
        'ichiban',
        'zalm menu',
      ]
      if (
        yellow_product_patterns.some((x) =>
          product.name.toLowerCase().includes(x)
        )
      )
        return 'yellow'
    },

    setStatusBack(order) {
      Swal.fire({
        icon: 'warning',
        title: 'Bestelling ' + order.trackingNumber + ' weer bereiden?',
        text:
          'Bestelling is klaar wanneer alles is bereid en in staat is voor afhalen of bezorgen. ',
        showCancelButton: true,
        confirmButtonText: 'Ja',
        cancelButtonText: `Nee`,
      }).then((result) => {
        if (result.isConfirmed) {
          this.setOrder(order)
          order.orderStatusId = 2
          this.editOrder(true)
        }
      })
    },

    markComplete(product) {
      let variant = product
      this.markVariantComplete(variant)
    },

    sortProducts(products) {
      const compare = (a, b) => {
        if (a.category < b.category) return -1
        if (a.category > b.category) return 1
        return 0
      }
      let productsCopy = cloneDeep(products)
      if (this.kdsHelperSearch) {
        productsCopy = productsCopy.filter((x) =>
          x.name.toLowerCase().includes(this.kdsHelperSearch.toLowerCase())
        )
      }
      const sortedProducts = productsCopy.sort(compare)

      return sortedProducts
    },

    sortOrderAddition(additions) {
      let additionsCopy = cloneDeep(additions)
      if (this.kdsHelperSearch) {
        additionsCopy = additionsCopy.filter((x) =>
          x.name.toLowerCase().includes(this.kdsHelperSearch.toLowerCase())
        )
      }
      return additionsCopy
    },
  },
}
</script>

<style scoped>
.receipt {
  width: 280px;
  height: calc(100vh - 180px);
  padding: 10px;
  margin: 10px;
  overflow-y: auto;
}

@media only screen and (min-width: 400px) {
  .receipt {
    width: 372px;
    height: calc(100vh - 200px);
    margin: 10px 12px 30px 12px;
  }
}

.status-background-1 {
  background: #ff0000;
}

.status-background-2 {
  background: #fbac0e;
}

.status-background-3 {
  background: #568cf3;
}

.status-background-4 {
  background: #35b852;
}

.kds-helper-btn {
  position: fixed;
  bottom: 0;
  left: 0;
  z-index: 1050;
}

.kds-table {
  min-height: calc(100vh - 300px) !important;
}
</style>
