import Vue from 'vue'
import Vuex from 'vuex'
import SERVICE_ROOT from './SERVICE_ROOT'

Vue.use(Vuex)

const store = new Vuex.Store({
  modules: {
    SERVICE_ROOT,
  },
})

Vue.prototype.$store = store
export default store
