<template>
  <v-card>
    <div class="main" v-if="!showEdit">
      <v-container fluid class="pb-0">
        <v-row no-gutters>
          <v-col>
            <ordered-items
              v-if="order.orderedPVs"
              :cartItems="order.orderedPVs"
              :isProduct="true"
            />
            <ordered-items
              v-if="order.orderAdditions"
              :cartItems="order.orderAdditions"
              :isProduct="false"
            />
            <v-list-item>
              <v-list-item-title class="title">
                Totaal ({{ getTotalQuantity }})
              </v-list-item-title>
              <v-list-item-action-text class="title black--text">
                &euro;{{ getTotalPrice.toFixed(2) }}
              </v-list-item-action-text>
            </v-list-item>
          </v-col>
          <v-col cols="12" md="6" class="px-3">
            <v-list-item>
              <v-list-item-content>
                <v-list-item-subtitle> Bestelling</v-list-item-subtitle>
                <v-list-item-title>
                  #{{ order.trackingNumber }}
                </v-list-item-title>
              </v-list-item-content>
              <v-list-item-content>
                <v-list-item-subtitle> Naam</v-list-item-subtitle>
                <v-list-item-title>{{ order.name }}</v-list-item-title>
              </v-list-item-content>
            </v-list-item>
            <v-list-item>
              <v-list-item-content>
                <v-list-item-subtitle> Levertijd</v-list-item-subtitle>
                <v-list-item-title>{{
                  $moment(order.deliverTime).format('HH:mm')
                }}</v-list-item-title>
              </v-list-item-content>
              <v-list-item-content>
                <v-list-item-subtitle>Levering</v-list-item-subtitle>
                <v-list-item-title>
                  <span>
                    {{ order.isPickUp ? 'Afhaal' : 'Bezorg' }}
                  </span>
                </v-list-item-title>
              </v-list-item-content>
            </v-list-item>
            <v-list-item v-if="!order.isPickUp">
              <v-list-item-content>
                <v-list-item-subtitle>PostCode</v-list-item-subtitle>
                <v-list-item-title>
                  {{ order.location.zipCode }}
                </v-list-item-title>
              </v-list-item-content>
              <v-list-item-content>
                <v-list-item-subtitle>Huisnummer</v-list-item-subtitle>
                <v-list-item-title>
                  {{ order.location.houseNumber }}
                </v-list-item-title>
              </v-list-item-content>
              <v-list-item-content v-if="order.location.houseAddition">
                <v-list-item-subtitle>Toevoeging</v-list-item-subtitle>
                <v-list-item-title>
                  {{ order.location.houseAddition }}
                </v-list-item-title>
              </v-list-item-content>
              <v-list-item-content
                v-if="
                  order.location.street &&
                    order.location.street != 'Lindestraat'
                "
              >
                <v-list-item-subtitle>Straat</v-list-item-subtitle>
                <v-list-item-title>
                  {{ order.location.street }}
                </v-list-item-title>
              </v-list-item-content>
            </v-list-item>
            <v-list-item>
              <v-list-item-content class="text-wrap">
                <v-list-item-subtitle>Referentie</v-list-item-subtitle>
                <v-list-item-title>{{ order.reference }}</v-list-item-title>
              </v-list-item-content>
              <v-list-item-action-text>
                <v-list-item-subtitle> Id</v-list-item-subtitle>
                <v-list-item-title class="black--text">{{
                  order.id
                }}</v-list-item-title>
              </v-list-item-action-text>
            </v-list-item>
            <v-list-item>
              <v-list-item-content v-if="order.orderStatus">
                <v-list-item-subtitle>Status</v-list-item-subtitle>
                <v-list-item-title>
                  {{ order.orderStatus.name }}</v-list-item-title
                >
              </v-list-item-content>
              <v-list-item-content v-if="order.paymentMethod">
                <v-list-item-subtitle>Betaling</v-list-item-subtitle>
                <v-list-item-title>
                  {{ order.paymentMethod.name }}</v-list-item-title
                >
              </v-list-item-content>
            </v-list-item>
            <v-list-item>
              <v-list-item-content class="py-1">
                <v-list-item-subtitle>Opmerkingen</v-list-item-subtitle>
                <v-list-item-title>{{
                  order.description ? order.description : '...'
                }}</v-list-item-title>
              </v-list-item-content>
            </v-list-item>
            <v-checkbox
              @click="editOrder(true)"
              v-model="order.isPaid"
              hide-details
              label="Betaald"
              class="mt-2 px-3"
            ></v-checkbox>
            <v-card-text class="d-flex">
              <v-btn-toggle v-model="order.paymentMethodId" color="secondary">
                <v-btn
                  @click="editOrder(true)"
                  v-for="(payment, i) in paymentMethods"
                  :key="i"
                  :value="payment.id"
                >
                  {{ payment.name }}
                </v-btn>
              </v-btn-toggle>
            </v-card-text>
          </v-col>
        </v-row>
      </v-container>
    </div>
    <!-- EDIT ORDER DIALOG -->
    <edit-order
      v-if="showEdit"
      @close-dialog="closeEditDialog"
      :order="order"
    />
    <v-divider></v-divider>
    <v-card-actions class="py-1" v-if="!showEdit">
      <v-btn icon class="mx-2" @click="showEdit = true">
        <v-icon>mdi-pencil</v-icon>
      </v-btn>
      <v-btn icon class="mr-2" @click="togglePrintDialog">
        <v-icon>mdi-printer</v-icon>
      </v-btn>
      <v-btn icon class="mr-2" to="/kitchen-orders">
        <v-icon>mdi-clipboard-list-outline</v-icon>
      </v-btn>
      <v-spacer></v-spacer>
      <v-chip-group column color="secondary" v-model="order.orderStatusId">
        <v-chip
          @click="changeStatus(status.id)"
          filter
          v-for="(status, i) in orderStatuses"
          :key="i"
          :value="status.id"
        >
          {{ status.name }}
        </v-chip>
      </v-chip-group>
    </v-card-actions>
    <v-dialog v-model="printDialog" width="300">
      <v-card>
        <v-card-title class="justify-content-center">
          Printen
          <v-spacer> </v-spacer>
          <v-icon @click="togglePrintDialog">mdi-close</v-icon>
        </v-card-title>
        <v-divider></v-divider>
        <print-dialog />
        <v-divider></v-divider>
        <v-card-actions>
          <v-btn @click="printReceipt" color="primary" text block>
            <v-icon>mdi-printer</v-icon>
            Print
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </v-card>
</template>
<script>
import OrderedItems from './ordered-items.vue'
import PrintDialog from '../menu-cashier/print-dialog.vue'
import EditOrder from './edit-order.vue'
import { mapGetters, mapActions } from 'vuex'
import {
  CART,
  SERVICE_ROOT,
  ORDER,
  GET_TOTAL_PRICE,
  GET_TOTAL_QUANTITY,
  EDIT_ACTIVE_ORDER,
  PRINT,
  GET_ORDER_STATUSES,
  GET_PAYMENT_METHODS,
} from '@/store/constants.js'
const Swal = require('sweetalert2')

export default {
  props: {
    order: {
      type: Object,
      required: true,
    },
  },

  components: {
    OrderedItems,
    EditOrder,
    PrintDialog,
  },

  data() {
    return {
      showEdit: false,
      printDialog: false,
    }
  },

  computed: {
    ...mapGetters({
      getTotalPrice: `${CART}/${GET_TOTAL_PRICE}`,
      getTotalQuantity: `${CART}/${GET_TOTAL_QUANTITY}`,
      orderStatuses: `${SERVICE_ROOT}/${GET_ORDER_STATUSES}`,
      paymentMethods: `${SERVICE_ROOT}/${GET_PAYMENT_METHODS}`,
    }),
  },

  methods: {
    ...mapActions({
      editOrder: `${ORDER}/${EDIT_ACTIVE_ORDER}`,
      print: `${ORDER}/${PRINT}`,
    }),

    printReceipt() {
      this.print(this.order.id)
      this.togglePrintDialog()
    },

    changeStatus(statusId) {
      if (statusId < 4) return this.editOrder(true)
      Swal.fire({
        title: 'Is de bestelling betaald?',
        text: 'Controleer of de betaal methode ook correct is.',
        showDenyButton: true,
        confirmButtonText: `Ja`,
        denyButtonText: `Nee`,
        allowOutsideClick: false,
      }).then((result) => {
        if (result.isConfirmed) {
          this.order.isPaid = true
          this.editOrder(true)
          Swal.fire('Betaling afgerond!', '', 'success')
        } else if (result.isDenied) {
          this.order.orderStatusId = statusId - 1
        }
      })
    },

    togglePrintDialog() {
      this.printDialog = !this.printDialog
    },

    closeEditDialog() {
      this.showEdit = !this.showEdit
    },
  },
}
</script>
