<template>
  <v-card v-if="modifiedOrder">
    <div class="main">
      <v-card-title v-if="$vuetify.breakpoint.mdAndDown">
        #{{ modifiedOrder.trackingNumber }}
      </v-card-title>
      <v-container fluid>
        <v-row dense>
          <v-col class="mr-2">
            <ordered-items
              :isProduct="true"
              :cartItems="getOrderedProducts"
              :isEdit="true"
              @increase-item="addItem"
              @decrease-item="decreaseItem"
            />
            <ordered-items
              :isProduct="false"
              :cartItems="getOrderedAdditions"
              :isEdit="true"
              @increase-item="addItem"
              @decrease-item="decreaseItem"
            />
            <v-card-title class="py-1">
              <span> Totaal ({{ getTotalQuantity }}) </span>
              <v-spacer></v-spacer>
              <span> &euro;{{ getTotalPrice.toFixed(2) }} </span>
            </v-card-title>
          </v-col>

          <v-col>
            <v-list-item>
              <v-list-item-content>
                <v-list-item-subtitle>Bestelling</v-list-item-subtitle>
                <v-list-item-title
                  >#{{ modifiedOrder.trackingNumber }}</v-list-item-title
                >
              </v-list-item-content>
              <v-list-item-content>
                <v-list-item-subtitle>Id</v-list-item-subtitle>
                <v-list-item-title>{{ modifiedOrder.id }}</v-list-item-title>
              </v-list-item-content>
            </v-list-item>
            <v-list-item>
              <v-list-item-content>
                <v-list-item-subtitle>Referentie</v-list-item-subtitle>
                <v-list-item-title>{{
                  modifiedOrder.reference
                }}</v-list-item-title>
              </v-list-item-content>
            </v-list-item>
            <div class="d-flex px-4">
              <v-checkbox
                hide-details
                v-model="modifiedOrder.isPickUp"
                label="Afhalen"
              ></v-checkbox>
              <div class="d-flex mt-2 ml-4" v-if="!modifiedOrder.isPickUp">
                <v-text-field
                  v-model="modifiedOrder.location.zipCode"
                  label="Postcode"
                  outlined
                  hide-details
                  dense
                ></v-text-field>
                <v-text-field
                  v-model="modifiedOrder.location.houseNumber"
                  label="Huisnummer"
                  outlined
                  hide-details
                  dense
                ></v-text-field>
                <v-text-field
                  v-model="modifiedOrder.location.houseAddition"
                  outlined
                  hide-details
                  label="Toevoeging"
                  dense
                ></v-text-field>
                <v-btn
                  icon
                  color="primary"
                  target="_blank"
                  :href="
                    `https://www.google.com/maps/search/${order.location.zipCode}+${order.location.houseNumber}+${order.location.houseAddition}`
                  "
                >
                  <v-icon>mdi-map-search</v-icon>
                </v-btn>
              </div>
            </div>
            <div class="d-flex pa-4" v-if="!modifiedOrder.isPickUp">
              <v-text-field
                v-model="modifiedOrder.location.street"
                outlined
                hide-details
                label="Straat"
                dense
              ></v-text-field>
              <v-text-field
                v-model="modifiedOrder.location.city"
                outlined
                hide-details
                label="Stad"
                dense
              ></v-text-field>
            </div>
            <v-divider v-if="!modifiedOrder.isPickUp"></v-divider>
            <v-card-text>
              <v-text-field
                label="Naam"
                outlined
                dense
                v-model="modifiedOrder.name"
              ></v-text-field>
              <div class="d-flex">
                <date-picker-menu
                  :initialDate="deliverDate"
                  placeholder="Bestel Datum"
                  @date-changed="updateDate"
                />
                <v-text-field
                  v-model="deliverTime"
                  label="Levertijd"
                  class="mx-1"
                  dense
                  outlined
                  @blur="formatTime()"
                  prepend-inner-icon="mdi-clock"
                  :rules="timerules"
                  clearable
                ></v-text-field>
              </div>

              <v-textarea
                label="Opmerking"
                rows="2"
                auto-grow
                v-model="modifiedOrder.description"
                outlined
                dense
                hide-details
              ></v-textarea>
              <v-checkbox
                label="Betaald"
                hide-details
                v-model="modifiedOrder.isPaid"
              ></v-checkbox>
            </v-card-text>
            <v-list-item>
              <v-list-item-content class="py-2">
                <v-list-item-subtitle>Betalings Methode</v-list-item-subtitle>
                <v-chip-group
                  column
                  color="secondary"
                  v-model="modifiedOrder.paymentMethodId"
                >
                  <v-chip
                    filter
                    v-for="(payment, i) in paymentMethods"
                    :key="i"
                    :value="payment.id"
                  >
                    {{ payment.name }}
                  </v-chip>
                </v-chip-group>
              </v-list-item-content>
            </v-list-item>

            <v-list-item>
              <v-list-item-content class="py-1">
                <v-list-item-subtitle>Bestelling status</v-list-item-subtitle>
                <v-chip-group
                  column
                  color="secondary"
                  v-model="order.orderStatusId"
                >
                  <v-chip
                    filter
                    v-for="(status, i) in orderStatuses"
                    :key="i"
                    :value="status.id"
                  >
                    {{ status.name }}
                  </v-chip>
                </v-chip-group>
              </v-list-item-content>
            </v-list-item>
            <v-btn color="red" dark @click="deleteCurrentOrder" text>
              <v-icon>mdi-trash-can</v-icon>
              Verwijder
            </v-btn>
          </v-col>
        </v-row>
      </v-container>
    </div>
    <v-divider></v-divider>
    <v-card-actions color="transparent">
      <v-btn large text @click="$emit('close-dialog')">
        <v-icon class="mr-2">mdi-arrow-left</v-icon>
        Terug
      </v-btn>
      <v-spacer></v-spacer>
      <v-btn color="secondary" text large class="mr-2" @click="saveOrder">
        <v-icon class="mr-2">mdi-check-circle</v-icon>
        Opslaan
      </v-btn>
    </v-card-actions>
  </v-card>
</template>

<script>
import moment from 'moment'
import OrderedItems from './ordered-items.vue'
import { mapActions, mapGetters } from 'vuex'
import {
  ADD_OR_INCREASE_CART_ITEM,
  CART,
  DELETE_ACTIVE_ORDER_FROM_POS,
  EDIT_ACTIVE_ORDER,
  GET_AVAILABLE_TIME_SLOTS,
  GET_ORDER_ADDITIONS,
  GET_ORDER_PRODUCTS,
  GET_ORDER_STATUSES,
  GET_PAYMENT_METHODS,
  GET_TOTAL_PRICE,
  GET_TOTAL_QUANTITY,
  ORDER,
  REMOVE_OR_DECREASE_CART_ITEM,
  SERVICE_ROOT,
  SET_ACTIVE_ORDER,
  TIME,
  YEAR_FORMAT,
} from '@/store/constants.js'
import DatePickerMenu from '../revenue/DatePickerMenu.vue'
const Swal = require('sweetalert2')

export default {
  components: {
    OrderedItems,
    DatePickerMenu,
  },

  props: {
    order: {
      type: Object,
      required: true,
    },
  },

  data() {
    return {
      modifiedOrder: null,
      onlyDetails: true,
      deliverTime: '16:10',
      deliverDate: moment().format(YEAR_FORMAT),
      printDialog: false,
      timerules: [
        (v) => !!v || 'Vul in',
        (v) =>
          /^([0-1]?[0-9]|2[0-3])((:)?[0-5][0-9])?$/.test(v) ||
          'Tijd klopt niet',
      ],
    }
  },

  mounted() {
    this.initialize()
  },

  watch: {
    order() {
      this.initialize()
    },
  },

  computed: {
    ...mapGetters({
      getTotalPrice: `${CART}/${GET_TOTAL_PRICE}`,
      getOrderedProducts: `${CART}/${GET_ORDER_PRODUCTS}`,
      getOrderedAdditions: `${CART}/${GET_ORDER_ADDITIONS}`,
      getTotalQuantity: `${CART}/${GET_TOTAL_QUANTITY}`,
      orderStatuses: `${SERVICE_ROOT}/${GET_ORDER_STATUSES}`,
      paymentMethods: `${SERVICE_ROOT}/${GET_PAYMENT_METHODS}`,
      getAvailableTimeSlots: `${ORDER}/${GET_AVAILABLE_TIME_SLOTS}`,
    }),
  },

  methods: {
    ...mapActions({
      addCart: `${CART}/${ADD_OR_INCREASE_CART_ITEM}`,
      decreaseCartItemQuantity: `${CART}/${REMOVE_OR_DECREASE_CART_ITEM}`,
      editOrder: `${ORDER}/${EDIT_ACTIVE_ORDER}`,
      setOrder: `${ORDER}/${SET_ACTIVE_ORDER}`,
      deleteOrder: `${ORDER}/${DELETE_ACTIVE_ORDER_FROM_POS}`,
    }),

    updateDate(value) {
      this.deliverDate = value
    },

    initialize() {
      this.modifiedOrder = Object.assign({}, this.order)
      this.deliverTime = moment(this.modifiedOrder.deliverTime).format(TIME)
      this.deliverDate = moment(this.modifiedOrder.deliverTime).format(
        YEAR_FORMAT
      )
    },

    addItem(cartItem) {
      this.addCart(cartItem)
      this.onlyDetails = false
    },

    decreaseItem(cartItem) {
      this.decreaseCartItemQuantity(cartItem)
      this.onlyDetails = false
    },

    deleteCurrentOrder() {
      Swal.fire({
        title: 'Wil je de bestelling verwijderen?',
        showDenyButton: true,
        confirmButtonText: `Ja`,
        denyButtonText: `Nee`,
      }).then((result) => {
        /* Read more about isConfirmed, isDenied below */
        if (result.isConfirmed) {
          this.deleteOrder(true)
          Swal.fire('Betaling verwijdered!', '', 'success')
          this.$emit('close-dialog')
        }
      })
    },

    saveOrder() {
      this.modifiedOrder.deliverTime = moment(
        this.deliverDate + ' ' + this.deliverTime,
        YEAR_FORMAT + ' ' + TIME
      )
        .toISOString(true)
        .slice(0, 23)
      this.setOrder(this.modifiedOrder)
      this.editOrder(this.onlyDetails)
      this.$emit('close-dialog')
    },

    formatTime() {
      const regex = new RegExp('^([0-1]?[0-9]|2[0-3])((:)?[0-5][0-9])?$')
      if (!regex.test(this.deliverTime) || this.deliverTime.length === 3)
        return (this.deliverTime = this.getAvailableTimeSlots[0])
      if (this.deliverTime.length === 4)
        this.deliverTime = [
          this.deliverTime.slice(0, 2),
          ':',
          this.deliverTime.slice(2),
        ].join('')

      if (this.deliverTime.length < 3)
        this.deliverTime = this.deliverTime + ':00'
    },
  },
}
</script>
