<template>
  <v-card min-width="300px">
    <v-list outlined class="main pt-0" two-line>
      <v-card-text v-if="activeOrders.length == 0" class="text-center py-8">
        <v-icon size="100" color="grey"> mdi-clock-time-ten-outline </v-icon>
        <v-card-title class="justify-center gray--text">
          Geen bestellingen
        </v-card-title>
        <v-card-subtitle>
          Momenteel zijn er nog geen bestellingen binnen.
        </v-card-subtitle>
        <v-btn color="error" class="mt-8" large to="/cashier">
          <v-icon class="mr-2"> mdi-plus </v-icon>
          Nieuw bestelling
        </v-btn>
      </v-card-text>
      <v-list-item-group v-else>
        <div v-for="(group, i) in activeOrders" :key="i">
          <div
            v-if="currentTime > group.endTime"
            class="subtitle-1 font-weight-bold grey white--text pa-1"
          >
            {{ group.time }} - {{ group.endTime }}
          </div>
          <div
            v-else
            class="subtitle-1 font-weight-bold primary white--text pa-1"
          >
            {{ group.time }} - {{ group.endTime }}
          </div>
          <v-divider />
          <order-list
            v-if="group.orders.length > 0"
            :group-orders="group.orders"
            :search="search"
            :all="showAll"
            @select-order="selectOrder"
          />
        </div>
      </v-list-item-group>
    </v-list>
    <v-card-actions>
      <v-btn icon color="darkblue" @click="search = `Afhalen`">
        <v-icon> mdi-store </v-icon>
      </v-btn>
      <v-btn icon class="mx-1" color="darkorange" @click="search = `Bezorgen`">
        <v-icon> mdi-moped </v-icon>
      </v-btn>
      <v-text-field
        v-model="search"
        rounded
        class="mx-1"
        outlined
        hide-details
        dense
        label="Zoek Bestelling"
        color="secondary"
        prepend-inner-icon="mdi-magnify"
        clearable
      />
      <v-switch
        class="ml-1 mt-n1"
        inset
        v-model="showAll"
        hide-details
      ></v-switch>
    </v-card-actions>
  </v-card>
</template>

<script>
import OrderList from '../orders/order-list.vue'
import * as moment from 'moment'
import {
  TIME,
  ORDER,
  GET_POS_ORDER_SORTED_BY_TIME,
  GET_POS_ORDERS,
} from '../../store/constants.js'
import { mapGetters } from 'vuex'

export default {
  props: {
    orders: {
      type: Array,
      required: true,
    },
  },

  components: {
    OrderList,
  },

  data() {
    return {
      search: '',
      showAll: false,
      currentTime: moment().format(TIME),
    }
  },

  computed: {
    ...mapGetters({
      getSortedOrderByTime: `${ORDER}/${GET_POS_ORDER_SORTED_BY_TIME}`,
      getOrders: `${ORDER}/${GET_POS_ORDERS}`,
    }),

    activeOrders() {
      return this.getSortedOrderByTime(this.showAll).filter(
        (x) => x.orders.length > 0
      )
    },
  },

  mounted() {
    setInterval(() => {
      this.setTime()
    }, 60000)
  },

  methods: {
    selectOrder(order) {
      this.$emit('select-order', order)
    },
    setTime() {
      this.currentTime = moment()
        .format(TIME)
        .toString()
    },
  },
}
</script>
