import { RepositoryFactory } from '../api/RepositoryFactory'
import PRODUCT from './modules/PRODUCT'
import CART from './modules/CART'
import ORDER from './modules/ORDER'
import CATEGORY from './modules/CATEGORY'
import HELPER from './modules/HELPER'

import * as moment from 'moment'
import {
  IS_INITIALIZED,
  GET_CATEGORIES,
  GET_REVENUE,
  GET_KITCHEN_TYPES,
  GET_LOCATION,
  GET_ORDER_STATUSES,
  GET_PAYMENT_METHODS,
  GET_PRODUCTS,
  GET_ADDITIONS,
  GET_VARIANTS,
  GET_VARIANT_CODE,
  INITIALIZE,
  SET_PRODUCTS,
  SET_CATEGORIES,
  SET_ORDER_ADDITIONS,
  FETCH_REVENUE,
  GET_TIMESLOTS,
  FETCH_TIMESLOTS,
} from './constants'

//#region MUTATIONS (Private)
const SET_REVENUE = 'SET_REVENUE'
const SET_VARIANTS = 'SET_VARIANTS'
//#endregion

const state = () => ({
  revenue: undefined,
  SERVICE_ROOT: undefined,
  repository: new RepositoryFactory(process.env.VUE_APP_API_URL, ''),
  timeSlots: undefined,
})

const getters = {
  [IS_INITIALIZED]: (state) => {
    return state.SERVICE_ROOT !== undefined
  },

  [GET_CATEGORIES]: (state) => {
    return state.SERVICE_ROOT?.categories
  },

  [GET_REVENUE]: (state) => {
    return state.revenue
  },

  [GET_KITCHEN_TYPES]: (state) => {
    return state.SERVICE_ROOT?.kitchenTypes
  },

  [GET_LOCATION]: (state) => {
    return state.SERVICE_ROOT?.locations
  },

  [GET_ORDER_STATUSES]: (state) => {
    return state.SERVICE_ROOT?.orderStatuses
  },

  [GET_PAYMENT_METHODS]: (state) => {
    return state.SERVICE_ROOT?.paymentMethods
  },

  [GET_PRODUCTS]: (state) => {
    return state.SERVICE_ROOT?.products
  },

  [GET_ADDITIONS]: (state) => {
    return state.SERVICE_ROOT?.orderAdditions
  },

  [GET_VARIANTS]: (state) => {
    return state.SERVICE_ROOT?.variants
  },

  [GET_VARIANT_CODE]: (state) => {
    const variantCodes = []

    state.SERVICE_ROOT?.products.forEach((p) => {
      p.variants.forEach((v) => {
        variantCodes.push(v.variantCode)
      })
    })

    return variantCodes
  },

  [GET_TIMESLOTS]: (state) => {
    return state.timeSlots
  },
}

const actions = {
  async [INITIALIZE]({ commit, state, dispatch }, accessToken) {
    state.repository = new RepositoryFactory(
      process.env.VUE_APP_API_URL,
      accessToken
    )
    const { data } = await state.repository.serviceRoot.get()
    dispatch(`${SET_ORDER_ADDITIONS}`)
    commit(`${INITIALIZE}`, data)
    commit(`${SET_VARIANTS}`)
  },

  async [SET_PRODUCTS]({ commit, state }) {
    const { data } = await state.repository.product.get()
    commit(`${SET_PRODUCTS}`, data)
  },

  async [SET_CATEGORIES]({ commit, state }) {
    const { data } = await state.repository.category.getCategories()
    commit(`${SET_CATEGORIES}`, data)
  },

  async [SET_ORDER_ADDITIONS]({ commit, state }) {
    const { data } = await state.repository.orderAddition.getOrderAdditions()
    commit(`${SET_ORDER_ADDITIONS}`, data)
  },

  async [FETCH_REVENUE]({ commit, state }, date) {
    const day = {
      start: moment(date.start).startOf('day').toISOString(),
      end: moment(date.end).endOf('day').toISOString(),
    }
    const { data } = await state.repository.order.getRevenue(day.start, day.end)
    commit(`${SET_REVENUE}`, data)
  },

  async [FETCH_TIMESLOTS]({ state }, interval) {
    const { data } = await state.repository.order.getTimeSlots(interval)
    state.timeSlots = data
  },
}

const mutations = {
  [INITIALIZE](state, sr) {
    state.SERVICE_ROOT = sr
  },

  [SET_PRODUCTS](state, products) {
    state.SERVICE_ROOT.products = products
  },

  [SET_CATEGORIES](state, categories) {
    state.SERVICE_ROOT.categories = categories
  },

  [SET_ORDER_ADDITIONS](state, orderAdditions) {
    state.SERVICE_ROOT.orderAdditions = orderAdditions
  },

  [SET_REVENUE](state, r) {
    state.revenue = r
  },

  [SET_VARIANTS](state) {
    const getProductWithId = (productId) => {
      return state.SERVICE_ROOT.products.find((x) => x.id == productId)
    }
    const variants = state.SERVICE_ROOT.products.flatMap((x) => x.variants)
    const variantsWithProduct = variants.map((variant) => ({
      ...variant,
      category: getProductWithId(variant.productId).category,
      taxRate: getProductWithId(variant.productId).taxRate,
      productName: getProductWithId(variant.productId).name,
      description: getProductWithId(variant.productId).description,
    }))
    state.SERVICE_ROOT.variants = variantsWithProduct
  },
}

const modules = {
  PRODUCT,
  CART,
  ORDER,
  CATEGORY,
  HELPER,
}

export default {
  namespaced: true,
  state,
  getters,
  actions,
  modules,
  mutations,
}
