<template>
  <div>
    <v-data-table
      v-if="isProduct"
      class="products"
      :headers="productHeaders"
      :items="cartItems"
      :items-per-page="100"
      item-key="name"
      hide-default-footer
      sort-by="category"
    >
      <template v-slot:item.isCompleted="{ item }">
        <v-checkbox
          v-model="item.isCompleted"
          hide-details
          class="mt-n1"
          @change="markComplete(item)"
        >
        </v-checkbox>
      </template>
      <template v-slot:item.quantity="{ item }" v-if="!isEdit">
        <strong>{{ `${item.quantity} x` }}</strong>
      </template>
      <template v-slot:item.price="{ item }">
        <span>&euro;{{ (item.price * item.quantity).toFixed(2) }}</span>
      </template>
      <template v-slot:item.name="{ item }">
        <strong>{{ `${item.name}` }}</strong>
      </template>
      <template v-slot:item.quantity="{ item }" v-if="isEdit">
        <div style="display: flex" justify="center">
          <v-btn small color="grey" icon @click="decreaseItem(item)">
            <v-icon>mdi-minus-circle</v-icon>
          </v-btn>
          <div class="mx-1 subtitle-1">
            {{ item.quantity }}
          </div>
          <v-btn icon small color="grey" @click="increaseItem(item)">
            <v-icon>mdi-plus-circle</v-icon>
          </v-btn>
        </div>
      </template>
    </v-data-table>

    <v-data-table
      v-if="!isProduct"
      class="additions mt-1"
      :headers="additonHeaders"
      :items="cartItems"
      :items-per-page="100"
      item-key="name"
      hide-default-footer
      sort-by="name"
    >
      <template v-slot:item.price="{ item }">
        <span>&euro;{{ (item.price * item.quantity).toFixed(2) }}</span>
      </template>

      <template v-slot:item.isCompleted="{ item }">
        <v-checkbox v-model="item.isCompleted" hide-details class="mt-n1">
        </v-checkbox>
      </template>

      <template v-slot:item.quantity="{ item }" v-if="isEdit">
        <div style="display: flex" justify="center">
          <v-btn small color="grey" icon @click="decreaseItem(item)">
            <v-icon>mdi-minus-circle</v-icon>
          </v-btn>
          <div class="mx-1 subtitle-1">
            {{ item.quantity }}
          </div>
          <v-btn icon small color="grey" @click="increaseItem(item)">
            <v-icon>mdi-plus-circle</v-icon>
          </v-btn>
        </div>
      </template>
    </v-data-table>
    <v-card-actions>
      <v-btn
        right
        @click="toggleDialogAddition"
        v-if="!isProduct && isEdit"
        text
        color="primary"
        ><v-icon>mdi-plus</v-icon> Nieuwe toevoeging</v-btn
      >
    </v-card-actions>
    <v-dialog v-model="dialogAdditon" width="400" v-if="!isProduct">
      <addition-dialog
        @close-dialog="toggleDialogAddition"
        @add-addition="addAddition"
      />
    </v-dialog>
  </div>
</template>

<script>
import AdditionDialog from '@/components/menu-cashier/addition-dialog'
import { mapActions } from 'vuex'
import { MARK_VARIANT_COMPLETE, ORDER } from '@/store/constants.js'
export default {
  props: {
    cartItems: {
      type: Array,
      required: true,
    },
    isProduct: {
      type: Boolean,
      required: true,
    },
    isEdit: {
      type: Boolean,
      default: function() {
        return false
      },
    },
  },

  components: {
    AdditionDialog,
  },

  data() {
    return {
      dialogAdditon: false,
      productHeaders: [
        { text: 'Categorie', value: 'category' },
        { text: 'Klaar', value: 'isCompleted' },
        { text: 'Aantal', value: 'quantity' },
        {
          text: 'Product',
          value: 'name',
        },
        { text: 'Prijs', value: 'price' },
      ],
      additonHeaders: [
        { text: 'Aantal', value: 'quantity' },
        {
          text: 'Toevoeging',
          value: 'name',
        },
        { text: 'Prijs', value: 'price' },
      ],
    }
  },

  methods: {
    ...mapActions({
      markVariantComplete: `${ORDER}/${MARK_VARIANT_COMPLETE}`,
    }),
    addAddition(item) {
      this.$emit('increase-item', item)
    },

    increaseItem(item) {
      this.$emit('increase-item', {
        item: item,
        isProduct: this.isProduct,
      })
    },

    decreaseItem(item) {
      this.$emit('decrease-item', {
        item: item,
        isProduct: this.isProduct,
      })
    },

    toggleDialogAddition() {
      this.dialogAdditon = !this.dialogAdditon
    },

    markComplete(product) {
      let variant = product
      this.markVariantComplete(variant)
    },
  },
}
</script>

<style>
.products {
  height: calc(50vh - 100px);
  overflow-y: auto;
  border-radius: 5px;
  border: #e7e7e7 1px solid;
}
.additions {
  height: 30vh;
  overflow-y: auto;
  border: #e7e7e7 1px solid;
  border-radius: 5px;
}
</style>
