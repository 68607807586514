var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[(_vm.isProduct)?_c('v-data-table',{staticClass:"products",attrs:{"headers":_vm.productHeaders,"items":_vm.cartItems,"items-per-page":100,"item-key":"name","hide-default-footer":"","sort-by":"category"},scopedSlots:_vm._u([{key:"item.isCompleted",fn:function(ref){
var item = ref.item;
return [_c('v-checkbox',{staticClass:"mt-n1",attrs:{"hide-details":""},on:{"change":function($event){return _vm.markComplete(item)}},model:{value:(item.isCompleted),callback:function ($$v) {_vm.$set(item, "isCompleted", $$v)},expression:"item.isCompleted"}})]}},(_vm.isEdit)?{key:"item.quantity",fn:function(ref){
var item = ref.item;
return [_c('div',{staticStyle:{"display":"flex"},attrs:{"justify":"center"}},[_c('v-btn',{attrs:{"small":"","color":"grey","icon":""},on:{"click":function($event){return _vm.decreaseItem(item)}}},[_c('v-icon',[_vm._v("mdi-minus-circle")])],1),_c('div',{staticClass:"mx-1 subtitle-1"},[_vm._v(" "+_vm._s(item.quantity)+" ")]),_c('v-btn',{attrs:{"icon":"","small":"","color":"grey"},on:{"click":function($event){return _vm.increaseItem(item)}}},[_c('v-icon',[_vm._v("mdi-plus-circle")])],1)],1)]}}:null,{key:"item.price",fn:function(ref){
var item = ref.item;
return [_c('span',[_vm._v("€"+_vm._s((item.price * item.quantity).toFixed(2)))])]}},{key:"item.name",fn:function(ref){
var item = ref.item;
return [_c('strong',[_vm._v(_vm._s(("" + (item.name))))])]}}],null,true)}):_vm._e(),(!_vm.isProduct)?_c('v-data-table',{staticClass:"additions mt-1",attrs:{"headers":_vm.additonHeaders,"items":_vm.cartItems,"items-per-page":100,"item-key":"name","hide-default-footer":"","sort-by":"name"},scopedSlots:_vm._u([{key:"item.price",fn:function(ref){
var item = ref.item;
return [_c('span',[_vm._v("€"+_vm._s((item.price * item.quantity).toFixed(2)))])]}},{key:"item.isCompleted",fn:function(ref){
var item = ref.item;
return [_c('v-checkbox',{staticClass:"mt-n1",attrs:{"hide-details":""},model:{value:(item.isCompleted),callback:function ($$v) {_vm.$set(item, "isCompleted", $$v)},expression:"item.isCompleted"}})]}},(_vm.isEdit)?{key:"item.quantity",fn:function(ref){
var item = ref.item;
return [_c('div',{staticStyle:{"display":"flex"},attrs:{"justify":"center"}},[_c('v-btn',{attrs:{"small":"","color":"grey","icon":""},on:{"click":function($event){return _vm.decreaseItem(item)}}},[_c('v-icon',[_vm._v("mdi-minus-circle")])],1),_c('div',{staticClass:"mx-1 subtitle-1"},[_vm._v(" "+_vm._s(item.quantity)+" ")]),_c('v-btn',{attrs:{"icon":"","small":"","color":"grey"},on:{"click":function($event){return _vm.increaseItem(item)}}},[_c('v-icon',[_vm._v("mdi-plus-circle")])],1)],1)]}}:null],null,true)}):_vm._e(),_c('v-card-actions',[(!_vm.isProduct && _vm.isEdit)?_c('v-btn',{attrs:{"right":"","text":"","color":"primary"},on:{"click":_vm.toggleDialogAddition}},[_c('v-icon',[_vm._v("mdi-plus")]),_vm._v(" Nieuwe toevoeging")],1):_vm._e()],1),(!_vm.isProduct)?_c('v-dialog',{attrs:{"width":"400"},model:{value:(_vm.dialogAdditon),callback:function ($$v) {_vm.dialogAdditon=$$v},expression:"dialogAdditon"}},[_c('addition-dialog',{on:{"close-dialog":_vm.toggleDialogAddition,"add-addition":_vm.addAddition}})],1):_vm._e()],1)}
var staticRenderFns = []

export { render, staticRenderFns }