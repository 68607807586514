<template>
  <div>
    <akito-menubar />
    <v-main>
      <slot />
    </v-main>
  </div>
</template>

<script>
import { mapActions } from 'vuex'
import AkitoMenubar from '../components/toolbars/akito-menubar'
import { FETCH_POS_ORDERS_FOR_TODAY, ORDER } from '@/store/constants.js'
import { Howl } from 'howler'
// const Swal = require("sweetalert2");

export default {
  name: 'DefaultLayout',
  components: { AkitoMenubar },
  data() {
    return {
      initialized: false,
      notificationSound: undefined,
    }
  },

  methods: {
    ...mapActions({
      fetchCurrentOrders: `${ORDER}/${FETCH_POS_ORDERS_FOR_TODAY}`,
    }),

    async attemptFetchCurrentOrders() {
      await this.fetchCurrentOrders()

      // this.notificationSound.play();

      // Swal.fire({
      //   position: "top-end",
      //   icon: "info",
      //   title: "Nieuwe bestelling!",
      //   toast: true,
      //   timer: 5000,
      // }).then((result) => {
      //   if (result.isConfirmed) {
      //     this.$router.push("/current-orders");
      //   }
      // });
    },
  },

  beforeDestroy() {
    this.$orderHub.$off('new-order-created', this.attemptFetchCurrentOrders)
    this.$orderHub.$off('order-updated', this.attemptFetchCurrentOrders)
  },

  created() {
    this.$orderHub.$on('new-order-created', this.attemptFetchCurrentOrders)
    this.$orderHub.$on('order-updated', this.attemptFetchCurrentOrders)
    document.title = 'Akito Cashier'
  },

  mounted() {
    const notificationSound = require('@/assets/notification.mp3')
    this.notificationSound = new Howl({
      src: [notificationSound],
    })
  },
}
</script>

<style>
#app {
  background-color: #eee !important;
}
.swal2-popup {
  font-family: 'Roboto', sans-serif;
}

html {
  overflow-y: auto;
}
</style>
