<template>
  <v-container v-if="isInitialized">
    <v-data-iterator
      :items="filteredProducts"
      :items-per-page.sync="itemsPerPage"
      :page="page"
      :search="search"
      :sort-by="sortBy"
      hide-default-footer
    >
      <template v-slot:header>
        <v-toolbar color="transparent" class="py-2" flat>
          <v-sheet
            v-if="$vuetify.breakpoint.smAndUp"
            width="300"
            color="transparent"
          >
            <!-- Sort By input -->
            <v-select
              v-model="sortBy"
              flat
              rounded
              solo-inverted
              hide-details
              :items="sortByKeys"
              prepend-inner-icon="mdi-sort"
              label="Sort by"
              item-text="name"
              item-value="value"
            />
          </v-sheet>

          <!-- Search input -->
          <v-text-field
            v-model="search"
            class="ma-3"
            clearable
            flat
            rounded
            outlined
            hide-details
            prepend-inner-icon="mdi-magnify"
            label="Zoeken"
          />
        </v-toolbar>
      </template>

      <!-- Display information when no products are available -->
      <template v-slot:no-data>
        <v-card-text class="text-center">
          <v-card-subtitle> Er zijn geen producten zichtbaar. </v-card-subtitle>
          <v-btn color="primary" @click="createProduct">
            <v-icon> mdi-plus </v-icon>
            <span v-if="!$vuetify.breakpoint.xs" class="ml-1">
              Nieuw product
            </span>
          </v-btn>
        </v-card-text>
      </template>

      <!-- Display products -->
      <template v-slot:default="props">
        <v-card
          color="transparent"
          max-width="1600"
          flat
          class="mx-auto mb-16 pb-12"
        >
          <v-container>
            <v-row :dense="$vuetify.breakpoint.xs">
              <v-col
                v-for="item in props.items"
                :key="item.name"
                cols="12"
                sm="6"
                md="4"
                lg="3"
              >
                <!-- Product card -->
                <v-card>
                  <v-list-item @click="editProduct(item)">
                    <v-list-item-avatar v-if="imageEnabled" rounded size="60">
                      <v-img :src="item.imageUrl" />
                    </v-list-item-avatar>
                    <v-list-item-title class="pa-2">
                      <span class="font-weight-bold mx-1">
                        {{ item.name }}
                      </span>
                      <div>
                        <v-chip
                          :color="item.category.colorCode"
                          small
                          dark
                          class="my-2"
                        >
                          {{ item.category.name }}
                        </v-chip>
                      </div>
                    </v-list-item-title>
                    <v-list-item-action v-if="item.isDeleted">
                      <v-icon class="mx-3"> mdi-alert-circle </v-icon>
                    </v-list-item-action>
                  </v-list-item>
                  <v-divider />
                </v-card>
              </v-col>
            </v-row>
            <v-row class="text-center">
              <v-col>
                <v-btn color="primary" @click="createProduct">
                  <v-icon> mdi-plus </v-icon>
                  <span v-if="!$vuetify.breakpoint.xs" class="ml-1">
                    Nieuw product
                  </span>
                </v-btn>
              </v-col>
            </v-row>
          </v-container>
        </v-card>
      </template>

      <!-- Pagination controls -->
      <template v-slot:footer>
        <v-footer fixed>
          <v-container fluid>
            <v-row align="center" justify="center" dense>
              <v-col cols="12" sm="5">
                <span class="gray--text">Producten per pagina</span>
                <v-menu offset-y>
                  <template v-slot:activator="{ on, attrs }">
                    <v-btn
                      dark
                      text
                      color="secondary"
                      class="ml-2"
                      v-bind="attrs"
                      v-on="on"
                    >
                      {{ itemsPerPage }}
                      <v-icon>mdi-chevron-down</v-icon>
                    </v-btn>
                  </template>
                  <v-list>
                    <v-list-item
                      v-for="(number, index) in itemsPerPageKeys"
                      :key="index"
                      @click="selectItemsPerPage(number)"
                    >
                      <v-list-item-title>{{ number }}</v-list-item-title>
                    </v-list-item>
                  </v-list>
                </v-menu>
              </v-col>

              <v-col>
                <v-btn
                  icon
                  color="secondary"
                  class="mr-1"
                  @click="previousPage"
                >
                  <v-icon>mdi-chevron-left</v-icon>
                </v-btn>
                <span class="mx-4 gray--text">
                  Pagina {{ page }} van {{ numberOfPages }}
                </span>
                <v-btn icon color="secondary" class="ml-1" @click="nextPage">
                  <v-icon>mdi-chevron-right</v-icon>
                </v-btn>
              </v-col>
            </v-row>
          </v-container>
        </v-footer>
      </template>
    </v-data-iterator>

    <!-- Create dialog -->
    <v-dialog
      v-model="isCreatingProduct"
      max-width="700"
      :fullscreen="$vuetify.breakpoint.xs"
      persistent
    >
      <product-dialog
        :is-creating="true"
        title="Nieuw Product"
        @close-dialog="isCreatingProduct = false"
      />
    </v-dialog>

    <!-- Edit dialog -->
    <v-dialog
      v-model="isEditingProduct"
      max-width="700"
      :fullscreen="$vuetify.breakpoint.xs"
      persistent
    >
      <product-dialog
        :is-creating="false"
        title="Bewerk Product"
        @close-dialog="isEditingProduct = false"
      />
    </v-dialog>

    <!-- Category drawer -->
    <v-navigation-drawer
      v-model="rightDrawer"
      right
      app
      :width="200"
      clipped
      :permanent="$vuetify.breakpoint.smAndUp && rightDrawer"
    >
      <div v-if="categories" class="mb-2">
        <category-card
          :categories="categories"
          @set-category="setCategory"
          :isCreating="true"
        />
      </div>
    </v-navigation-drawer>
  </v-container>
</template>

<script>
import { mapGetters, mapActions } from 'vuex'
import ProductDialog from '@/components/products/ProductDialog'
import CategoryCard from '@/components/menu-cashier/category-card'
import { cloneDeep } from 'lodash'
import {
  SERVICE_ROOT,
  PRODUCT,
  GET_PRODUCTS,
  GET_CATEGORIES,
  IS_INITIALIZED,
  INITIALIZE,
  SET_PRODUCTS,
  SET_SELECTED_PRODUCT,
} from '@/store/constants.js'

export default {
  name: 'Product',
  components: { ProductDialog, CategoryCard },

  data() {
    return {
      imageEnabled: false,
      isCreatingProduct: false,
      isEditingProduct: false,
      itemsPerPage: 25,
      itemsPerPageKeys: [5, 10, 25, 50],
      page: 1,
      search: '',
      selectedProduct: null,
      sortBy: 'category.name',
      sortByKeys: [
        { name: 'Categorie', value: 'category.name' },
        { name: 'Naam', value: 'name' },
      ],
      rightDrawer: true,
      selectedCategoryId: -1,
    }
  },

  computed: {
    ...mapGetters({
      isInitialized: `${SERVICE_ROOT}/${IS_INITIALIZED}`,
      products: `${SERVICE_ROOT}/${GET_PRODUCTS}`,
      categories: `${SERVICE_ROOT}/${GET_CATEGORIES}`,
    }),

    filteredProducts() {
      let products = this.products
      if (this.selectedCategoryId !== -1) {
        products = products.filter(
          (p) => p.categoryId == this.selectedCategoryId
        )
      }

      return products
    },

    numberOfPages() {
      return Math.ceil(this.products.length / this.itemsPerPage)
    },
  },

  methods: {
    ...mapActions({
      initialize: `${SERVICE_ROOT}/${INITIALIZE}`,
      setProducts: `${SERVICE_ROOT}/${SET_PRODUCTS}`,
      setSelectedProduct: `${PRODUCT}/${SET_SELECTED_PRODUCT}`,
    }),

    async createProduct() {
      const newProduct = {
        categoryId:
          this.selectedCategoryId !== -1 ? this.selectedCategoryId : 1,
        description: '',
        displayOrder: null,
        id: null,
        imageUrl: null,
        isPickUpOnly: false,
        name: '',
        taxRate: 9.0,
        variants: [],
      }
      await this.setSelectedProduct(newProduct)
      this.isCreatingProduct = true
    },

    editProduct(product) {
      this.setSelectedProduct(cloneDeep(product))
      this.isEditingProduct = true
    },

    nextPage() {
      if (this.page + 1 <= this.numberOfPages) {
        this.page += 1
      }
    },

    previousPage() {
      if (this.page - 1 >= 1) {
        this.page -= 1
      }
    },

    selectItemsPerPage(value) {
      this.itemsPerPage = value
    },

    setCategory(value) {
      this.selectedCategoryId = value
    },
  },
}
</script>

<style scoped>
.footer {
  position: fixed;
  left: 0;
  bottom: 0;
  width: 100%;
}
</style>
