<template>
  <v-card>
    <v-card-title>
      <v-btn class="mr-3" icon color="primary" @click="$emit('close-dialog')">
        <v-icon> mdi-close </v-icon>
      </v-btn>
      Toevoegingen
    </v-card-title>
    <v-divider></v-divider>
    <v-card-actions class="pt-4">
      <v-combobox
        v-model="addition.name"
        label="Toevoeging"
        class="mx-1"
        dense
        outlined
        hide-details=""
        :items="getAdditions"
        @change="checkAdditionPrice()"
      ></v-combobox>
      <v-text-field
        v-model="addition.price"
        label="Bedrag"
        placeholder="0.00"
        class="mx-1"
        prefix="€"
        dense
        hide-details=""
        outlined
      ></v-text-field>
    </v-card-actions>
    <v-card-text>
      <span class="caption">Is het niet toegevoegd? </span>
      <div class="caption">
        Selecteer de bedrag veld na het typen van de toevoeging.
      </div>
    </v-card-text>
    <v-divider></v-divider>
    <v-card-actions>
      <v-btn @click="$emit('close-dialog')" text large> Annuleren </v-btn>
      <v-spacer></v-spacer>
      <v-btn large color="secondary" @click="addAddition" text>
        Toevoegen
      </v-btn>
    </v-card-actions>
  </v-card>
</template>

<script>
export default {
  computed: {
    getAdditions() {
      return this.candidateAdditions.map((x) => x.name)
    },
  },

  data() {
    return {
      addition: {
        name: '',
        price: '0',
      },

      candidateAdditions: [
        {
          name: 'Gratis mini loempia',
          price: 0,
        },
        {
          name: 'Bezorgkosten',
          price: 5,
        },
      ],
    }
  },

  methods: {
    checkAdditionPrice() {
      const found = this.candidateAdditions.find(
        (x) => x.name == this.addition.name
      )
      if (found) this.addition.price = found.price.toFixed(2).toString()
    },
    addAddition() {
      this.$emit('add-addition', {
        item: {
          name: this.addition.name,
          price: this.addition.price.replace(',', '.'),
        },
        isProduct: false,
      })
      this.$emit('close-dialog')
    },
  },
}
</script>
