<template>
  <v-data-table
    :headers="headers"
    :items="getSelectedProduct.variants"
    sort-by="name"
    class="elevation-1"
    :items-per-page="4"
  >
    <template v-slot:[`item.isEnabled`]="{ item }">
      <v-simple-checkbox v-model="item.isEnabled" disabled></v-simple-checkbox>
    </template>
    <template v-slot:[`item.isSoldOut`]="{ item }">
      <v-simple-checkbox v-model="item.isSoldOut" disabled></v-simple-checkbox>
    </template>
    <template v-slot:[`item.price`]="{ item }">
      {{ Number.parseFloat(item.price).toFixed(2) }}
    </template>
    <template v-slot:top>
      <v-toolbar flat>
        <v-toolbar-title>Varianten</v-toolbar-title>
        <v-divider class="mx-4" inset vertical></v-divider>
        <v-spacer></v-spacer>
        <v-dialog v-model="dialog" max-width="500px" persistent>
          <template v-slot:activator="{ on, attrs }">
            <v-btn color="primary" dark class="mb-2" v-bind="attrs" v-on="on">
              Nieuwe variant
            </v-btn>
          </template>
          <v-card>
            <v-card-title>
              <span class="headline">{{ formTitle }}</span>
            </v-card-title>

            <v-card-text>
              <v-container>
                <v-row>
                  <v-col>
                    <v-text-field
                      v-model="selectedVariant.name"
                      label="Naam"
                      outlined
                      counter
                      maxlength="64"
                      :rules="[rules.required]"
                    ></v-text-field>
                  </v-col>
                </v-row>
                <v-row>
                  <v-col>
                    <v-text-field
                      v-model="selectedVariant.variantCode"
                      label="Code"
                      outlined
                      counter
                      maxlength="7"
                      :rules="[rules.required, rules.variant]"
                    ></v-text-field>
                  </v-col>
                  <v-col>
                    <v-text-field
                      v-model="selectedVariant.price"
                      label="Prijs"
                      outlined
                    ></v-text-field>
                  </v-col>
                </v-row>
                <v-row>
                  <v-col>
                    <v-select
                      v-model="selectedVariant.isEnabled"
                      :items="booleanOptions"
                      label="Zichtbaar"
                      item-text="name"
                      item-value="value"
                      dense
                      outlined
                    />
                  </v-col>
                  <v-col>
                    <v-select
                      v-model="selectedVariant.isSoldOut"
                      :items="booleanOptions"
                      label="Uitverkocht"
                      item-text="name"
                      item-value="value"
                      dense
                      outlined
                    />
                  </v-col>
                </v-row>
              </v-container>
            </v-card-text>

            <v-card-actions>
              <v-spacer></v-spacer>
              <v-btn color="blue darken-1" text @click="close">
                Annuleren
              </v-btn>
              <v-btn
                color="blue darken-1"
                text
                @click="save"
                :disabled="invalid"
              >
                Opslaan
              </v-btn>
            </v-card-actions>
          </v-card>
        </v-dialog>
        <v-dialog v-model="dialogDelete" max-width="500px" persistent>
          <v-card>
            <v-card-title class="headline"
              >Bevestig verwijderen
              {{ selectedVariant.variantCode }}?</v-card-title
            >
            <v-card-actions>
              <v-spacer></v-spacer>
              <v-btn color="blue darken-1" text @click="dialogDelete = false"
                >Annuleren</v-btn
              >
              <v-btn
                color="blue darken-1"
                text
                @click="deleteSelectedVariantConfirm"
                >OK</v-btn
              >
              <v-spacer></v-spacer>
            </v-card-actions>
          </v-card>
        </v-dialog>
      </v-toolbar>
    </template>
    <template v-slot:[`item.actions`]="{ item }">
      <v-icon small class="mr-2" @click="editSelectedVariant(item)">
        mdi-pencil
      </v-icon>
      <v-icon small @click="deleteSelectedVariant(item)"> mdi-delete </v-icon>
    </template>
    <template v-slot:no-data> Geen product varianten. </template>
  </v-data-table>
</template>

<script>
import { mapActions, mapGetters } from 'vuex'
import {
  ADD_PRODUCT_VARIANT,
  GET_SELECTED_PRODUCT,
  GET_VARIANT_CODE,
  PRODUCT,
  REMOVE_PRODUCT_VARIANT,
  SERVICE_ROOT,
  UPDATE_PRODUCT_VARIANT,
} from '@/store/constants.js'
import { cloneDeep } from 'lodash'

export default {
  data() {
    return {
      dialog: false,
      dialogDelete: false,
      edit: false,
      formTitle: 'Varianten',
      headers: [
        { text: 'Naam', value: 'name' },
        { text: 'Code', value: 'variantCode' },
        { text: 'Prijs', value: 'price' },
        { text: 'Zichtbaar', value: 'isEnabled' },
        { text: 'Uitverkocht', value: 'isSoldOut' },
        { text: 'Acties', value: 'actions', sortable: false },
      ],
      selectedVariant: {
        isEnabled: true,
        isSoldOut: false,
        name: '',
        price: 0,
        variantCode: '',
      },
      originalVariantCode: '',
      defaultVariant: {
        isEnabled: true,
        isSoldOut: false,
        name: '',
        price: 0,
        variantCode: '',
      },
      booleanOptions: [
        { name: 'Ja', value: true },
        { name: 'Nee', value: false },
      ],
      rules: {
        required: (value) => !!value || 'Verplicht.',
        variant: (value) =>
          !this.isNotUniqueVariantCode(value) || 'Niet uniek.',
      },
      deletedVariantCodes: [],
    }
  },

  computed: {
    ...mapGetters({
      getSelectedProduct: `${PRODUCT}/${GET_SELECTED_PRODUCT}`,
      getVariantCodes: `${SERVICE_ROOT}/${GET_VARIANT_CODE}`,
    }),

    invalid() {
      return (
        this.selectedVariant.name.length == 0 ||
        this.selectedVariant.name.length > 64 ||
        this.selectedVariant.variantCode.length == 0 ||
        this.selectedVariant.variantCode.length > 7 ||
        this.isNotUniqueVariantCode(this.selectedVariant.variantCode)
      )
    },
  },

  methods: {
    ...mapActions({
      addProductVariant: `${PRODUCT}/${ADD_PRODUCT_VARIANT}`,
      removeProductVariant: `${PRODUCT}/${REMOVE_PRODUCT_VARIANT}`,
      updateProductVariant: `${PRODUCT}/${UPDATE_PRODUCT_VARIANT}`,
    }),

    close() {
      this.dialog = false
      this.$nextTick(() => {
        this.selectedVariant = Object.assign({}, this.defaultVariant)
      })
    },

    closeDelete() {
      this.dialogDelete = false
      this.$nextTick(() => {
        this.removeProductVariant(this.selectedVariant.variantCode)
        this.deletedVariantCodes.push(this.selectedVariant.variantCode)
        this.selectedVariant = Object.assign({}, this.defaultVariant)
      })
    },

    deleteSelectedVariant(item) {
      this.selectedVariant = Object.assign({}, item)
      this.dialogDelete = true
    },

    deleteSelectedVariantConfirm() {
      this.closeDelete()
    },

    editSelectedVariant(item) {
      this.originalVariantCode = item.variantCode
      this.selectedVariant = cloneDeep(item)
      this.edit = true
      this.dialog = true
    },

    save() {
      if (this.edit) {
        this.updateProductVariant({
          variant: this.selectedVariant,
          variantCode: this.originalVariantCode,
        })
        this.edit = false
      } else {
        this.addProductVariant(this.selectedVariant)
      }
      this.close()
    },

    isNotUniqueVariantCode(variantCode) {
      const currentVariantCodes = this.getSelectedProduct.variants.map(
        (x) => x.variantCode
      )
      const filteredVariants = this.getVariantCodes.filter(
        (x) => !currentVariantCodes.includes(x)
      )
      return (
        filteredVariants.includes(variantCode) &&
        !this.deletedVariantCodes.includes(variantCode)
      )
    },
  },
}
</script>
