<template>
  <div class="custom-scroll" :style="!isCreating ? `background: #555` : ''">
    <v-card
      link
      flat
      @click="setCategory(-1)"
      rounded="0"
      dark
      :color="
        selectedCategoryId < 0 ? (isColorCoded ? 'black' : 'primary') : '#555'
      "
    >
      <v-card-title class="justify-center"> Alles </v-card-title>
    </v-card>
    <div v-for="(category, i) in categories" :key="i">
      <v-card
        flat
        dark
        :color="
          isCreating
            ? category.colorCode
            : selectedCategoryId === category.id
            ? isColorCoded
              ? category.colorCode
              : 'primary'
            : '#555'
        "
        @click="setCategory(category.id)"
        rounded="0"
      >
        <v-list-item v-if="isCreating">
          <v-list-item-action>
            <v-btn icon small @click.stop="editCategory(category)" class="mr-2">
              <v-icon>mdi-pencil</v-icon>
            </v-btn>
          </v-list-item-action>
          <v-list-item-title class="title">
            {{ category.categoryCode }}
          </v-list-item-title>
        </v-list-item>
        <v-avatar
          :color="category.colorCode"
          size="8"
          height="100%"
          v-if="!isCreating && isColorCoded"
          class="color-square rounded-0"
        ></v-avatar>
        <v-card-title class="justify-center pb-0 px-8" v-if="!isCreating">
          {{ category.categoryCode }}
        </v-card-title>
        <div class="caption text-center pb-2" v-if="!isCreating">
          {{ category.name }}
        </div>
      </v-card>
    </div>
    <v-card link @click="createCategory" flat v-if="isCreating">
      <v-card-title class="justify-center">
        <v-icon> mdi-plus </v-icon>
        <span v-if="!$vuetify.breakpoint.xs" class="ml-1"> Nieuw </span>
      </v-card-title>
    </v-card>

    <!-- Category create dialog -->
    <v-dialog v-model="dialog" max-width="500px" persistent>
      <v-card>
        <v-card-title>
          <span class="headline mr-2"
            >{{ edit ? 'Bewerk categorie' : 'Nieuw categorie' }}
          </span>
          <v-progress-circular
            v-if="processing"
            indeterminate
            color="primary"
          ></v-progress-circular>
        </v-card-title>

        <v-card-text>
          <v-form v-model="valid" lazy-validation>
            <v-text-field
              v-model="selectedCategory.name"
              counter
              maxlength="64"
              :rules="[rules.required]"
              label="Naam"
              required
              outlined
              :disabled="processing"
            ></v-text-field>

            <v-text-field
              v-model="selectedCategory.categoryCode"
              :rules="[rules.required, rules.unique]"
              counter
              maxlength="7"
              label="Categorie code"
              required
              outlined
              :disabled="processing"
            ></v-text-field>

            <v-color-picker
              v-model="selectedCategory.colorCode"
              show-swatches
              width="100%"
              :swatches="colorCodes"
              hide-mode-switch
              hide-canvas
              mode="hexa"
              :disabled="processing"
            />

            <v-btn
              v-if="edit"
              color="error"
              class="mt-4"
              @click="dialogDelete = true"
              :disabled="processing"
            >
              <v-icon class="mr-1"> mdi-trash-can </v-icon>
              Verwijder Categorie
            </v-btn>

            <v-dialog v-model="dialogDelete" max-width="500px">
              <v-card>
                <v-card-title class="headline"
                  >Bevestig verwijderen
                  {{ selectedCategory.name }}?</v-card-title
                >
                <v-card-actions>
                  <v-spacer></v-spacer>
                  <v-btn
                    color="blue darken-1"
                    text
                    @click="dialogDelete = false"
                    :disabled="processing"
                    >Annuleren</v-btn
                  >
                  <v-btn
                    color="blue darken-1"
                    text
                    @click="attemptDeleteCategory"
                    :disabled="processing"
                    >OK</v-btn
                  >
                  <v-spacer></v-spacer>
                </v-card-actions>
              </v-card>
            </v-dialog>
          </v-form>
        </v-card-text>

        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn
            color="blue darken-1"
            text
            @click="close"
            :disabled="processing"
          >
            Annuleren
          </v-btn>
          <v-btn color="blue darken-1" text @click="save" :disabled="!isValid">
            Opslaan
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </div>
</template>

<script>
import { cloneDeep } from 'lodash'
import { mapActions, mapGetters } from 'vuex'
import {
  SERVICE_ROOT,
  CATEGORY,
  IS_INITIALIZED,
  GET_CATEGORIES,
  INITIALIZE,
  SET_CATEGORIES,
  SET_SELECTED_CATEGORY,
  SAVE_EDIT_CATEGORY,
  SAVE_NEW_CATEGORY,
  DELETE_CATEGORY,
  GET_SELECTED_CATEGORY,
} from '@/store/constants.js'

export default {
  props: {
    isCreating: {
      type: Boolean,
      default() {
        return false
      },
    },

    isColorCoded: Boolean,
  },

  data() {
    return {
      dialog: false,
      edit: false,
      dialogDelete: false,
      selectedCategoryId: -1,
      selectedCategory: {
        categoryCode: '',
        colorCode: '#FFFFF0',
        name: '',
        kitchenTypeId: 1,
      },
      defaultCategory: {
        categoryCode: '',
        colorCode: '#FFFFF0',
        name: '',
        kitchenTypeId: 1,
      },
      rules: {
        required: (value) => !!value || 'Verplicht.',
        unique: (value) => this.isUniqueCategoryCode(value) || 'Niet uniek.',
      },
      valid: false,
      colorCodes: [
        ['#F28888'],
        ['#FCBA3A', '#F9A75C'],
        ['#97CE6C', '#35B852'],
        ['#7EA9FB', '#4A8AEA'],
      ],
      processing: false,
    }
  },

  mounted() {
    this.setCategories()
  },

  computed: {
    ...mapGetters({
      isInitialized: `${SERVICE_ROOT}/${IS_INITIALIZED}`,
      categories: `${SERVICE_ROOT}/${GET_CATEGORIES}`,
      getSelectedCategory: `${CATEGORY}/${GET_SELECTED_CATEGORY}`,
    }),

    isValid() {
      return (
        this.valid &&
        this.selectedCategory.name.length > 0 &&
        this.isUniqueCategoryCode(this.selectedCategory.categoryCode)
      )
    },
  },

  methods: {
    ...mapActions({
      initialize: `${SERVICE_ROOT}/${INITIALIZE}`,
      setCategories: `${SERVICE_ROOT}/${SET_CATEGORIES}`,
      setSelectedCategory: `${CATEGORY}/${SET_SELECTED_CATEGORY}`,
      saveEditCategory: `${CATEGORY}/${SAVE_EDIT_CATEGORY}`,
      saveNewCategory: `${CATEGORY}/${SAVE_NEW_CATEGORY}`,
      deleteCategory: `${CATEGORY}/${DELETE_CATEGORY}`,
    }),

    setCategory(id) {
      this.$emit('set-category', id)
      this.selectedCategoryId = id
    },

    isUniqueCategoryCode(categoryCode) {
      let categoryCodes = this.categories.map((x) => x.categoryCode)

      if (this.getSelectedCategory) {
        categoryCodes = categoryCodes.filter(
          (x) => x != this.getSelectedCategory.categoryCode
        )
      }

      return !categoryCodes.includes(categoryCode)
    },

    editCategory(category) {
      this.setSelectedCategory(category)
      this.selectedCategory = cloneDeep(category)
      this.edit = true
      this.dialog = true
    },

    createCategory() {
      this.selectedCategory = cloneDeep(this.defaultCategory)
      this.setSelectedCategory(cloneDeep(this.defaultCategory))
      this.dialog = true
    },

    close() {
      this.edit = false
      this.dialog = false
    },

    async save() {
      this.processing = true
      this.setSelectedCategory(this.selectedCategory)

      if (this.edit) {
        await this.saveEditCategory()
      } else {
        await this.saveNewCategory()
      }

      this.dialog = false
      this.processing = false
    },

    async attemptDeleteCategory() {
      this.processing = true
      await this.deleteCategory()
      this.close()
      this.processing = false
    },
  },
}
</script>

<style scoped>
.header {
  margin-top: 10px;
  font-size: 34px;
  font-family: 'Gill Sans', 'Gill Sans MT', Calibri, 'Trebuchet MS', sans-serif;
}

.color-square {
  position: absolute;
  left: 0px;
  top: 0px;
}
</style>
