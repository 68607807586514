<template>
  <v-app>
    <component :is="layout">
      <router-view></router-view>
    </component>
  </v-app>
</template>

<script>
const DEFAULT_LAYOUT = 'default'
import { mapActions } from 'vuex'
import { INITIALIZE, SERVICE_ROOT } from '@/store/constants.js'

export default {
  async mounted() {
    // await new Promise((r) => setTimeout(r, 2000)); // Wait 1s to let the app initialize
    // await this.initializeApp();
  },

  computed: {
    layout() {
      const layout = this.$route.meta.layout || DEFAULT_LAYOUT
      return () => import(`@/layouts/${layout}.vue`)
    },
  },

  methods: {
    ...mapActions({
      initialize: `${SERVICE_ROOT}/${INITIALIZE}`,
    }),

    async initializeApp() {
      const token = await this.$auth.getTokenSilently()
      this.initialize(token)
    },
  },
}
</script>
