<template>
  <div>
    <v-app-bar
      app
      color="primary"
      dark
      :clipped-left="true"
      :clipped-right="true"
      v-if="$route.name"
    >
      <v-app-bar-nav-icon to="/">
        <v-icon>mdi-home</v-icon>
      </v-app-bar-nav-icon>
      <v-app-bar-nav-icon
        to="/current-orders"
        v-if="$route.name.match('Cashier') || $route.name.match('Kitchen')"
      >
        <v-icon>mdi-text-box-check</v-icon>
      </v-app-bar-nav-icon>
      <v-app-bar-nav-icon to="/cashier" v-else>
        <v-icon>mdi-hand-coin</v-icon>
      </v-app-bar-nav-icon>
      <v-spacer />
      <aktio-topbar />
      <v-spacer />
      <v-app-bar-nav-icon @click="rightDrawer = !rightDrawer">
        v0.3.11</v-app-bar-nav-icon
      >
      <v-app-bar-nav-icon icon @click="logout">
        <v-icon>mdi-logout</v-icon>
      </v-app-bar-nav-icon>
    </v-app-bar>
    <v-navigation-drawer v-model="rightDrawer" right app temporary width="400">
      <news-timeline />
    </v-navigation-drawer>
  </div>
</template>

<script>
import AktioTopbar from './akito-topbar.vue'
import NewsTimeline from './newsTimeline.vue'

export default {
  components: { AktioTopbar, NewsTimeline },

  data() {
    return {
      rightDrawer: false,
    }
  },

  methods: {
    logout() {
      this.$auth.logout({
        returnTo: window.location.origin,
      })
    },
  },
}
</script>
