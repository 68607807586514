<template>
  <v-card-text max-width="600">
    <v-list width="450">
      <v-list-item v-for="(pair, index) in categoryByProfit" :key="index">
        <v-list-item-title>
          <span class="px-3">
            {{ `${pair[0]}` }}
          </span>
        </v-list-item-title>
        <v-list-item-title>
          &euro; {{ `${pair[1].toFixed(2)}` }}
        </v-list-item-title>
      </v-list-item>
    </v-list>
  </v-card-text>
</template>

<script>
export default {
  props: {
    categorieRevenue: Object,
  },

  computed: {
    categoryByProfit() {
      return Object.entries(this.categorieRevenue)
        .sort(([,a],[,b]) => a-b)
        .reverse()
    }
  }
}
</script>
