import {
  SET_CATEGORIES,
  SET_PRODUCTS,
  SERVICE_ROOT,
  GET_SELECTED_CATEGORY,
  SET_SELECTED_CATEGORY,
  SAVE_EDIT_CATEGORY,
  RESET_CATEGORY,
  SAVE_NEW_CATEGORY,
  DELETE_CATEGORY,
} from '../constants'

const state = () => ({
  selectedCategory: undefined,
})

const getters = {
  [GET_SELECTED_CATEGORY]: (state) => {
    return state.selectedCategory
  },
}

const actions = {
  [SET_SELECTED_CATEGORY]({ commit }, category) {
    commit(`${SET_SELECTED_CATEGORY}`, category)
  },

  async [SAVE_EDIT_CATEGORY]({ commit, state, dispatch, rootState }) {
    if (state.selectedCategory.displayOrder == null)
      delete state.selectedCategory.displayOrder
    if (state.selectedCategory.imagstickeUrl == null)
      state.selectedCategory.imageUrl = ''
    await rootState.SERVICE_ROOT.repository.category.updateCategory(
      state.selectedCategory.id,
      state.selectedCategory
    )
    commit(`${RESET_CATEGORY}`)
    await dispatch(`${SERVICE_ROOT}/${SET_CATEGORIES}`, null, { root: true })
    await dispatch(`${SERVICE_ROOT}/${SET_PRODUCTS}`, null, { root: true })
  },

  async [SAVE_NEW_CATEGORY]({ commit, state, dispatch, rootState }) {
    if (state.selectedCategory.displayOrder == null)
      delete state.selectedCategory.displayOrder
    if (state.selectedCategory.imageUrl == null)
      state.selectedCategory.imageUrl = ''
    await rootState.SERVICE_ROOT.repository.category.createCategory(
      state.selectedCategory
    )
    commit(`${RESET_CATEGORY}`)
    await dispatch(`${SERVICE_ROOT}/${SET_CATEGORIES}`, null, { root: true })
    await dispatch(`${SERVICE_ROOT}/${SET_PRODUCTS}`, null, { root: true })
  },

  async [DELETE_CATEGORY]({ commit, state, dispatch, rootState }) {
    await rootState.SERVICE_ROOT.repository.category.deleteCategory(
      state.selectedCategory.id
    )
    commit(`${RESET_CATEGORY}`)
    await dispatch(`${SERVICE_ROOT}/${SET_CATEGORIES}`, null, { root: true })
    await dispatch(`${SERVICE_ROOT}/${SET_PRODUCTS}`, null, { root: true })
  },
}

const mutations = {
  [SET_SELECTED_CATEGORY](state, category) {
    state.selectedCategory = category
  },

  [RESET_CATEGORY](state) {
    state.selectedCategory = {
      categoryCode: '',
      colorCode: '#FFFFF0',
      name: '',
    }
  },
}

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations,
}
