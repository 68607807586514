import {
  CART,
  CREATE_ACTIVE_ORDER,
  CREATE_EXTERNAL_ORDER,
  DELETE_ACTIVE_ORDER_FROM_POS,
  DELETE_EXTERNAL_ORDER,
  EDIT_ACTIVE_ORDER,
  FETCH_EXTERNAL_ORDERS,
  FETCH_POS_ORDERS_BY_DATE,
  FETCH_POS_ORDERS_FOR_TODAY,
  GET_ACTIVE_ORDER,
  GET_AVAILABLE_TIME_SLOTS,
  GET_EXTERNAL_ORDERS,
  GET_ORDER_ADDITIONS,
  GET_ORDER_PRODUCTS,
  GET_POS_ORDERS,
  GET_POS_ORDER_SORTED_BY_STATUS,
  GET_POS_ORDER_SORTED_BY_TIME,
  GET_PRINT_OPTIONS,
  MARK_VARIANT_COMPLETE,
  PRINT,
  PRINT_CUSTOMER,
  PRINT_KITCHEN,
  RESET_ACTIVE_ORDER,
  SET_ACTIVE_ORDER,
  SET_ACTIVE_ORDER_BY_ID,
  SET_NEW_ACTIVE_ORDER,
  SET_ORDER_PRODUCTS_AND_ADDITIONS,
  SET_PRINT_OPTIONS,
  HELPER,
  TIME,
} from '../constants.js'
import * as moment from 'moment'

//#region ORDER - MUTATIONS (private)
const SET_POS_ORDERS = 'SET_POS_ORDERS'
const SET_EXTERNAL_ORDERS = 'SET_EXTERNAL_ORDERS'
const ADD_EXTERNAL_ORDERS = 'ADD_EXTERNAL_ORDERS'
//#endregion
const Swal = require('sweetalert2')

const state = () => ({
  orders: [],
  currentDate: null,
  externalOrders: [],
  date: null,
  activeOrder: {
    id: -1,
    name: '',
    deliverTime: '16:15',
    description: '',
    isPaid: false,
    isPickUp: true,
    paymentMethodId: 1,
    orderedPVs: [],
    orderAdditions: [],
    orderStatus: null,
    paymentMethod: null,
    location: null,
    orderStatusId: 1,
  },
  printOptions: {
    customer: 1,
    kitchen: 2,
  },
})

const getters = {
  [GET_POS_ORDERS]: (state) => {
    return state.orders
  },

  [GET_POS_ORDER_SORTED_BY_STATUS]: (state) => {
    let orders = state.orders.filter((x) => x.orderStatusId !== 4)

    return orders.sort(function compare(a, b) {
      return a.deliverTime > b.deliverTime || -(a.deliverTime < b.deliverTime)
    })
  },

  [GET_POS_ORDER_SORTED_BY_TIME]: (state) => (showAll) => {
    let startTime = '16:00'
    let endTime = '20:00'
    const orderTimeList = []
    const asapTime = 15
    const duration = moment
      .duration(moment(endTime, TIME).diff(moment(startTime, TIME)))
      .asMinutes()
    const increments = duration / asapTime

    //  Create time groups
    orderTimeList.push({
      time: '00:00',
      endTime: startTime,
      id: 0,
      orders: [],
    })

    for (let i = 1; i <= increments; i++) {
      endTime = moment(startTime, TIME)
        .add(asapTime, 'minutes')
        .format(TIME)
      const orderTime = {
        time: startTime,
        endTime,
        id: i,
        orders: [],
      }
      orderTimeList.push(orderTime)
      startTime = endTime
    }

    orderTimeList.push({
      time: endTime,
      endTime: '23:59',
      id: increments,
      orders: [],
    })

    //  Place orders in the right time groups
    state.orders.forEach((order) => {
      const deliverTime = moment(order.deliverTime).format(TIME)
      for (let i = 0; i < orderTimeList.length; i++) {
        const current = orderTimeList[i]
        if (deliverTime >= current.time && deliverTime < current.endTime) {
          if (showAll || (!showAll && order.orderStatusId < 4)) {
            current.orders.push(order)
            order.groupTime = current.time
            order.groupId = i
          }
        }
      }
    })

    return orderTimeList
  },

  [GET_ACTIVE_ORDER]: (state) => {
    return state.activeOrder
  },

  [GET_EXTERNAL_ORDERS]: (state) => {
    return state.externalOrders
  },

  // POSSIBLE TO MOVE INTO HELPER
  [GET_AVAILABLE_TIME_SLOTS]: () => {
    let startTime = '15:45'
    const endTime = '20:00'
    const asapTime = 15
    const duration = moment
      .duration(moment(endTime, TIME).diff(moment(startTime, TIME)))
      .asMinutes()
    const increments = duration / asapTime
    const list = []
    for (let i = 0; i <= increments; i++) {
      const temp = moment(startTime, TIME)
        .add(asapTime, 'minutes')
        .format(TIME)
      if (temp > moment().format(TIME)) list.push(temp)
      startTime = temp
    }
    if (list === []) list.push(moment().format(TIME))
    return list
  },

  [GET_PRINT_OPTIONS]: (state) => {
    return state.printOptions
  },
}

const actions = {
  async [MARK_VARIANT_COMPLETE]({ rootState }, variant) {
    await rootState.SERVICE_ROOT.repository.orderPV.updateVariant(
      variant.id,
      variant
    )
    if (variant.isCompleted)
      Swal.fire({
        position: 'top-end',
        icon: 'success',
        title: variant.name + ' is klaar.',
        toast: true,
        showConfirmButton: false,
        timer: 3000,
      })
    else
      Swal.fire({
        position: 'top-end',
        icon: 'warning',
        title: variant.name + ' is nog niet klaar.',
        toast: true,
        showConfirmButton: false,
        timer: 3000,
      })
  },

  [SET_PRINT_OPTIONS]({ state }, options) {
    state.printOptions = options
  },

  async [PRINT]({ state, dispatch }, orderId) {
    try {
      if (state.printOptions.kitchen > 0)
        await dispatch(
          `${HELPER}/${PRINT_KITCHEN}`,
          { orderId: orderId, times: state.printOptions.kitchen },
          { root: true }
        )
      if (state.printOptions.customer > 0)
        await dispatch(
          `${HELPER}/${PRINT_CUSTOMER}`,
          { orderId: orderId, times: state.printOptions.customer },
          { root: true }
        )
      if (state.printOptions.kitchen > 0 || state.printOptions.customer > 0)
        await Swal.fire({
          position: 'top-end',
          icon: 'success',
          title: 'Printen...' + orderId,
          toast: true,
          timer: 3000,
          showConfirmButton: false,
        })
    } catch (error) {
      Swal.fire({
        icon: 'error',
        title: 'Oops...',
        text: 'Iets is fout gegaan met het printen',
      })
      console.error(error)
    }
  },

  [RESET_ACTIVE_ORDER]({ commit }) {
    commit(`${RESET_ACTIVE_ORDER}`)
  },

  async [CREATE_ACTIVE_ORDER]({ rootState, state, dispatch }) {
    const { data } = await rootState.SERVICE_ROOT.repository.order.createOrder(
      state.activeOrder
    )
    await dispatch(`${PRINT}`, data.id)
    await dispatch(`${RESET_ACTIVE_ORDER}`)
  },

  /** Create external order */
  async [CREATE_EXTERNAL_ORDER]({ rootState, commit }, order) {
    const body = {
      deliverTime: moment(state.date),
      externalSource: order.externalSource,
      totalPrice: order.totalPrice,
    }
    const {
      data,
    } = await rootState.SERVICE_ROOT.repository.externalOrder.create(body)
    await commit(`${ADD_EXTERNAL_ORDERS}`, data)
    await Swal.fire({
      icon: 'success',
      title: 'Externe bestelling toegevoegd',
      toast: true,
      timer: 3000,
      position: 'top-end',
      showConfirmButton: false,
    })
  },

  async [DELETE_EXTERNAL_ORDER]({ rootState, commit }, externalOrder) {
    await Swal.fire({
      icon: 'info',
      title: 'Bestelling verwijderen?',
      text: 'Wil je bestelling verwijderen!',
      showCancelButton: true,
      confirmButtonText: 'Ja',
      cancelButtonText: `Nee`,
    }).then((result) => {
      if (result.isConfirmed) {
        rootState.SERVICE_ROOT.repository.externalOrder.delete(externalOrder.id)
        commit(`${DELETE_EXTERNAL_ORDER}`, externalOrder)
      }
    })
  },

  /** Fetch orders by date
   * @param date an object with start and end date formatted in iso string.
   */
  async [FETCH_POS_ORDERS_BY_DATE]({ commit, rootState, state }, date) {
    state.date = date
    const { data } = await rootState.SERVICE_ROOT.repository.order.get(
      state.date.start,
      state.date.end
    )
    commit(`${SET_POS_ORDERS}`, data)
  },

  /** Fetch external orders */
  async [FETCH_EXTERNAL_ORDERS]({ rootState, commit, state }, date) {
    state.date = {
      start: moment(date.start)
        .startOf('day')
        .toISOString(),
      end: moment(date.end)
        .endOf('day')
        .toISOString(),
    }
    const { data } = await rootState.SERVICE_ROOT.repository.externalOrder.get(
      state.date.start,
      state.date.end
    )
    commit(`${SET_EXTERNAL_ORDERS}`, data)
  },

  /** Edit order with different order products or ony internal details.
   * @param onlyDetails false if order products or additions need to be changed too.
   */
  async [EDIT_ACTIVE_ORDER](
    { commit, dispatch, rootState, state },
    onlyDetails
  ) {
    await dispatch(`${SET_ORDER_PRODUCTS_AND_ADDITIONS}`)
    const { data } = await rootState.SERVICE_ROOT.repository.order.updateOrder(
      state.activeOrder.id,
      state.activeOrder,
      onlyDetails
    )
    if (state.date) await dispatch(`${FETCH_POS_ORDERS_BY_DATE}`, state.date)
    else await dispatch(`${FETCH_POS_ORDERS_FOR_TODAY}`)
    await commit(`${SET_ACTIVE_ORDER}`, data)
    Swal.fire({
      position: 'top-end',
      icon: 'success',
      title: 'Bestelling bewerkt!',
      toast: true,
      timer: 3000,
      showConfirmButton: false,
    })
  },

  async [FETCH_POS_ORDERS_FOR_TODAY]({ dispatch, state }) {
    state.date = {
      start: moment().startOf('day').toISOString(),
      end: moment().endOf('day').toISOString(),
    }
    await dispatch(`${FETCH_POS_ORDERS_BY_DATE}`, state.date)
  },

  [SET_ACTIVE_ORDER]({ commit }, order) {
    commit(`${SET_ACTIVE_ORDER}`, order)
  },

  async [SET_ACTIVE_ORDER_BY_ID]({ commit }, orderId) {
    commit(`${SET_ACTIVE_ORDER_BY_ID}`, orderId)
  },

  [SET_NEW_ACTIVE_ORDER]({ commit }, newOrder) {
    commit(`${SET_NEW_ACTIVE_ORDER}`, newOrder)
  },

  async [DELETE_ACTIVE_ORDER_FROM_POS]({ rootState, state, commit }) {
    await rootState.SERVICE_ROOT.repository.order.deleteOrder(
      state.activeOrder.id
    )
    commit(`${DELETE_ACTIVE_ORDER_FROM_POS}`)
    commit(`${RESET_ACTIVE_ORDER}`)
  },

  async [SET_ORDER_PRODUCTS_AND_ADDITIONS]({ rootGetters, state }) {
    state.activeOrder.orderedPVs = rootGetters[`${CART}/${GET_ORDER_PRODUCTS}`]
    state.activeOrder.orderAdditions =
      rootGetters[`${CART}/${GET_ORDER_ADDITIONS}`]
  },
}

const mutations = {
  [SET_POS_ORDERS](state, orders) {
    state.orders = orders
    // Find active order and update it
    if (state.activeOrder) {
      if (state.activeOrder.id > 0) {
        const activeOrder = orders.find((x) => x.id == state.activeOrder.id)
        state.activeOrder = activeOrder
      }
    }
  },

  [SET_ACTIVE_ORDER](state, order) {
    state.activeOrder = order
  },

  [SET_EXTERNAL_ORDERS](state, externalOrders) {
    state.externalOrders = externalOrders
  },

  [ADD_EXTERNAL_ORDERS](state, order) {
    state.externalOrders.push(order)
  },

  [SET_ACTIVE_ORDER_BY_ID](state, orderId) {
    if (!orderId || state.orders.length < 1) {
      return
    }
    const found = state.orders.find(
      (x) => x.trackingNumber === parseInt(orderId)
    )
    if (found) state.activeOrder = found
    // RESET ORDER??
  },

  [SET_NEW_ACTIVE_ORDER](state, newOrder) {
    state.activeOrder.orderStatusId = newOrder.orderStatusId
    state.activeOrder.name = newOrder.name
    state.activeOrder.deliverTime = moment(newOrder.deliverTime, TIME)
      .toISOString(true)
      .slice(0, 23)
    state.activeOrder.isPaid = newOrder.isPaid
    state.activeOrder.orderedPVs = newOrder.orderedProducts
    state.activeOrder.orderAdditions = newOrder.orderedAdditions
    state.activeOrder.location = newOrder.location
    state.activeOrder.paymentMethodId = newOrder.paymentMethodId
    state.activeOrder.description = newOrder.description
    state.activeOrder.isPickUp = newOrder.isPickUp
  },

  [DELETE_ACTIVE_ORDER_FROM_POS](state) {
    state.orders = state.orders.filter((x) => x.id !== state.activeOrder.id)
  },

  [DELETE_EXTERNAL_ORDER](state, externalOrder) {
    state.externalOrders = state.externalOrders.filter(
      (x) => x.id !== externalOrder.id
    )
  },

  [RESET_ACTIVE_ORDER](state) {
    state.activeOrder = {
      id: -1,
      name: '',
      trackingNumber: '',
      reference: '',
      orderStatusId: -1,
      deliverTime: '16:15',
      description: '',
      isPaid: false,
      isPickUp: true,
      paymentMethodId: 1,
      orderedPVs: [],
      orderAdditions: [],
      location: null,
      orderStatus: null,
      paymentMethod: null,
    }
    state.printOptions = {
      customer: 1,
      kitchen: 2,
    }
  },
}

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations,
}
