import Vue from 'vue'
import VueRouter from 'vue-router'
import Dashboard from '../views/Dashboard.vue'
import Cashier from '../views/Cashier.vue'
import Settings from '../views/Settings.vue'
import Notfound from '../views/NotFound.vue'
import Products from '../views/Products.vue'
import CurrentOrders from '../views/CurrentOrders.vue'
import KitchenOrders from '../views/KitchenOrders.vue'
import Help from '../views/Help.vue'
import Revenue from '../views/Revenue.vue'
import AllOrders from '../views/AllOrders.vue'
import { authGuard } from '../auth/authGuard'

Vue.use(VueRouter)
const routes = [
  {
    path: '/',
    name: 'Dashboard',
    component: Dashboard,
    meta: {
      layout: 'default',
    },
    beforeEnter: authGuard,
  },
  {
    path: '/cashier',
    name: 'Cashier',
    component: Cashier,
    meta: {
      layout: 'default',
    },
    beforeEnter: authGuard,
  },
  {
    path: '/kitchen-orders',
    name: 'Kitchen',
    component: KitchenOrders,
    meta: {
      layout: 'default',
    },
    beforeEnter: authGuard,
  },
  {
    path: '/settings',
    name: 'Setting',
    component: Settings,
    meta: {
      layout: 'default',
    },
    beforeEnter: authGuard,
  },
  {
    path: '/products',
    name: 'Products',
    component: Products,
    meta: {
      layout: 'default',
    },
    beforeEnter: authGuard,
  },
  {
    path: '/allorders',
    name: 'All Orders',
    component: AllOrders,
    meta: {
      layout: 'default',
    },
    beforeEnter: authGuard,
  },
  {
    path: '/current-orders/:id?',
    name: 'Current Orders',
    component: CurrentOrders,
    meta: {
      layout: 'default',
    },
    beforeEnter: authGuard,
  },
  {
    path: '/administration',
    name: 'Administration',
    meta: {
      layout: 'default',
    },
    beforeEnter: authGuard,
  },
  {
    path: '/revenue',
    name: 'Revenue',
    meta: {
      layout: 'default',
    },
    component: Revenue,
    beforeEnter: authGuard,
  },
  {
    path: '/help',
    name: 'Help',
    component: Help,
    meta: {
      layout: 'default',
    },
    beforeEnter: authGuard,
  },
  {
    path: '/*',
    name: 'Error',
    component: Notfound,
    meta: {
      layout: 'blank',
    },
    beforeEnter: authGuard,
  },
]

const router = new VueRouter({
  mode: 'history',
  routes,
})

export default router
